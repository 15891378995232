import React from "react";

import { useStateContext } from '../../ContextProvider/Context';
import { resetServerContext } from "react-beautiful-dnd";
import { Button } from "@chakra-ui/react";

const G2Button20 = ({ widthVal, heightVal, text, onClick, ...rest }) => {
  return (
    <Button {...rest} w={widthVal} h={heightVal} onClick={onClick} className="!bg-[var(--orange)] !text-white text-sm hover:!bg-[#2a956e] rounded-sm flex justify-center items-center text-center font-semibold">
      {text}
    </Button>
  );
}

export default G2Button20;