import g2Axios from "../../Custom/g2Axios";
import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";

const getContentList = (toast, sort, currentPage) => {
  return (dispatch) => {
    g2Axios
      .post("/marketplace", { sort, currentPage })
      .then((res) => {
        dispatch({
          type: "MARKET_GET_CONTENTLIST",
          payload: res.data.contentList,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting marketplace data list:", error);
      });
  };
};

const searchContentList = (toast, text) => {
  return (dispatch) => {
    g2Axios
      .get("/marketplace/search/" + text)
      .then((res) => {
        dispatch({
          type: "MARKET_GET_CONTENTLIST",
          payload: res.data.contentList,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting marketplace data list:", error);
      });
  };
};

const getMyContentInfo = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/marketplace/mine")
      .then((res) => {
        dispatch({
          type: "MARKET_GET_POSTEDCOUNT",
          payload: res.data.posted,
        });
        dispatch({
          type: "MARKET_GET_DRAFTCOUNT",
          payload: res.data.draft,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting marketplace data list:", error);
      });
  };
};

const getMyContent = (toast, cond) => {
  return (dispatch) => {
    g2AxiosT
      .get("/marketplace/mine/" + cond)
      .then((res) => {
        dispatch({
          type: "MARKET_GET_CONTENTLIST",
          payload: res.data.contentList,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting marketplace data list:", error);
      });
  };
};

const getSomeContent = (toast, sort, itemId, nodeId) => {
  return (dispatch) => {
    g2Axios
      .post("/marketplace/some", { sort, itemId, nodeId })
      .then((res) => {
        dispatch({
          type: "MARKET_GET_CONTENTLIST",
          payload: res.data.contentList,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting marketplace data list:", error);
      });
  };
};

const getContent = (toast, id) => {
  return (dispatch) => {
    g2AxiosT
      .get("/marketplace/view/" + id)
      .then((res) => {
        dispatch({ type: "MARKET_GET_CONTENT", payload: res.data.content });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting marketplace data:", error);
      });
  };
};

const makeContent = (navigate, cond, val) => {
  return (dispatch) => {
    dispatch({ type: "MARKET_GET_CONTENT", payload: val });

    if (cond == 1) {
      navigate("/marketplace/view");
    } else {
      navigate("/marketplace/edit");
    }
  };
};

const addContent = (toast, navigate, formData, setWait) => {
  return (dispatch) => {
    g2AxiosT
      .post("/marketplace/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setWait(false);
        dispatch({
          type: "MARKET_GET_CONTENTLIST",
          payload: res.data.contentList,
        });
        dispatch({
          type: "MARKET_GET_CATEGORY",
          payload: res.data.category,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
        navigate("/marketplace");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding unit data:", error);
      });
  };
};

const updateContent = (toast, navigate, id, formData, setWait) => {
  return (dispatch) => {
    g2AxiosT
      .put("/marketplace/update/" + id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setWait(false);
        dispatch({
          type: "MARKET_GET_CONTENTLIST",
          payload: res.data.contentList,
        });
        dispatch({
          type: "MARKET_GET_CATEGORY",
          payload: res.data.category,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
        navigate("/marketplace");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding unit data:", error);
      });
  };
};

const deleteContent = (toast, navigate, id, posted) => {
  return (dispatch) => {
    g2AxiosT
      .delete("/marketplace/delete/" + id)
      .then((res) => {
        g2Toast({ toast, status: "success", title: res.data.msg });
        navigate("/marketplace");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error updating goal data:", error);
      });
  };
};

const makePreview = (navigate, data) => {
  return (dispatch) => {
    dispatch({ type: "MARKET_GET_PREVIEW", payload: data });
    navigate("/marketplace/preview");
  };
};

const addToBusket = (toast, id) => {
  return (dispatch) => {
    g2AxiosT
      .post("/marketplace/busket/" + id)
      .then((res) => {
        dispatch({ type: "MARKET_GET_BUSKET", payload: res.data.busket });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error accepting invitation:", error);
      });
  };
};

const getBusket = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/marketplace/busket")
      .then((res) => {
        dispatch({ type: "MARKET_GET_BUSKET", payload: res.data.busket });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error accepting invitation:", error);
      });
  };
};

const removeToBusket = (toast, id) => {
  return (dispatch) => {
    g2AxiosT
      .delete("/marketplace/busket/" + id)
      .then((res) => {
        dispatch({ type: "MARKET_GET_BUSKET", payload: res.data.busket });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error accepting invitation:", error);
      });
  };
};

const removeToBusketList = (toast, ids, price, setWait) => {
  return (dispatch) => {
    g2AxiosT
      .delete("/marketplace/busket/sel", { data: { ids } })
      .then((res) => {
        dispatch({ type: "MARKET_GET_BUSKET", payload: res.data.busket });
        setWait(false);
        g2Toast({
          toast,
          status: "success",
          title: `Payment successed! (Amount: ${price} $)`,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error accepting invitation:", error);
      });
  };
};

const addReview = (toast, contentId, rating, reviewText) => {
  return (dispatch) => {
    g2AxiosT
      .put("/marketplace/view/review/" + contentId, { rating, reviewText })
      .then((res) => {
        dispatch({
          type: "MARKET_GET_CONTENTLIST",
          payload: res.data.contentList,
        });
        dispatch({ type: "MARKET_GET_CONTENT", payload: res.data.content });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error accepting invitation:", error);
      });
  };
};

const getCategory = (toast, limited = false) => {
  return (dispatch) => {
    g2Axios
      .post("/marketplace/tree", { limited })
      .then((res) => {
        dispatch({
          type: "MARKET_GET_CATEGORY",
          payload: res.data.category,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding unit data:", error);
      });
  };
};

const addTreeItem = (toast, treeItem) => {
  return (dispatch) => {
    g2AxiosT
      .post("/marketplace/tree/item", { treeItem })
      .then((res) => {
        dispatch({
          type: "MARKET_GET_CATEGORY",
          payload: res.data.category,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding unit data:", error);
      });
  };
};

const addTreeNode = (toast, id, treeNode) => {
  return (dispatch) => {
    g2AxiosT
      .put("/marketplace/tree/item/node/" + id, { treeNode })
      .then((res) => {
        dispatch({
          type: "MARKET_GET_CATEGORY",
          payload: res.data.category,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding unit data:", error);
      });
  };
};

const removeTreeItem = (toast, itemId) => {
  return (dispatch) => {
    g2AxiosT
      .delete("/marketplace/tree/item/" + itemId)
      .then((res) => {
        dispatch({
          type: "MARKET_GET_CATEGORY",
          payload: res.data.category,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding unit data:", error);
      });
  };
};

const removeTreeNode = (toast, itemId, nodeId) => {
  return (dispatch) => {
    g2AxiosT
      .put("/marketplace/tree/item/node/remove/" + itemId, { nodeId })
      .then((res) => {
        dispatch({
          type: "MARKET_GET_CATEGORY",
          payload: res.data.category,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding unit data:", error);
      });
  };
};

const incViewCnt = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .put("/marketplace/view/inc")
      .then((res) => { })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error increasing view count:", error);
      });
  };
};

export {
  getContentList,
  searchContentList,
  getMyContentInfo,
  getMyContent,
  getSomeContent,
  getContent,
  makeContent,
  addContent,
  updateContent,
  deleteContent,
  makePreview,
  addToBusket,
  getBusket,
  removeToBusket,
  removeToBusketList,
  addReview,
  getCategory,
  addTreeItem,
  addTreeNode,
  removeTreeItem,
  removeTreeNode,
  incViewCnt,
};
