import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  Card,
  CardBody,
  Select,
  Progress,
  Circle,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
  Link,
  Avatar,
} from "@chakra-ui/react";

import { ArrowBackIcon, CalendarIcon, SearchIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import {
  getInvitation,
  getUnitList,
  getUrl,
} from "../../Store/Actions/learnAction";
import { getToken, verifyToken } from "../../utils";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import InvitationModal from "./InvitationModal";

import G2Button2 from "../Common/G2Button2";
import G2Button20 from "../Common/G2Button20";

import notification1 from "../../assets/learn/notification1.png";
import notification2 from "../../assets/learn/notification2.png";
import content1 from "../../assets/create/content1.png";
import content2 from "../../assets/create/content2.png";
import group1 from "../../assets/create/group1.png";
import group2 from "../../assets/create/group2.png";
import attach from "../../assets/create/attachment.png";

import axios from "axios";
import { getServerUrl } from "../../helpers/location";
import { getFileUrl } from "../../helpers/general";

const MainContentForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const invitation = useSelector((state) => state.Learn.invitation);
  const group = useSelector((state) => state.Learn.group);
  const contentList = useSelector((state) => state.Learn.contentList);

  const [isAIopen, setIsAIOpen] = useState(false);

  const token = getToken();

  useEffect(() => {
    if (verifyToken()) {
      dispatch(getInvitation(toast));
    }

    if (isEmptyValue(group._id)) {
      navigate("/learn");
    }
  }, []);

  const openInvitationModal = () => {
    setIsAIOpen(true);
  };

  const closeInvitationModal = () => {
    setIsAIOpen(false);
  };

  const handleContentClick = (val, id) => {
    dispatch(getUnitList(toast, navigate, val, id, group._id));
  };

  return (
    <Box w="100%" h="100%" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box w="100%" h="80px" bg={G2_color_2} className="flex-box centering-h shadow">
        <Box w="100%" className="flex-box">
          <Box p="3" className="text-left flex centering-h" style={{ width: "calc(100% - 70px)" }}>
            <IconButton isRound={true} className="normal-hover mx-[10px]" style={{ backgroundColor: "var(--orange)" }} color="white" aria-label="Done" fontSize="20px" icon={<ArrowBackIcon />} onClick={() => navigate("/learn")} />
            <Avatar size="md" src={getFileUrl(group?.userId?.avatar)} alt="icon" />
            {!isEmptyValue(group._id) ? (
              <Box>
                {
                  //JSON.stringify(group)
                }
                <Text fontSize="16px" className="font-semibold mx-[10px]">
                  {group.groupId.title}
                </Text>
                <Text fontSize="14px" className="mx-[10px]">
                  Invitation from: {group.userId.name}
                </Text>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box w="70px" className="centering relative">
            <Box className="normal-hover invite-notification" onClick={openInvitationModal}>
              {isEmptyValue(invitation[0]?._id) ? (
                <Image width="30px" height="35px" src={notification1} alt="notification" />
              ) : (
                <Image width="30px" height="35px" src={notification2} alt="notification" />
              )}
              {invitation?.length != 0 ? (
                <Box w="15px" h="15px" className="absolute top-[2px] right-[22px] centering">
                  <Text fontSize="12px" className="text-white">
                    {invitation?.length}
                  </Text>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <InvitationModal
              isAIopen={isAIopen}
              closeInvitationModal={closeInvitationModal}
            />
          </Box>
        </Box>
      </Box>
      <Box w="100%" className="learn-accordion-box">
        <Box w="100%">
          {group?.groupId?.attachments && (
            <Box w="100%">
              {group?.groupId?.attachments?.map((val, i) => (
                <Box key={i} className="flex mb-[20px] centering-h">
                  <Image width="25px" height="auto" src={attach} alt="attachment" />
                  <Link href={`${getServerUrl()}learn/downloadfile?file=${val.attachment}`} color="#d86655" className="ml-[10px] text-[16px] font-semibold">
                    Attachment ({val.attachment?.split("/").pop()})
                  </Link>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {contentList?.map((val, i) => (
          <Box key={i} w="100%" bg={G2_color_2} className="learn-content-box flex-box centering-h py-[20px] pr-[20px] mb-[20px] rounded-[2.5px] shadow normal-hover" onClick={() => handleContentClick(val, val._id)}>
            <Box w="60px" className="centering-w">
              <Image boxSize="25px" src={G2_theme_mode == "light" ? content1 : content2} alt="icon" />
            </Box>
            <Box style={{ width: "calc(100% - 120px)" }}>
              <Text fontSize="16px">{val.title}</Text>
            </Box>
            <Box w="60px" className="text-end">
              <Text fontSize="16px">{val.size} Units</Text>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MainContentForm;
