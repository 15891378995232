import g2Axios from "../../Custom/g2Axios";
import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";

const getInvitation = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/learn/invitation")
      .then((res) => {
        dispatch({
          type: "LEARN_GET_INVITATION",
          payload: res.data.invitation,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting invitation data:", error);
      });
  };
};

const acceptInvitation = (toast, id) => {
  return (dispatch) => {
    g2AxiosT
      .put("/learn/invitation/accept/" + id)
      .then((res) => {
        dispatch({
          type: "LEARN_GET_INVITATION",
          payload: res.data.invitation,
        });
        dispatch({
          type: "LEARN_GET_MEMBERLIST",
          payload: res.data.memberList,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error accepting invitation:", error);
      });
  };
};

const rejectInvitation = (toast, id) => {
  return (dispatch) => {
    g2AxiosT
      .put("/learn/invitation/reject/" + id)
      .then((res) => {
        dispatch({
          type: "LEARN_GET_INVITATION",
          payload: res.data.invitation,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error rejecting invitation:", error);
      });
  };
};

const uploadActivityPic = (toast, formData, setUploadShow) => {
  return (dispatch) => {
    g2AxiosT
      .post("/learn/content/unit/card/activityfeed", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setUploadShow(false);
        dispatch({ type: "LEARN_SET_CARD", payload: res.data.card });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding activity data:", error);
      });
  };
};

const getMemberList = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/learn")
      .then((res) => {
        dispatch({
          type: "LEARN_GET_MEMBERLIST",
          payload: res.data.memberList,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting member list:", error);
      });
  };
};

const getContentList = (toast, navigate, val, ids) => {
  return (dispatch) => {
    g2AxiosT
      .post("/learn/content", { ids })
      .then((res) => {
        dispatch({ type: "LEARN_SET_GROUP", payload: val });
        dispatch({
          type: "LEARN_GET_CONTENTLIST",
          payload: res.data.contentList,
        });
        navigate("/learn/content");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting content list:", error);
      });
  };
};

const getUnitList = (toast, navigate, val, id, memberId) => {
  return (dispatch) => {
    g2AxiosT
      .post("/learn/content/unit/" + id, { memberId })
      .then((res) => {
        dispatch({ type: "LEARN_SET_CONTENT", payload: val });
        dispatch({ type: "LEARN_GET_UNITLIST", payload: res.data.unitList });
        dispatch({ type: "LEARN_GET_CARDLIST", payload: res.data.cardList });
        dispatch({ type: "LEARN_GET_ACTIVITY", payload: res.data.activity });
        navigate("/learn/content/unit");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting unit list:", error);
      });
  };
};

const getUnit = (toast, navigate, val) => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerLearn", "method": "getCard", "cardId": val._id })
      .then((res) => {
        dispatch({ type: "LEARN_SET_CARD", payload: res.data });
        navigate("/learn/content/unit/card");
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting card:", error);
      });
  };
};

const addActivity = (toast, navigate, memberId, cardId, quizResults, completed) => {
  return (dispatch) => {
    g2AxiosT
      .post("/learn/content/unit/card/activity", { memberId, cardId, quizResults, completed })
      .then((res) => {
        dispatch({ type: "LEARN_GET_ACTIVITY", payload: res.data.activity });
        dispatch({ type: "LEARN_SET_CARD", payload: res.data.card });
        if (completed) {
          navigate("/learn/content/unit");
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error adding activity:", error);
      });
  };
};

const getUrl = (toast, url) => {
  return (dispatch) => {
    g2AxiosT
      .post("/learn/geturl", { url })
      .then((res) => {
        dispatch({ type: "LEARN_GET_ATTACHMENT", payload: res.data.url });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting URL:", error);
      });
  };
};

const actionTaskResponse = (toast, cardId, userCardTaskIds, accept, groupMemberId = null) => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerLearn", "method": "actionUserCardTasks", cardId, userCardTaskIds, accept, groupMemberId })
      .then((res) => {
        if (groupMemberId == null) {
          dispatch({ type: "DASHBOARD_MAIN_DATA", payload: res.data.mainData });
        } else {
          dispatch({ type: "CREATE_GET_ACTIVITY", payload: res.data.activity });
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting card:", error);
      });
  };
};

const actionQuizResponse = (toast, userCardId, accept, groupMemberId = null) => {
  return (dispatch) => {
    g2AxiosT
      .post("/", { "class": "ControllerLearn", "method": "actionUserCard", userCardId, accept, groupMemberId })
      .then((res) => {
        if (groupMemberId == null) {
          dispatch({ type: "DASHBOARD_MAIN_DATA", payload: res.data.mainData });
        } else {
          dispatch({ type: "CREATE_GET_ACTIVITY", payload: res.data.activity });
        }
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting card:", error);
      });
  };
};



export {
  getInvitation,
  acceptInvitation,
  rejectInvitation,
  getMemberList,
  getContentList,
  getUnitList,
  getUnit,
  uploadActivityPic,
  addActivity,
  getUrl,
  actionTaskResponse,
  actionQuizResponse
};
