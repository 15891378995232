import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  Card,
  CardBody,
  Select,
  Progress,
  Circle,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";

import { ArrowLeftIcon, ArrowRightIcon, EditIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import { getJobList, getJob } from "../../Store/Actions/academyAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

// import AddModal from "./AddModal";

import G2Button10 from "../Common/G2Button10";
import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";
import G2Button23 from "../Common/G2Button23";
import G2Button24 from "../Common/G2Button24";
import G2Button26 from "../Common/G2Button26";

import logo from "../../assets/dashboard/logo.png";
import { getServerUrl } from "../../helpers/location";
import { getFileUrl } from "../../helpers/general";

const JobBoardForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const jobList = useSelector((state) => state.Academy.jobList);

  const [isGoalDopen, setIsGoalDopen] = useState(false);

  const token = getToken();

  useEffect(() => {
    if (verifyToken()) {
      dispatch(getJobList(toast));
    }
  }, []);

  const handlePreviousClick = () => {
    navigate("/academy/digital");
  };

  const handleNextClick = () => {
    navigate("/academy/event");
  };

  const handleJobViewClick = (val) => {
    dispatch(getJob(navigate, 1, val));
  };

  const handleEditClick = () => {
    navigate("/academy/job/edit");
  };

  return (
    <Box
      w="100%"
      h="100%"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box
        w="100%"
        h="80px"
        bg={G2_color_2}
        className="flex-box centering-h px-[30px] shadow"
      >
        <Text
          fontSize="20px"
          className="font-semibold mr-[10px] text-[var(--orange)]"
        >
          Jobs Boards
        </Text>
      </Box>
      <Box w="100%" px="30px" py="20px" className="flex-box">
        <Text
          w="100%"
          fontSize="20px"
          className="mt-[10px] font-semibold text-center"
        >
          Job listings
          <EditIcon
            className="ml-[5px] mb-[5px] normal-hover"
            onClick={handleEditClick}
          />
        </Text>
        {jobList && (
          <Box w="100%">
            {jobList?.map((val, i) => (
              <Box key={i} w="100%" className="mt-[20px]">
                <Box
                  w="100%"
                  bg="#032c47"
                  className={`border ${G2_theme_mode == "light"
                    ? "border-gray-600"
                    : "border-gray-300"
                    } border-[3px]`}
                >
                  <Text
                    w="100%"
                    fontSize="16px"
                    color="white"
                    className="px-[10px] py-[5px] font-semibold text-center"
                  >
                    {val.title}
                  </Text>
                </Box>
                <Box
                  w="100%"
                  h="135px"
                  className={`flex-box border-b-[3px] border-r-[3px] border-l-[3px] ${G2_theme_mode == "light"
                    ? "border-gray-600"
                    : "border-gray-300"
                    }`}
                >
                  <Box w="150px" h="100%" className="centering">
                    <Image
                      w="100px"
                      src={getFileUrl(val.logo)}
                    />
                  </Box>
                  <Box
                    h="100%"
                    bg={G2_color_2}
                    className="px-[20px]"
                    style={{ width: "calc(100% - 250px)" }}
                  >
                    <Text
                      w="100%"
                      fontSize="20px"
                      className="mt-[10px] font-semibold"
                    >
                      {val.category}
                    </Text>
                    <Box w="100%" className="flex mt-[5px]">
                      <Text fontSize="14px" className="font-semibold">
                        Job Location :
                      </Text>
                      <Text fontSize="14px" className="ml-[20px]">
                        {val.location}
                      </Text>
                    </Box>
                    <Box w="100%" className="flex mt-[5px]">
                      <Text fontSize="14px" className="font-semibold">
                        Job Type :
                      </Text>
                      <Text fontSize="14px" className="ml-[20px]">
                        {val.type}
                      </Text>
                    </Box>
                    <Box w="100%" className="flex mt-[5px]">
                      <Text fontSize="14px" className="font-semibold">
                        Salary Range :
                      </Text>
                      <Text fontSize="14px" className="ml-[20px]">
                        {val.salary}
                      </Text>
                    </Box>
                  </Box>
                  <Box w="100px" h="100%" bg={G2_color_2} className="centering">
                    <G2Button20
                      widthVal="70px"
                      heightVal="35px"
                      text="View"
                      onClick={() => handleJobViewClick(val)}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default JobBoardForm;
