import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { Spinner, Alert, AlertIcon, useToast } from "@chakra-ui/react";

import g2AxiosT from "../../Custom/g2AxiosT";
import axios from "axios";

import { removeToBusketList } from "../../Store/Actions/lifeAction";
import g2Toast from "../../Custom/g2Toast";
import { getServerUrl } from "../../helpers/location";

const PaymentForm2 = (props) => {
  const { price, selectedElements } = props;

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const toast = useToast();

  const user = useSelector((state) => state.Auth.user);

  const [errorMessage, setErrorMessage] = useState("");
  const [wait, setWait] = useState(false);

  const backendUrl = `${getServerUrl()}marketplace/payments/create`;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null || stripe == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();

    if (submitError?.message) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    setWait(true);

    const data = {
      currency: "usd",
      email: user.email,
      amount: price * 100,
      paymentMethodType: "card",
    };
    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const response = await axios.post(backendUrl, { data });

    dispatch(removeToBusketList(toast, selectedElements, price, setWait));

    const clientSecret = response.data.client_secret;

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/life/social`,
        // return_url: window.location.href,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe || !elements}
        style={{ width: "100%", height: "35px" }}
        className="bg-[var(--orange)] mt-[20px] text-white hover:bg-[#2a956e] rounded-sm flex justify-center items-center text-center font-semibold"
      >
        {wait && (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="sm"
            className="mr-[20px]"
          />
        )}
        Pay
      </button>
      {/* {errorMessage &&
        <Alert status='error' variant='solid' height="40px" borderRadius="md" className="mt-[10px] text-white">
          <AlertIcon />
          {errorMessage}
        </Alert>
      } */}
    </form>
  );
};

export default PaymentForm2;
