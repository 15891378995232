import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  Button,
  Text,
  Input,
  Divider,
  IconButton,
  Image,
  useToast,
} from "@chakra-ui/react";

import { SmallCloseIcon, DeleteIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { addSponsor } from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";

const SponsorAddModal = (props) => {
  const { isSAddopen, closeSAddModal } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  const toast = useToast();

  const group = useSelector((state) => state.Create.group);

  const [logoFile, setLogoFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [webSite, setWebSite] = useState("");

  const onClickFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event, num) => {
    const selectedFile = event.target.files[0];

    const allowedFileTypes = [".jpg", ".jpeg", ".png"];
    const fileExtension = "." + selectedFile.name.split(".").pop();

    if (event.target.files.length == 0) {
      return;
    }

    if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
      g2Toast({ toast, status: "error", title: "Invalid file type!" });
      return;
    }

    setLogoFile(selectedFile);
    setLogoUrl(URL.createObjectURL(selectedFile));
  };

  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  const handleSaveSponsor = () => {
    const data = {
      name,
      message,
      webSite,
    };

    const formData = new FormData();

    formData.append("file", logoFile);

    formData.append("data", JSON.stringify(data));

    if (logoFile && !isEmptyObject(data)) {
      dispatch(addSponsor(toast, formData));

      setName("");
      setLogoFile(null);
      setLogoUrl(null);
      setMessage("");
      setWebSite("");

      closeSAddModal();
    } else {
      g2Toast({
        toast,
        status: "error",
        title: "All input fields must be filled out!",
      });
    }
  };

  return (
    <>
      <Modal size={isSmallScreen ? "lg" : "xl"} isOpen={isSAddopen}>
        <ModalOverlay />
        <ModalContent
          style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
        >
          <ModalBody>
            <Box w="100%" className="flex-box py-5">
              <Box
                w="100%"
                className="flex-box justify-between centering-h mb-[10px]"
              >
                <Box className="flex-box text-left">
                  <Text
                    className="block text-lg font-semibold"
                    style={{ color: `${G2_color_3}` }}
                  >
                    Add a sponsor
                  </Text>
                </Box>
                <Box className="flex-box text-right">
                  <SmallCloseIcon
                    className="exit-icon"
                    style={{ color: `${G2_color_3}` }}
                    onClick={closeSAddModal}
                  />
                </Box>
              </Box>
              <Divider
                borderColor={G2_theme_mode == "light" ? "black" : "white"}
                orientation="horizontal"
                className="mb-[10px]"
              />
              <Text
                w="100%"
                className="block text-sm font-semibold mt-5 mb-2"
                style={{ color: `${G2_color_3}` }}
              >
                Sponsor Name
              </Text>
              <Input
                w="100%"
                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
                style={{
                  background: `${G2_color_1}`,
                  color: `${G2_color_3}`,
                  borderRadius: "2.5px",
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Text
                w="100%"
                className="block text-sm font-semibold mt-5 mb-2"
                style={{ color: `${G2_color_3}` }}
              >
                Sponsor Logo
              </Text>
              <Box
                w="100%"
                h="150px"
                borderRadius="2.5px"
                bg={G2_color_1}
                className="centering shadow-md"
              >
                <Input
                  type="file"
                  ref={fileInputRef}
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                {logoUrl == null ? (
                  <G2Button21
                    widthVal="120px"
                    heightVal="35px"
                    text="Upload Logo"
                    onClick={onClickFileInput}
                  />
                ) : (
                  <Box w="100%" h="100%" className="relative centering">
                    <Image boxSize="150px" src={logoUrl} alt="uploadedImage" />
                    <IconButton
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      variant="outline"
                      isRound={true}
                      color="black"
                      fontSize="15px"
                      icon={<DeleteIcon />}
                      onClick={() => setLogoUrl(null)}
                    />
                  </Box>
                )}
              </Box>
              <Text
                w="100%"
                className="block text-sm font-semibold mt-5 mb-2"
                style={{ color: `${G2_color_3}` }}
              >
                Sponsor Message
              </Text>
              <Textarea
                w="100%"
                bg={G2_color_1}
                size="sm"
                rows={5}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Text
                w="100%"
                className="block text-sm font-semibold mt-5 mb-2"
                style={{ color: `${G2_color_3}` }}
              >
                Sponsor Website
              </Text>
              <Input
                w="100%"
                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
                style={{
                  background: `${G2_color_1}`,
                  color: `${G2_color_3}`,
                  borderRadius: "2.5px",
                }}
                value={webSite}
                onChange={(e) => setWebSite(e.target.value)}
              />
              <Box w="100%" className="mt-5">
                <G2Button20
                  widthVal="100%"
                  heightVal="40px"
                  text="Save Sponsor"
                  onClick={handleSaveSponsor}
                />
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SponsorAddModal;
