import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import {
  Box,
  Text,
  Image,
  Button,
  Divider,
  Card,
  CardBody,
  Select,
  Progress,
  Circle,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
  AspectRatio,
  Textarea,
  Spinner,
} from "@chakra-ui/react";

import {
  EditIcon,
  DeleteIcon,
  ChevronRightIcon,
  CloseIcon,
  SmallCloseIcon,
} from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import {
  updateCard1,
  updateCard2,
} from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyValue from "../../Custom/isEmptyValue";
// import randomStringGen from "../../Custom/randomStringGen";

import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";

import content1 from "../../assets/create/content1.png";
import content2 from "../../assets/create/content2.png";
import phone from "../../assets/create/phone.png";
import play from "../../assets/create/play.png";
import { getServerUrl } from "../../helpers/location";
import QuizCreationCard from "../Quiz/QuizCreationCard";
import WYSIWYGEditor from "../Common/WYSIWYGEditor";
import { getFileUrl, isFile } from "../../helpers/general";
import UploadArea from "./UploadArea";
import _ from "lodash";

const CardEditForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const content = useSelector((state) => state.Create.content);
  const unit = useSelector((state) => state.Create.unit);
  const currentCard = useSelector((state) => state.Create.currentCard);
  const [wait, setWait] = useState(false);
  const [quiz, setQuiz] = useState({});

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [cardName, setCardName] = useState(currentCard.cardName);

  const [audioFile, setAudioFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);

  const [videoFile, setVideoFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const [delAudio, setDelAudio] = useState(false);
  const [delVideo, setDelVideo] = useState(false);

  const [cardContent, setCardContent] = useState(currentCard.cardContent);
  const [attachments, setAttachments] = useState(currentCard?.attachments ?? []);
  const [cardTask, setCardTask] = useState(currentCard.cardTask);

  const containerRef = React.useRef(null);

  useEffect(() => {
    if (isEmptyValue(content._id) || isEmptyValue(unit._id) || isEmptyValue(currentCard._id)) {
      navigate("/create");
    }

    setQuiz(currentCard.quiz);

    const ps = new PerfectScrollbar(containerRef.current);

    return () => {
      ps.destroy();
    };
  }, [currentCard]);

  const onClickFileInput = (num) => {
    if (num == 1) {
      fileInputRef1.current.click();
    } else {
      fileInputRef2.current.click();
    }
  };

  const handleFileChange = (event, num) => {
    const selectedFile = event.target.files[0];

    if (event.target.files.length == 0) {
      return;
    }

    if (num == 1) {
      const allowedFileTypes = [".mp3", ".wav"];
      const fileExtension = "." + selectedFile.name.split(".").pop();

      if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
        g2Toast({ toast, status: "error", title: "Invalid file type!" });
        return;
      }

      if (selectedFile.size > 20 * 1024 * 1024) {
        alert("File size exceeds the maximum allowed size of " + 20 + " MB.");
        return;
      }

      setAudioFile(selectedFile);
      setAudioUrl(URL.createObjectURL(selectedFile));
    } else {
      const allowedFileTypes = [".mp4", ".mpg", ".avi", ".3gp"];
      const fileExtension = "." + selectedFile.name.split(".").pop();

      if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
        g2Toast({ toast, status: "error", title: "Invalid file type!" });
        return;
      }

      if (selectedFile.size > 200 * 1024 * 1024) {
        alert("File size exceeds the maximum allowed size of " + 200 + " MB.");
        return;
      }

      setVideoFile(selectedFile);
      setVideoUrl(URL.createObjectURL(selectedFile));
    }
  };

  const handleUpdateCard = () => {
    let cond = 0;
    const formData = new FormData();

    if (audioFile && videoFile) {
      cond = 1;
      const files = [audioFile, videoFile];

      files.forEach((file, i) => {
        formData.append("files", file);
      });
    } else if (audioFile && !videoFile) {
      cond = 2;
      formData.append("file", audioFile);
    } else if (videoFile && !audioFile) {
      cond = 3;
      formData.append("file", videoFile);
    } else {
      cond = 4;
    }

    let tempAttachments = _.cloneDeep(attachments);
    tempAttachments = tempAttachments.filter(r => !isFile(r));
    const files = attachments.filter(r => isFile(r));
    for (let file of files) {
      formData.append("attachments", file)
    }

    const data = {
      contentId: content._id,
      unitId: unit._id,
      cardId: currentCard._id,
      cardName,
      cardContent,
      cardTask,
      quiz,
      attachments: tempAttachments,
      index: currentCard.index ?? null
    };

    formData.append("data", JSON.stringify(data));

    if (!isEmptyValue(cardName)) {
      setWait(true);

      dispatch(updateCard1(toast, navigate, cond, formData, setWait));
      /*
      if (cond != 4) {
      } else {
        dispatch(updateCard2(toast, navigate, data, setWait));
      }
        */
    } else {
      g2Toast({ toast, status: "error", title: "Input card name!" });
    }
  };

  const handleAudioDelete = () => {
    setAudioFile(null);
    setAudioUrl(null);
    setDelAudio(true);
  };

  const handleVideoDelete = () => {
    setVideoFile(null);
    setVideoUrl(null);
    setDelVideo(true);
  };

  return (
    <Box w="100%" h="100%" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box w="100%" bg={G2_color_2} className="create-main-header flex-box centering-h shadow">
        <Box w="100%" className="flex-box justify-between">
          <Box className="flex-box text-left centering-h">
            <Image boxSize="25px" src={G2_theme_mode == "light" ? content1 : content2} alt="icon" />
            <Box>
              <Text fontSize="16px" className="font-semibold mx-[10px]">
                {unit.title}
              </Text>
              <Text fontSize="14px" className="mx-[10px] normal-hover" onClick={() => navigate("/create/content")}>
                {content.title}
              </Text>
            </Box>
          </Box>
          <Box className="flex-box text-right">
            {/* <Box className="mt-[7.5px] mr-5 normal-hover flex">
                            <DeleteIcon boxSize="15px" className="mt-[5px] mr-[5px]" />
                            <Text>Delete card</Text>
                        </Box> */}
          </Box>
        </Box>
      </Box>
      {wait ? (
        <Box w="100%" className="centering-w mt-[200px]">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" />
        </Box>
      ) : (
        <Box w="100%" className="flex-box pb-[20px]">
          <Box className="create-unit-left-w py-[20px]">
            <Text w="100%" className="block text-sm font-semibold mb-2" style={{ color: `${G2_color_3}` }}>
              Name
            </Text>
            <Input w="100%" size="md" borderRadius="2.5px" bg={G2_color_2} color={G2_color_3} value={cardName} onChange={(e) => setCardName(e.target.value)} />
            <Text w="100%" className="block text-sm font-semibold mt-5 mb-2" style={{ color: `${G2_color_3}` }}>
              Audio (MaxSize: 20Mbyte)
            </Text>
            <Box w="100%" h="100px" borderRadius="2.5px" bg={G2_color_2} className="centering shadow">
              <Input type="file" ref={fileInputRef1} accept=".mp3, .wav" onChange={(event) => handleFileChange(event, 1)} style={{ display: "none" }} />
              {!delAudio && !isEmptyValue(currentCard.audio) ? (
                <Box w="100%" h="100%" className="relative centering pl-[20px] pr-[80px]">
                  <audio controls autoplay className="w-full" src={getFileUrl(currentCard.audio)} />
                  <IconButton position="absolute" top="50%" right="0" transform="translate(-50%, -50%)" variant="outline" isRound={true} fontSize="15px" color={G2_color_3} icon={<DeleteIcon />} onClick={handleAudioDelete} />
                </Box>
              ) : (
                <Box w="100%" className="centering">
                  {audioUrl == null ? (
                    <G2Button21 widthVal="120px" heightVal="35px" text="Upload Audio" onClick={() => onClickFileInput(1)} />
                  ) : (
                    <Box w="100%" h="100%" className="relative centering pl-[20px] pr-[80px]">
                      <audio controls autoplay className="w-full" src={audioUrl} />
                      <IconButton position="absolute" top="50%" right="0" transform="translate(-50%, -50%)" variant="outline" isRound={true} fontSize="15px" color={G2_color_3} icon={<DeleteIcon />} onClick={handleAudioDelete} />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Text w="100%" className="block text-sm font-semibold mt-5 mb-2" style={{ color: `${G2_color_3}` }}>
              Video (MaxSize: 200Mbyte)
            </Text>
            <Box w="100%" h="200px" borderRadius="2.5px" bg={G2_color_2} className="centering shadow">
              <Input type="file" ref={fileInputRef2} accept=".mp4, .mpg, .avi, .3gp" onChange={(event) => handleFileChange(event, 2)} style={{ display: "none" }} />
              {!delVideo && !isEmptyValue(currentCard.video) ? (
                <Box w="100%" h="100%" className="relative centering">
                  <AspectRatio w="350px" h="100%" ratio={16 / 9}>
                    <video controls width="auto" height="100%" src={getFileUrl(currentCard.video)} />
                  </AspectRatio>
                  <IconButton position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" variant="outline" isRound={true} fontSize="15px" color="white" icon={<DeleteIcon />} onClick={handleVideoDelete} />
                </Box>
              ) : (
                <Box w="100%" className="centering">
                  {videoUrl == null ? (
                    <G2Button21 widthVal="120px" heightVal="35px" text="Upload Video" onClick={() => onClickFileInput(2)} />
                  ) : (
                    <Box w="100%" h="100%" className="relative centering">
                      <AspectRatio w="350px" h="100%" ratio={16 / 9}>
                        <video controls width="auto" height="100%" src={videoUrl} />
                      </AspectRatio>
                      <IconButton position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" variant="outline" isRound={true} fontSize="15px" color="white" icon={<DeleteIcon />} onClick={handleVideoDelete} />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Text w="100%" className="block text-sm font-semibold mt-5 mb-2" style={{ color: `${G2_color_3}` }}>
              Content
            </Text>
            <Box w="100%" borderRadius="2.5px" className="shadow">
              <WYSIWYGEditor value={cardContent} setValue={setCardContent} />
            </Box>
            <UploadArea attachments={attachments} setAttachments={setAttachments} title="Upload Attachment" />
            <Text w="100%" className="block text-sm font-semibold mt-5 mb-2" style={{ color: `${G2_color_3}` }}>
              Practical Task
            </Text>
            <Box w="100%" borderRadius="2.5px" className="shadow">
              <WYSIWYGEditor value={cardTask} setValue={setCardTask} />
            </Box>
            <QuizCreationCard quiz={quiz} setQuiz={setQuiz} />
            <Box w="100%" className="mt-5">
              <G2Button20 widthVal="100%" heightVal="35px" text="Save a card" onClick={handleUpdateCard} />
            </Box>
          </Box>
          {screenSize > 767 && <Box className="create-unit-right-w py-[25px] centering-w">
            <Box w="348px">
              <Box w="100%" className="px-[10px] py-[20px] relative">
                <Image width="100%" height="auto" src={phone} alt="phone" />
                <Box w="100%">
                  <Box w="83%" h="560px" className="absolute top-[65px] right-[30px] overflow-y-auto" ref={containerRef}>
                    <Box w="100%" minH="50px" bg="#49688F" className="pl-[20px] pr-[60px] pt-[20px] pb-[20px] rounded-tl-[3px] rounded-tr-[3px] relative">
                      <Text fontSize="16px" className="font-semibold text-white">
                        {cardName}
                      </Text>
                      {!isEmptyValue(currentCard.audio) && <Image className="absolute right-[15px] bottom-[20px] normal-hover" boxSize="30px" src={play} alt="play" />}
                    </Box>
                    {(videoUrl != null || !isEmptyValue(currentCard.video)) && <Box w="100%">
                      <AspectRatio w="100%" ratio={16 / 9}>
                        <Box w="100%">
                          {videoUrl != null && <video controls width="auto" height="100%" src={videoUrl} />}
                          {!isEmptyValue(currentCard.video) && <video controls width="auto" height="100%" src={getFileUrl(currentCard.video)} />}
                        </Box>
                      </AspectRatio>
                    </Box>}
                    <Box w="100%" className="px-[20px] pt-[20px]">
                      {!isEmptyValue(cardContent) && <Text fontSize="16px" className="font-semibold text-[var(--orange)]">
                        Content
                      </Text>}
                      <Text fontSize="16px" className="text-black" dangerouslySetInnerHTML={{ __html: cardContent }} />
                    </Box>
                    <Box w="100%" className="px-[20px] pt-[20px]">
                      {!isEmptyValue(cardTask) && <Text fontSize="16px" className="font-semibold text-[var(--orange)]">
                        Practical Task
                      </Text>}
                      <Text fontSize="16px" className="text-black" dangerouslySetInnerHTML={{ __html: cardTask }} />
                    </Box>
                    <Box w="100%" className="px-[20px] pt-[20px]">
                      {quiz?.questions?.length > 0 && <G2Button21 widthVal="100%" heightVal="35px" text="Take quiz" />}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>}
        </Box>
      )}
    </Box >
  );
};

export default CardEditForm;
