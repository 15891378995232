import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	Box,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Image,
	Text,
	Button,
	Divider,
	Icon,
} from "@chakra-ui/react";

import {
	SunIcon,
	MoonIcon,
	ChevronDownIcon,
	HamburgerIcon,
	SmallCloseIcon,
} from "@chakra-ui/icons";

import { getToken, deleteToken, verifyToken } from "../../utils";
import { getUserData } from "../../Store/Actions/authAction";
import { useStateContext } from "../../ContextProvider/Context";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2Button11 from "../Common/G2Button11";

//import Grow2_Logo from "../../assets/Grow2_Logo.png";
import UTradie_Logo from "../../assets/UTradieLogo.png";
import avatar1 from "../../assets/navbar/avatar1.png";
import avatar2 from "../../assets/navbar/avatar2.png";

import dashboard1 from "../../assets/dashboard/dashboard-icon.png";
import create1 from "../../assets/dashboard/create-icon.png";
import learn1 from "../../assets/dashboard/learn-icon.png";
import marketplace1 from "../../assets/dashboard/marketplace-icon.png";
import life1 from "../../assets/dashboard/life-icon.png";
import academy1 from "../../assets/dashboard/academy-icon.png";

import dashboard from "../../assets/navbar/dashboard-icon.png";
import create from "../../assets/navbar/create-icon.png";
import learn from "../../assets/navbar/learn-icon.png";
import marketplace from "../../assets/navbar/marketplace-icon.png";
import life from "../../assets/navbar/life-icon.png";
import academy from "../../assets/navbar/academy-icon.png";

import aboutus from "../../assets/navbar/aboutus.png";
import contactus from "../../assets/navbar/contactus.png";
import login from "../../assets/navbar/login.png";
import logout from "../../assets/navbar/logout.png";
import UTradieText from "../Common/UTradieText";
import Chat from "../Chat/Chat";
import { getServerUrl } from "../../helpers/location";

import { getFilteredMenu } from "../../helpers/menu";
import { getFileUrl } from "../../helpers/general";

function Navbar(props) {
	const { title, isLanding } = props;

	const { setMode, G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
		useStateContext();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const token = getToken();

	const user = useSelector((state) => state.Auth.user);
	const userData = useSelector((state) => state.Auth.userData);
	const type = useSelector((state) => state.Auth.type);

	const [screenSize, setScreenSize] = useState(1366);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (!isEmptyValue(type)) {
			localStorage.setItem("type", type);
		}

		const cond = localStorage.getItem("type");
		if (verifyToken()) {
			dispatch(getUserData(cond));
		}
	}, []);

	useEffect(() => {
		setScreenSize(window.innerWidth);
		window.addEventListener("resize", setScreenSize);

		setIsOpen(false);
	}, [window.innerWidth]);


	const onClickDashboard = () => {
		navigate("/dashboard");
	};

	const handleItemClick = () => {
		switch (title) {
			case "Create":
				navigate("/create");
				break;
			case "Learn":
				navigate("/learn");
				break;
			case "Marketplace":
				navigate("/marketplace");
				break;
			case "Connections":
				navigate("/life");
				break;
			case "Academy":
				navigate("/academy/digital");
				break;
			default:
				break;
		}
	};

	const menuItems = useMemo(() => {
		return getFilteredMenu(user?.accessRights);
	}, [user]);

	return <Box w="100%" className="navbar">
		<Box w="100%" h="80px" className="px-6 flex-box centering-h justify-between">
			<Box className="flex-box text-left">
				<Box className="mr-5">
					<Image className="logo normal-hover" src={UTradie_Logo} alt="logo" onClick={() => navigate("/")} />
				</Box>
				<Box fontSize="20px" className="hidden sm:block mt-[14px] mr-2 font-semibold text-white normal-hover" onClick={() => navigate("/")}>
					<UTradieText isHeading={true} />
				</Box>
				<Box className="flex-box">
					<Box fontSize="20px" className="hidden sm:block mt-[12px] mr-2 text-white">|</Box>
					<Box fontSize="16px" className="mt-[19px] text-white normal-hover" onClick={handleItemClick}>{title}</Box>
				</Box>
			</Box>
			<Box className="flex-box text-right centering-h">
				<Box className="flex-box justify-between">
					<Box className="flex-box text-left centering-h">
						<IconButton className="mr-5" isRound={true} variant="outline" bg="#032c47" color="white" aria-label="Done" fontSize="20px" icon={G2_theme_mode == "light" ? <SunIcon /> : <MoonIcon />} onClick={setMode} />
					</Box>
					{screenSize >= 1024 ? (
						<Box className="flex-box text-right centering-h">
							<Menu>
								<MenuButton as={Button} bg="#032c47" color="white" _hover={{ bg: "#0e3d5c" }} rightIcon={<ChevronDownIcon />}>
									<UTradieText isHeading={true} />
								</MenuButton>
								<MenuList>
									{menuItems.map((group, index) => {
										return group.map((r, i) => <MenuItem key={(index * 100) + i} onClick={() => navigate(r.path)}>
											<>
												{r.img != undefined && <Image m="5px 10px 5px 0px" boxSize="25px" src={r.img} alt="icon" />}
												{r.icon != undefined && <Icon m="5px 10px 5px 0px" boxSize="25px" color="#4B4B4B" as={r.icon} />}
											</>
											<Text className="text-black">{r.text}</Text>
										</MenuItem>);
									})}
								</MenuList>
							</Menu>
							<G2Button11 text="About us" onClick={() => navigate("/about")} />
							<G2Button11 text="Contact us" onClick={() => navigate("/contact")} />
							{verifyToken() ? (
								<Box className="flex-box centering-h">
									<Box className="ml-5">
										<Image bgColor="white" className="avatar rounded-full" src={getFileUrl(user?.avatar)} alt="avatar" onClick={() => navigate("/setting")} />
									</Box>
									<Box bg="#032c47" color="white" fontSize="16px" className="ml-3 mr-5 font-semibold normal-hover" onClick={() => navigate("/setting")}>
										{user?.name}
									</Box>
									<G2Button11 text="Logout" onClick={() => { deleteToken(); navigate("/login"); }} />
								</Box>
							) : (
								<G2Button11 text="Login" onClick={() => { navigate("/login"); }} />
							)}
						</Box>
					) : (
						<Box className="flex-box text-right centering-h">
							<IconButton isRound={true} variant="outline" bg="#032c47" color="white" aria-label="Done" fontSize="20px" icon={isOpen ? <SmallCloseIcon /> : <HamburgerIcon />} onClick={() => setIsOpen(!isOpen)} />
						</Box>
					)}
				</Box>
			</Box>
		</Box>

		{isOpen && <Box width="100%" className="flex-box bg-[#334F72]">
			{verifyToken() && <Box w="100%">
				<Box w="100%" className="px-[37.5px] py-2.5 centering-h menu-item" onClick={() => navigate("/setting")}>
					<Image className="mr-5 rounded-full" boxSize="35px" src={isEmptyValue(userData?.avatar) ? avatar1 : `${getServerUrl()}learn/getfile?file=${userData.avatar}`} alt="avatar" />
					<Text className="text-white" fontSize="16px">{user.name}</Text>
				</Box>
				<Divider borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation="horizontal" />
			</Box>}

			<Divider borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation="horizontal" />

			{menuItems.map((group, index) => <>
				<Divider borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation="horizontal" />
				{group.map((r, i) => <Box w="100%" key={(index * 100) + i} onClick={() => navigate(r.path)}>
					<Box w="100%" className="px-10 py-2.5 centering-h menu-item">
						<>
							{r.img != undefined && <Image m="5px 10px 5px 0px" boxSize="25px" src={r.img} alt="icon" />}
							{r.icon != undefined && <Icon m="5px 10px 5px 0px" boxSize="25px" color="#FFFFFF" as={r.icon} />}
						</>
						<Text className="text-white">{r.text}</Text>
					</Box>
				</Box>)}
			</>)}

			{verifyToken() ? (
				<Box w="100%" className="px-10 py-2.5 centering-h menu-item" onClick={() => { deleteToken(); navigate("/login"); setIsOpen(false); }}>
					<Image className="mr-5" boxSize="30px" src={logout} alt="icon" />
					<Text className="text-white" fontSize="16px">
						Logout
					</Text>
				</Box>
			) : (<Box w="100%" className="px-10 py-2.5 centering-h menu-item" onClick={() => { navigate("/login"); setIsOpen(false); }}>
				<Image className="mr-5" boxSize="30px" src={login} alt="icon" />
				<Text className="text-white" fontSize="16px">Login</Text>
			</Box>)}
		</Box>}


	</Box>
}

export default Navbar;
