import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    Box,
    Image,
    Text,
    List,
    ListItem,
    ListIcon,
    Divider,
    IconButton,
    Circle,
    useToast
} from "@chakra-ui/react";

import { ArrowForwardIcon, ArrowBackIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import "../../App.css";

import { useStateContext } from '../../ContextProvider/Context';
import { getToken, verifyToken } from "../../utils";
import { getTotalList, getContent, getGroup } from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import ContentAddModal from "./ContentAddModal";
import GroupAddModal from "./GroupAddModal";

import G2Button20 from "../Common/G2Button20";

import show from "../../assets/create/show.png";
import hidden from "../../assets/create/hidden.png";

import { FaArrowRight } from "react-icons/fa";

const LeftSideBar = ({ mode, setMode, screenSize, leftBarShow, setLeftBarShow, leftBarClick }) => {

    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    const navigate = useNavigate();

    const toast = useToast();

    const dispatch = useDispatch();

    const contentOpen = useMemo(() => {
        return mode == "content";
    }, [mode]);

    const groupOpen = useMemo(() => {
        return mode == "group";
    }, [mode]);

    const token = getToken();

    useEffect(() => {
        if (verifyToken()) {
            dispatch(getTotalList(toast));
        }
    }, [])

    const handleContentClick = () => {
        setMode('content');
    }

    const handleGroupClick = () => {
        setMode('group');
    }

    return (
        <Box w="100%" h="100%" style={{ background: "#49688f", color: "#ffffff" }}>
            {leftBarShow ? (
                <Box w="100%" h="100%" className="pb-5">
                    <Box w="100%" h="80px" className="centering">
                        <IconButton isRound={true} className="normal-hover" style={{ backgroundColor: "var(--orange)" }} color="white" aria-label='Done' fontSize='20px' icon={<ArrowBackIcon />} onClick={leftBarClick} />
                    </Box>
                    <Box w="100%">
                        <Box w="100%" className={`cursor-pointer flex-box justify-between px-5 py-[15px] ${contentOpen ? "bg-[#334F72]" : ""}`} onClick={handleContentClick}>
                            <Box className="flex-box text-left">
                                <Text fontSize="16px" className="font-semibold">Content</Text>
                                {/* <Circle size='20px' bg='green.500' color='white' fontSize="sm" className="ml-[10px] centering">{contentList.length}</Circle> */}
                            </Box>
                            <Box className="flex-box text-right">
                                {contentOpen && <FaArrowRight h="20px" className="mt-[2px]" />}
                            </Box>
                        </Box>
                    </Box>

                    <Box w="100%">
                        <Box w="100%" className={`cursor-pointer flex-box justify-between px-5 py-[15px] ${groupOpen ? "bg-[#334F72]" : ""}`} onClick={handleGroupClick}>
                            <Box className="flex-box text-left">
                                <Text fontSize="16px" className="font-semibold">Group</Text>
                            </Box>
                            <Box className="flex-box text-right">
                                {groupOpen && <FaArrowRight h="20px" className="mt-[2px]" />}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <Box w="100%" h="80px" className="centering">
                        <IconButton isRound={true} className="normal-hover" style={{ backgroundColor: "var(--orange)" }} color="white" aria-label='Done' fontSize='20px' icon={<ArrowForwardIcon />} onClick={leftBarClick} />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default LeftSideBar;