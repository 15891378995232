import axios from "axios";
import { getServerUrl } from "../helpers/location";

const { getToken, verifyToken } = require("../utils");

const g2AxiosT = axios.create({
  baseURL: getServerUrl(),
});

// Add a request interceptor
g2AxiosT.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default g2AxiosT;
