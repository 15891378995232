import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  Button,
  Text,
  Input,
  Divider,
  Checkbox,
  useToast,
} from "@chakra-ui/react";

import { SmallCloseIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { addDefaultContent } from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2Button20 from "../Common/G2Button20";

const DefaultContentAddModal = (props) => {
  const { isDCAddOpen, closeDCAddModal } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toast = useToast();

  const contentList = useSelector((state) => state.Create.contentList);
  const group = useSelector((state) => state.Create.group);

  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedContents, setSelectedContents] = useState([]);

  const handleSelectAll = () => {
    setSelectedAll(!selectedAll);

    if (selectedAll == false) {
      const temp = [];
      contentList?.map((val) => {
        temp.push(val._id);
      });
      setSelectedContents(temp);
    } else {
      setSelectedContents([]);
    }
  };

  const handleContentSelect = (id) => {
    if (selectedContents.includes(id)) {
      setSelectedAll(false);

      setSelectedContents(selectedContents.filter((val) => val != id));
    } else {
      if (contentList?.length == selectedContents.length + 1) {
        setSelectedAll(true);
      }
      setSelectedContents([...selectedContents, id]);
    }
  };

  const handleDefaultContentSave = () => {
    if (!isEmptyValue(selectedContents[0])) {
      dispatch(addDefaultContent(toast, group._id, selectedContents));
      setSelectedAll(false);
      setSelectedContents([]);
      closeDCAddModal();
    } else {
      g2Toast({ toast, status: "error", title: "Select content!" });
    }
  };

  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      <Modal size={isSmallScreen ? "lg" : "xl"} isOpen={isDCAddOpen}>
        <ModalOverlay />
        <ModalContent
          style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
        >
          <ModalBody>
            <Box w="100%" className="flex-box py-5">
              <Box
                w="100%"
                className="flex-box justify-between centering-h mb-[10px]"
              >
                <Box className="flex-box text-left">
                  <Text className="block text-lg font-semibold" style={{ color: `${G2_color_3}` }}>
                    Add default contents
                  </Text>
                </Box>
                <Box className="flex-box text-right">
                  <SmallCloseIcon className="exit-icon" style={{ color: `${G2_color_3}` }} onClick={closeDCAddModal} />
                </Box>
              </Box>
              <Divider borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation="horizontal" className="mb-[10px]" />
              {contentList ? (
                <Box w="100%" className="mt-[10px]">
                  <Checkbox size="md" className="mr-5" borderColor="green.500" isChecked={selectedAll} onChange={handleSelectAll}>
                    Select All
                  </Checkbox>
                  <Box className="mt-[5px] mb-[10px]">
                    <Divider w="90px" borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation="horizontal" />
                  </Box>
                  {contentList.filter((element) => !group.defaultContentIds?.includes(element._id))?.map((val, i) => (
                    <Box key={i} w="100%">
                      <Checkbox size="md" className="mr-5 add-content" borderColor="blue.500" colorScheme="green" isChecked={selectedContents.includes(val._id)} onChange={() => handleContentSelect(val._id)}>
                        {val.title}
                      </Checkbox>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box w="100%">
                  <Text w="100%" className="text-sm">
                    You have no content.
                  </Text>
                </Box>
              )}
              <Box w="100%" className="mt-5">
                <G2Button20
                  widthVal="100%"
                  heightVal="40px"
                  text="Save"
                  onClick={handleDefaultContentSave}
                />
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DefaultContentAddModal;
