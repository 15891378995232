import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    Box,
    Text,
    Image,
    Divider,
    Card,
    CardBody,
    Select,
    Progress,
    Circle,
    useToast,
    InputGroup,
    Input,
    InputRightElement,
    IconButton,
    Checkbox,
    List,
    ListItem,
    VStack,
    HStack,
    Avatar,
    Heading
} from "@chakra-ui/react";

import { ArrowBackIcon, CalendarIcon, SearchIcon } from '@chakra-ui/icons';

import "../../App.css";

import { useStateContext } from '../../ContextProvider/Context';
import { getContentList, getInvitation } from "../../Store/Actions/learnAction";
import { getToken, verifyToken } from "../../utils";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import InvitationModal from "./InvitationModal";

import G2Button2 from "../Common/G2Button2";
import G2Button20 from "../Common/G2Button20";

import notification1 from "../../assets/learn/notification1.png";
import notification2 from "../../assets/learn/notification2.png";

import success from "../../assets/learn/success.png";
import fail from "../../assets/learn/fail.png";
import group2 from "../../assets/create/group2.png";
import content1 from "../../assets/create/content1.png";
import content2 from "../../assets/create/content2.png";
import { getFileUrl } from "../../helpers/general";

const MainFirstForm = (props) => {
    const { screenSize, boxW1, boxW2 } = props;

    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const toast = useToast();

    const invitation = useSelector((state) => state.Learn.invitation);
    const memberList = useSelector((state) => state.Learn.memberList);

    const [isAIopen, setIsAIOpen] = useState(false);

    const token = getToken();

    useEffect(() => {
        if (verifyToken()) {
            dispatch(getInvitation(toast));
        }
    }, [])

    const openInvitationModal = () => {
        setIsAIOpen(true);
    };

    const closeInvitationModal = () => {
        setIsAIOpen(false);
    };
    const status = true;

    const handleGroupClick = (i, val, ids) => {
        dispatch(getContentList(toast, navigate, val, ids));
    };

    return (
        <Box w="100%" h="100%" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
            <Box w="100%" h="80px" bg={G2_color_2} className="flex-box centering-h shadow">
                <Box w="100%" className="flex-box">
                    <Box w="70px" className="centering relative">
                        <Box className="normal-hover invite-notification" onClick={openInvitationModal}>
                            {isEmptyValue(invitation[0]?._id) ? (
                                <Image width="30px" height="35px" src={notification1} alt='notification' />
                            ) : (
                                <Image width="30px" height="35px" src={notification2} alt='notification' />
                            )}
                            {invitation?.length != 0 ? (
                                <Box w="15px" h="15px" className="absolute top-[2px] right-[22px] centering">
                                    <Text fontSize="12px" className="text-white">{invitation?.length}</Text>
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Box>
                        <InvitationModal isAIopen={isAIopen} closeInvitationModal={closeInvitationModal} />
                    </Box>
                    <Heading size="lg">Learn</Heading>
                </Box>
            </Box>
            <Box w="100%" h="100%" className="pb-5">
                <Box w="100%" className="learn-accordion-box">
                    {memberList ? (<>
                        {memberList?.map((val, i) => (
                            <>
                                <Box key={i} w="100%" bg={G2_color_2} className="learn-content-box flex-box centering-h py-[20px] pr-[20px] mb-[20px] rounded-[2.5px] shadow normal-hover" onClick={() => handleGroupClick(i, val, val.groupId.defaultContentIds)}>
                                    <HStack w="100%" pl="10px">
                                        <Box w="60px" className="centering-w">
                                            <Avatar size="md" src={getFileUrl(val.userId.avatar)} alt="icon" />
                                        </Box>
                                        <VStack w="100%">

                                            <HStack w="100%">
                                                <Text fontSize="16px">{val.groupId.title}</Text>
                                            </HStack>
                                            <HStack w="100%">
                                                <Text fontSize="16px">Invitation from: {val.userId.name}</Text>
                                            </HStack>
                                        </VStack>
                                    </HStack>


                                </Box>
                            </>

                        ))}
                    </>
                    ) : (
                        <Box className="px-5 mt-5">
                            <Text fontSize="16px">There are no groups joined.</Text>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box >
    );
};

export default MainFirstForm;