import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";

import Navbar from "../../Components/Navbar/Navbar";
import MainMarketForm from "../../Components/Marketplace/MainMarketForm";

import UniversalLeftSideBar from "../../Components/Common/UniversalLeftSideBar";

const PreviewPage = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const token = getToken();

  // useEffect(() => {
  //     if (!verifyToken()) {
  //         navigate("/login");
  //     }
  // }, []);

  return (
    <Box
      minH="100vh"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box w="100%">
        <Navbar title="Marketplace" />
      </Box>
      <Box w="100%" className="flex-box">
        <Box className="universal-leftBar-w">
          <Box w="100%" h="100vh" className="sticky top-0">
            <UniversalLeftSideBar />
          </Box>
        </Box>
        <Box className="universal-body-w">
          <MainMarketForm />
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewPage;
