import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  IconButton,
  AspectRatio,
  Select,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  useToast,
  Spinner,
} from "@chakra-ui/react";

import { StarIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { addContent, makePreview } from "../../Store/Actions/lifeAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import ReviewModal from "./ReviewModal";

import G2Button1 from "../Common/G2Button1";
import G2Button2 from "../Common/G2Button2";

import cover from "../../assets/life/cover.png";
import logo from "../../assets/life/logo.png";

import yesImg from "../../assets/marketplace/yesImg.png";
import noImg from "../../assets/marketplace/noImg.png";

const table = [
  {
    name: "Set monthly goals",
    state: true,
  },
  {
    name: "Monthly 1 on 1 call",
    state: true,
  },
  {
    name: "Live online chat",
    state: true,
  },
  {
    name: "Monthly webinar",
    state: true,
  },
  {
    name: "Exclusive content",
    state: true,
  },
];

const PreviewForm = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const [wait, setWait] = useState(false);

  const user = useSelector((state) => state.Auth.user);
  const preview = useSelector((state) => state.Life.preview);

  const [isOpen, setIsOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");

  useEffect(() => {
    if (isEmptyValue(preview.name)) {
      navigate("/life");
    }
  }, []);

  const openReviewModal = () => {
    setIsOpen(true);
  };

  const handleRatingClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const onClickBack = () => {
    navigate("/life/register");
  };

  const onClickAdd = async () => {
    try {
      const blob1 = await fetch(preview.coverUrl).then((r) => r.blob());
      const coverFile = new File([blob1], preview.coverName, {
        type: preview.coverType,
      });

      const blob2 = await fetch(preview.logoUrl).then((r) => r.blob());
      const logoFile = new File([blob2], preview.logoName, {
        type: preview.logoType,
      });

      const blob3 = await fetch(preview.videoUrl).then((r) => r.blob());
      const videoFile = new File([blob3], preview.videoName, {
        type: preview.videoType,
      });

      const files = [coverFile, logoFile, videoFile];

      const data = {
        name: preview.name,
        bioDescription: preview.bioDescription,
        isPropit: preview.isPropit,
        industry: preview.industry,
        businessName: preview.businessName,
        website: preview.website,
        support: preview.support,
        skill: preview.skill,
        subDescription: preview.subDescription,
        videoDescription: preview.videoDescription,
        serviceList: preview.serviceList,
        businessCost: preview.businessCost,
        memberCost: preview.memberCost,
        posted: true,
        itemId: preview.itemId,
        nodeId: preview.nodeId,
      };

      const formData = new FormData();

      files?.forEach((file, i) => {
        formData.append("files", file);
      });
      formData.append("data", JSON.stringify(data));

      setWait(true);

      dispatch(addContent(toast, navigate, formData, setWait));
    } catch (error) {
      console.error("Error fetching location", error);
    }
  };

  return (
    <Box style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      {wait ? (
        <Box w="100%" className="centering-w mt-[200px]">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
        </Box>
      ) : (
        <Box w="100%">
          <Box className="life-preview-main flex-box centering-h">
            <Box className="market-preview-cover px-5 centering">
              <Image
                width="100%"
                height="auto"
                src={preview.coverUrl}
                fallbackSrc={cover}
                alt="uploadedImage"
              />
            </Box>
            <Box className="market-preview-descripton flex-box centering-h">
              <Box w="100%">
                <Text className="font-semibold text-white" fontSize="26px">
                  {preview.name}
                </Text>
              </Box>
              <Box w="100%" className="mt-5">
                <Text className="text-white" fontSize="14px">
                  Works for
                </Text>
                <Text className="font-semibold text-[#FFC529]" fontSize="16px">
                  {preview.businessName}
                </Text>
              </Box>
              <Box w="100%" className=" mt-5">
                <Text className="text-white" fontSize="16px">
                  {preview.bioDescription}
                </Text>
              </Box>
              <Box w="100%" className="flex-box mt-5 centering-h">
                <StarIcon color="yellow.300" className="mr-2" />
                <Text className="text-white mr-5" fontSize="16px">
                  0/5
                </Text>
                <G2Button2
                  widthVal="120px"
                  heightVal="35px"
                  text="Add a review"
                  onClick={openReviewModal}
                />
              </Box>
            </Box>
            <Box className="market-preview-logo flex-box centering">
              <Box w="100%" className="centering-w">
                <Image
                  borderRadius="full"
                  boxSize="150px"
                  src={preview.logoUrl}
                  fallbackSrc={logo}
                  alt="uploadedImage"
                />
              </Box>
              <Box w="100%" className="flex-box centering-w mt-5">
                <Text
                  w="100%"
                  className="text-center font-semibold text-[#FFC529]"
                  fontSize="16px"
                >
                  {preview.businessName}
                </Text>
                <Text
                  w="100%"
                  className="text-center font-semibold text-[#35D8A7]"
                  fontSize="16px"
                >
                  $ {preview.memberCost} /Week
                </Text>
              </Box>
              <Box w="100%" className="centering-w mt-5">
                <Select
                  size="sm"
                  w="210px"
                  height="35px"
                  bg="white"
                  color="black"
                  style={{
                    background: `${G2_color_2}`,
                    color: `${G2_color_3}`,
                    borderRadius: "2.5px",
                  }}
                >
                  <option
                    value="option1"
                    style={{
                      background: `${G2_color_2}`,
                      color: `${G2_color_3}`,
                    }}
                  >
                    Per assigned member
                  </option>
                  <option
                    value="option2"
                    style={{
                      background: `${G2_color_2}`,
                      color: `${G2_color_3}`,
                    }}
                  >
                    Per assigned creator
                  </option>
                </Select>
              </Box>
            </Box>
          </Box>
          <Box
            className="flex-box"
            style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
          >
            <Box className="market-preview-video">
              <AspectRatio w="100%" ratio={16 / 9}>
                <Box>
                  <video
                    controls
                    width="100%"
                    height="auto"
                    src={preview.videoUrl}
                  />
                </Box>
              </AspectRatio>
            </Box>
            <Box className="market-preview-video-des flex-box centering">
              <Text w="100%" fontSize="16px">
                {preview.subDescription}
              </Text>
              <Text w="100%" fontSize="16px">
                {preview.videoDescription}
              </Text>
              <Text w="100%" fontSize="16px">
                Click on the video to view a preview
              </Text>
            </Box>
            <Box className="market-preview-table">
              <Box>
                <Text fontSize="20px" className="font-semibold mb-5">
                  Services
                </Text>
                <TableContainer
                  css={{
                    background: `${G2_color_1}`,
                    color: `${G2_color_3}`,
                    borderRadius: "10px",
                  }}
                >
                  <Table>
                    <Thead>
                      <Tr>
                        <Th
                          fontSize="16px"
                          p="5"
                          css={{
                            background: `${G2_color_1}`,
                            color: `${G2_color_3}`,
                          }}
                        >
                          Description
                        </Th>
                        <Th
                          fontSize="16px"
                          css={{
                            background: `${G2_color_1}`,
                            color: `${G2_color_3}`,
                          }}
                        ></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {preview.serviceList?.map((val, i) => (
                        <Tr key={i}>
                          <Td>{val}</Td>
                          <Td>
                            <Image boxSize="20px" src={yesImg} alt="video" />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box w="100%" className="centering-w mb-10">
              <Box className="flex flex-wrap centering-w">
                <G2Button1
                  widthVal="200px"
                  text="Back to Register Page"
                  onClick={onClickBack}
                />
                <G2Button2
                  widthVal="200px"
                  text="Add to Connections"
                  onClick={onClickAdd}
                />
              </Box>
            </Box>
          </Box>
          <ReviewModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            rating={rating}
            reviewText={reviewText}
            handleRatingClick={handleRatingClick}
            setReviewText={setReviewText}
          />
        </Box>
      )}
    </Box>
  );
};

export default PreviewForm;
