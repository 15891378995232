import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  Button,
  Text,
  Input,
  Divider,
  useToast,
} from "@chakra-ui/react";

import { SmallCloseIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { updateUnit } from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2Button20 from "../Common/G2Button20";

const UnitEditModal = (props) => {
  const { isUEditOpen, closeUEditModal, selectedUnits, setSelectedUnits } =
    props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toast = useToast();

  const unitList = useSelector((state) => state.Create.unitList);

  const unit = unitList.find((val) => val._id == selectedUnits[0]);

  const [unitName, setUnitName] = useState("");

  useEffect(() => {
    setUnitName(unit.title);
  }, [selectedUnits]);

  const handleSaveClick = () => {
    if (!isEmptyValue(unitName)) {
      dispatch(updateUnit(toast, selectedUnits[0], unitName));
      setSelectedUnits([]);
      setUnitName("");
      closeUEditModal();
    } else {
      g2Toast({ toast, status: "error", title: "Input unit name!" });
    }
  };

  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      <Modal size={isSmallScreen ? "lg" : "xl"} isOpen={isUEditOpen}>
        <ModalOverlay />
        <ModalContent
          style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
        >
          <ModalBody>
            <Box w="100%" className="flex-box py-5">
              <Box
                w="100%"
                className="flex-box justify-between centering-h mb-[10px]"
              >
                <Box className="flex-box text-left">
                  <Text
                    className="block text-lg font-semibold"
                    style={{ color: `${G2_color_3}` }}
                  >
                    Edit a unit
                  </Text>
                </Box>
                <Box className="flex-box text-right">
                  <SmallCloseIcon
                    className="exit-icon"
                    style={{ color: `${G2_color_3}` }}
                    onClick={closeUEditModal}
                  />
                </Box>
              </Box>
              <Divider
                borderColor={G2_theme_mode == "light" ? "black" : "white"}
                orientation="horizontal"
                className="mb-[10px]"
              />
              <Text
                w="100%"
                className="block text-sm font-semibold mt-5 mb-2"
                style={{ color: `${G2_color_3}` }}
              >
                Name
              </Text>
              <Input
                w="100%"
                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
                style={{
                  background: `${G2_color_1}`,
                  color: `${G2_color_3}`,
                  borderRadius: "2.5px",
                }}
                value={unitName}
                onChange={(e) => setUnitName(e.target.value)}
              />
              <Box w="100%" className="mt-5">
                <G2Button20
                  widthVal="100%"
                  heightVal="40px"
                  text="Save"
                  onClick={handleSaveClick}
                />
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UnitEditModal;
