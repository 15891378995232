import axios from "axios";
import { getServerUrl } from "../helpers/location";

const { getToken, verifyToken } = require("../utils");

const g2Axios = axios.create({
  baseURL: getServerUrl(),
});

export default g2Axios;
