import React from "react";
import CommonExternalPage from "./CommonExternalPage";

const content = [
  {
    title: "uTRADIE Pricing",
    body: ""
  },
  {
    title: "uTRADIE Membership",
    body: "Just $5.50 AUD/Month",
    points: [
      "Perfect for Individuals: Ideal for tradies, apprentices, mentors, and school students looking to build skills and create their careers.",
      "Simplified Growth: A single platform offering personalised learning and development for both personal and professional advancement.",
      "Flexible Sharing: Forward your subscription to mentors with ease, supporting students, apprentices, and those facing challenges.",
      "Affordable Learning: Access extensive resources for growth and development at the price of a single coffee.",
    ]
  },
  {
    title: "uTRADIE Business Account",
    body: "Only $55 AUD/Month",
    points: [
      "Support Your Team and Industry: Ideal for business owners, sponsors, and industry reps looking to enhance skills or stay ahead of the curve, while empowering their staff and supporting their customers.",
      "Build & Connect: Strengthen your uTRADIE profile to be recognised in your industry, showcasing your culture to attract talent while uniting your team.",
      "Enhanced Job Posting: Keep your job posts active for 90 days, ensuring maximum visibility.",
      "Job Referral System: Amplify your reach by leveraging uTRADIE's network.",
      "Talent Scouting: Use our advanced tools to find and attract the top talent in your industry.",
      "Exclusive Insights: Gain access to unique data and trends in your industry.",
      "Networking Opportunities: Connect with a diverse pool of professionals.",
    ]
  },
  {
    title: "Earning Opportunities on uTRADIE",
    body: "",
    points: [
      "Connect Features: Offer mentoring or support sessions at your own rates and earn from your expertise.",
      "Marketplace: Sell your unique content to businesses or individuals seeking your knowledge.",
      "Sponsorships: Partner with brands for endorsements through uTRADIE Create, broadening your income streams.",
      "Note: A nominal 4.5% surcharge applies to earnings through uTRADIE, facilitating these transactions securely on our platform.",
    ]
  },
];

const PricingPage = () => {
  return <CommonExternalPage title="Pricing" content={content} />
};

export default PricingPage;
