import React from "react";
import CommonExternalPage from "./CommonExternalPage";

const content = [
  {
    title: "Terms and Conditions for uTRADIE",
    body: "Effective Date: 29/05/2024"
  },
  {
    title: "Introduction",
    body: "Welcome to uTRADIE! These Terms and Conditions govern your use of our website and services, and by accessing uTRADIE, you agree to these terms in full. If you disagree with any part of these terms, you must not use our platform."
  },
  {
    title: "Intellectual Property Rights",
    body: "Other than content you own, which you may have opted to include on this Website, under these Terms, Grow2 Pty Ltd and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved."
  },
  {
    title: "Restrictions",
    body: "You are expressly restricted from the following:",
    points: [
      "Publishing any Website material in any media without proper attribution;",
      "Selling, sublicensing, and/or otherwise commercialising any Website material;",
      "Publicly performing and/or showing any Website material;",
      "Using this Website in any way that is, or may be, damaging to this Website;",
      "Using this Website in any way that impacts user access to this Website;",
      "Using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;",
      "Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this Website, or while using this Website.",
    ]
  },
  {
    title: "Content",
    body: "Content found on or through this service are the properties of Grow2 Pty Ltd or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us."
  },
  {
    title: "User-Generated Content",
    body: "Grow2 Pty Ltd is not responsible for content created and posted by its users. The views expressed by users on uTRADIE do not necessarily reflect or represent the views and opinions of Grow2 Pty Ltd. Users are responsible for the content they create, share, and post on or through uTRADIE, including its legality, reliability, and appropriateness. By posting content to uTRADIE, you grant Grow2 Pty Ltd a non-exclusive, worldwide, irrevocable, royalty-free licence to use, reproduce, adapt, publish, translate, and distribute it in any and all media."
  },
  {
    title: "Right to Remove or Modify Content",
    body: "If any content is found to be in violation of these terms, or is flagged as illegal, offensive, threatening, libellous, defamatory, obscene, or otherwise objectionable, or infringes any party’s intellectual property, Grow2 Pty Ltd reserves the right to remove such content and account at any time without notice."
  },
  {
    title: "Accounts",
    body: "If you create an account on uTRADIE, you are responsible for maintaining the security of your account, and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. You must notify us immediately upon becoming aware of any unauthorised use of your account or any other breaches of security."
  },
  {
    title: "Limitation of Liability",
    body: "In no event will Grow2 Pty Ltd, nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort, or otherwise, and Grow2 Pty Ltd, including its officers, directors, and employees shall not be liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website."
  },
  {
    title: "Indemnification",
    body: "You hereby indemnify to the fullest extent Grow2 Pty Ltd from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms."
  },
  {
    title: "Severability",
    body: "If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein."
  },
  {
    title: "Variation of Terms",
    body: "Grow2 Pty Ltd is permitted to revise these terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website."
  },
  {
    title: "Assignment",
    body: "Grow2 Pty Ltd shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms."
  },
  {
    title: "Entire Agreement",
    body: "These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Grow2 Pty Ltd and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same."
  }
];

const TermsPage = () => {
  return <CommonExternalPage title="Terms and Conditions" content={content} />
};

export default TermsPage;
