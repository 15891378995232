import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  useToast,
  Input,
  Checkbox,
  IconButton,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";

import { EditIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import {
  deleteGroup,
  deleteMemberList,
  removeDefaultContent,
  addAttachment,
  removeAttachment,
} from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

import InviteModal from "./InviteModal";
import GroupEditModal from "./GroupEditModal";
import DefaultContentAddModal from "./DefaultContentAddModal";
import MemberComponent from "./MemberComponent";

import G2Button19 from "../Common/G2Button19";
import G2Button20 from "../Common/G2Button20";
import G2Button23 from "../Common/G2Button23";

import content1 from "../../assets/create/content1.png";
import content2 from "../../assets/create/content2.png";
import group1 from "../../assets/create/group1.png";
import group2 from "../../assets/create/group2.png";
import edit1 from "../../assets/create/edit1.png";
import edit2 from "../../assets/create/edit2.png";
import attachment from "../../assets/create/attachment.png";
import user from "../../assets/create/user.png";
import { isDragActive } from "framer-motion";

const MainGroupForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const fileInputRef = useRef(null);

  const [wait, setWait] = useState(false);

  const contentList = useSelector((state) => state.Create.contentList);
  const group = useSelector((state) => state.Create.group);
  const memberList = useSelector((state) => state.Create.memberList);

  const [isGroupDopen, setIsGroupDopen] = useState(false);
  const [isMemberDopen, setIsMemberDopen] = useState(false);
  const [isDefaultContentDopen, setIsDefaultContentDopen] = useState(null);
  const [isAttachmentDopen, setIsAttachmentDopen] = useState(null);

  const [isIAddopen, setIsIAddOpen] = useState(false);
  const [isGEditOpen, setIsGEditOpen] = useState(false);
  const [isDCAddOpen, setIsDCAddOpen] = useState(false);

  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);


  useEffect(() => {
    if (isEmptyValue(group._id)) {
      navigate("/create");
    }
  }, []);

  useEffect(() => {
    setSelectedMembers([]);
  }, [group]);

  const handleSelectAll = () => {
    setSelectedAll(!selectedAll);

    if (selectedAll == false) {
      const temp = [];
      memberList?.map((val) => {
        temp.push(val._id);
      });
      setSelectedMembers(temp);
    } else {
      setSelectedMembers([]);
    }
  };

  const handleMemberSelect = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    if (selectedMembers?.includes(id)) {
      setSelectedAll(false);

      setSelectedMembers(selectedMembers?.filter((val) => val != id));
    } else {
      if (memberList?.length == selectedMembers?.length + 1) {
        setSelectedAll(true);
      }

      setSelectedMembers([...selectedMembers, id]);
    }
  };

  const onClickFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedFileTypes = [".pdf", ".doc", ".docx", ".mp3", ".wav", ".mp4", ".mpg", ".avi", ".3gp", ".jpg", ".jpeg", ".png"];
    const fileExtension = "." + selectedFile.name.split(".").pop();

    if (event.target.files.length == 0) {
      g2Toast({ toast, status: "error", title: "Select a file!" });
      return;
    }

    if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
      g2Toast({ toast, status: "error", title: "Invalid file type!" });
      return;
    }

    if (selectedFile.size > 100 * 1024 * 1024) {
      alert("File size exceeds the maximum allowed size of " + 100 + " MB.");
      return;
    }

    if (!isEmptyValue(group._id) && selectedFile) {
      const formData = new FormData();

      formData.append("file", selectedFile);

      setWait(true);
      dispatch(addAttachment(toast, group._id, formData, setWait));
    } else {
      g2Toast({ toast, status: "error", title: "Invalid input!" });
    }
  };

  const openIAddModal = () => {
    setIsIAddOpen(true);
  };

  const closeIAddModal = () => {
    setIsIAddOpen(false);
  };

  const openGEditModal = () => {
    setIsGEditOpen(true);
  };

  const closeGEditModal = () => {
    setIsGEditOpen(false);
  };

  const openDCAddModal = () => {
    setIsDCAddOpen(true);
  };

  const closeDCAddModal = () => {
    setIsDCAddOpen(false);
  };

  const openGroupDModal = () => {
    setIsGroupDopen(true);
  };

  const closeGroupDModal = () => {
    setIsGroupDopen(false);
  };

  const handleDeleteGroup = () => {
    dispatch(deleteGroup(toast, navigate, group._id));

    setIsGroupDopen(false);
  };

  const openMemberDModal = () => {
    setIsMemberDopen(true);
  };

  const closeMemberDModal = () => {
    setIsMemberDopen(false);
  };

  const handleDeleteMember = () => {
    if (!isEmptyValue(selectedMembers[0])) {
      dispatch(deleteMemberList(toast, group._id, selectedMembers));
      setSelectedMembers([]);
      setIsMemberDopen(false);
    } else {
      g2Toast({ toast, status: "error", title: "Select member!" });
    }
  };

  const openDefaultContentDModal = (i) => {
    setIsDefaultContentDopen(i);
  };

  const closeDefaultContentDModal = () => {
    setIsDefaultContentDopen(null);
  };

  const handleDefaultContentRemove = (id) => {
    dispatch(removeDefaultContent(toast, group._id, id));

    setIsDefaultContentDopen(null);
  };

  const openAttachmentDModal = (i) => {
    setIsAttachmentDopen(i);
  };

  const closeAttachmentDModal = () => {
    setIsAttachmentDopen(null);
  };

  const handleAttachmentRemove = (id) => {
    dispatch(removeAttachment(toast, group._id, id));

    setIsAttachmentDopen(null);
  };

  return (
    <Box w="100%" className="flex-box" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box w="100%" bg={G2_color_2} className="create-group-main centering-h shadow">
        <Box w="100%" className="flex-box justify-between centering-h">
          <Box className="flex-box text-left centering-h">
            <Image boxSize="25px" src={G2_theme_mode == "light" ? group1 : group2} alt="icon" />
            <Text fontSize="16px" className="font-semibold mx-[10px]">
              {group.title}
            </Text>
          </Box>
          <Box className="flex-box text-right centering-h">
            <Box className="mr-5 normal-hover flex" onClick={openGEditModal}>
              <EditIcon boxSize="15px" className="mt-[5px] mr-[5px]" />
              <Text>Edit group</Text>
            </Box>
            <Box className="mr-5 normal-hover flex" onClick={openGroupDModal}>
              <DeleteIcon boxSize="15px" className="mt-[5px] mr-[5px]" />
              <Text>Delete group</Text>
            </Box>
            <G2ConfirmAlert isOpen={isGroupDopen} onClose={closeGroupDModal} handleOkClick={handleDeleteGroup} />
            <G2Button20 widthVal="90px" heightVal="35px" text="Invite" onClick={openIAddModal} />
            <InviteModal isIAddopen={isIAddopen} closeIAddModal={closeIAddModal} groupId={group._id} />
          </Box>
        </Box>
        <GroupEditModal isGEditOpen={isGEditOpen} closeGEditModal={closeGEditModal} />
      </Box>
      <Box className="create-group-main-left pb-[10px]">
        <Box w="100%" h="55px" className="flex-box justify-between centering-h px-[10px]">
          <Box className="flex justify-start centering-h mr-10 mt-5">
            <Checkbox size="md" className="mr-5" borderColor="blue.500" colorScheme="green" isChecked={selectedAll} onChange={handleSelectAll}>
              Select All
            </Checkbox>
          </Box>
          <Box className="flex justify-end centering-h mt-5">
            {!isEmptyValue(selectedMembers[0]) && <G2Button19 widthVal="90px" heightVal="35px" text="Delete" onClick={openMemberDModal} />}
            <G2ConfirmAlert isOpen={isMemberDopen} onClose={closeMemberDModal} handleOkClick={handleDeleteMember} />
          </Box>
        </Box>
        <Box w="100%" className="flex-box px-[10px] my-[10px]">
          <Divider borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation="horizontal" />
        </Box>
        <Box w="100%">
          {!isEmptyValue(memberList[0]?._id) ? (
            <Box w="100%" className="flex-box">
              {memberList.map((element, i) => (
                <MemberComponent key={i} boxW2={boxW2} member={element} selectedMembers={selectedMembers} handleMemberSelect={handleMemberSelect} />
              ))}
            </Box>
          ) : (
            <Box w="100%" className="flex-box mt-[150px]">
              <Text w="100%" fontSize="16px" className="text-center">
                There are no members.
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box className="create-group-main-right" pt="10px" pb="30px">
        <Text fontSize="16px" my="15px" className="font-semibold">
          Default content
        </Text>
        {contentList && <Box className="mb-[15px]">
          {contentList
            .filter((element) =>
              group.defaultContentIds?.includes(element._id)
            )
            ?.map((val, i) => (
              <Box key={i} w="100%" bg={G2_color_2} className="flex-box centering-h py-[10px] mb-[10px] rounded-[2.5px] shadow">
                <Box w="50px" className="centering">
                  <Image boxSize="20px" src={G2_theme_mode == "light" ? content1 : content2} alt="icon" />
                </Box>
                <Box className="create-default-ceontent">
                  <Text fontSize="16px">{val.title}</Text>
                </Box>
                <Box w="40px" className="centering">
                  <DeleteIcon className="normal-hover" color={G2_color_3} fontSize="15px" onClick={() => openDefaultContentDModal(i)} />
                  <G2ConfirmAlert isOpen={i == isDefaultContentDopen} onClose={closeDefaultContentDModal} handleOkClick={() => handleDefaultContentRemove(val._id)} />
                </Box>
              </Box>
            ))}
        </Box>}
        <G2Button23 widthVal="140px" heightVal="35px" text="Assign content" onClick={openDCAddModal} />
        <DefaultContentAddModal isDCAddOpen={isDCAddOpen} closeDCAddModal={closeDCAddModal} />
        <Box w="100%" mt="20px" className=" flex centering-h">
          <Text fontSize="16px" className="font-semibold">
            Attachment
          </Text>
          <Text fontSize="16px" className="ml-[5px]">
            (MaxSize: 100Mbyte)
          </Text>
        </Box>
        <Box w="100%">
          <G2Button23
            widthVal="110px"
            heightVal="35px"
            text="Document"
            onClick={onClickFileInput}
          />
        </Box>
        <Input
          type="file"
          ref={fileInputRef}
          accept=".pdf, .doc, .docx, .mp3, .wav, .mp4, .mpg, .avi, .3gp, .jpg, .jpeg, .png"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        {group.attachments && (
          <Box w="100%">
            {group.attachments?.map((val, i) => (
              <Box key={i} w="100%" className="flex mt-5 relative">
                <Tooltip
                  label={val.attachment?.split("/").pop()}
                  placement="bottom-start"
                >
                  <Image
                    width="60px"
                    height="auto"
                    src={attachment}
                    alt="attachment"
                  />
                </Tooltip>
                <IconButton
                  position="absolute"
                  top="10px"
                  left="65px"
                  variant="outline"
                  isRound={true}
                  fontSize="15px"
                  bg={G2_color_2}
                  color={G2_color_3}
                  icon={<DeleteIcon />}
                  onClick={() => openAttachmentDModal(i)}
                />
                <G2ConfirmAlert
                  isOpen={i == isAttachmentDopen}
                  onClose={closeAttachmentDModal}
                  handleOkClick={() => handleAttachmentRemove(val.id)}
                />
              </Box>
            ))}
          </Box>
        )}
        <Box w="100%" className="flex mt-[15px] centering-h">
          {wait && <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="md" />}
        </Box>
      </Box>
    </Box>
  );
};

export default MainGroupForm;
