import React from "react";

import {
    Box,
    Stack,
    Checkbox,
    RadioGroup,
    Radio,
    FormLabel
} from "@chakra-ui/react";

import { useStateContext } from '../../ContextProvider/Context';

import {
    options1,
    options2,
    options3,
    options4,
    options5,
} from "../../constant";
import UTradieText from "../Common/UTradieText";

const GoalsAndObjectivesForm = (props) => {
    const { selectedOptions1, selectedOptions2, selectedOptions3, selectedOptions4, selectedOptions5, setSelectedOptions3, setSelectedOptions4, setSelectedOptions5, handleFieldChange3, handleOptionChange } = props;

    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    return (
        <Box style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
            <Box width="100%" h="50px"></Box>
            <Box className={`font-bold text-[18px] text-start`}>
                Answer the following questions to decide your objective
            </Box>
            <Box width="100%" h="20px"></Box>
            <Box>
                <Box>
                    <FormLabel as="legend">
                        1. What inspired you to join <UTradieText />?
                    </FormLabel>
                    <Stack spacing={2}>
                        {options1.map((option) => (
                            <Checkbox
                                borderColor="blue.500"
                                colorScheme='green'
                                key={option.id}
                                isChecked={selectedOptions1.includes(option.id)}
                                onChange={() => handleOptionChange(1, option.id)}
                            >
                                {option.text}
                            </Checkbox>
                        ))}
                    </Stack>
                </Box>
                <Box width="100%" h="20px"></Box>
                <Box>
                    <FormLabel as="legend">
                        2. What do you hope to achieve by using <UTradieText />?
                    </FormLabel>
                    <Stack spacing={2}>
                        {options2.map((option) => (
                            <Checkbox
                                borderColor="blue.500"
                                colorScheme='green'
                                key={option.id}
                                isChecked={selectedOptions2.includes(option.id)}
                                onChange={() => handleOptionChange(2, option.id)}
                            >
                                {option.text}
                            </Checkbox>
                        ))}
                    </Stack>
                </Box>
                <Box width="100%" h="20px"></Box>
                <Box>
                    <FormLabel as="legend">
                        3. Are you looking to develop your skills or learn something new?
                    </FormLabel>
                    <RadioGroup
                        spacing={2}
                        value={selectedOptions3.toString()}
                        onChange={(value) => setSelectedOptions3(parseInt(value, 10))}
                    >
                        {options3.map((option, i) => (
                            <Radio key={i} borderColor="blue.500" colorScheme='green' width={"100%"} value={option.id.toString()}>
                                {option.text}
                            </Radio>
                        ))}
                    </RadioGroup>
                </Box>
                <Box width="100%" h="20px"></Box>
                <Box>
                    <FormLabel as="legend">
                        4. Do you have any specific career goals in mind?
                    </FormLabel>
                    <RadioGroup
                        spacing={2}
                        value={selectedOptions4.toString()}
                        onChange={(value) => setSelectedOptions4(parseInt(value, 10))}
                    >
                        {options4.map((option, i) => (
                            <Radio key={i} borderColor="blue.500" colorScheme='green' width={"100%"} value={option.id.toString()}>
                                {option.text}
                            </Radio>
                        ))}
                    </RadioGroup>
                </Box>
                <Box width="100%" h="20px"></Box>
                <Box>
                    <FormLabel as="legend">
                        5. Are you interested in networking with other professionals in
                        your industry?
                    </FormLabel>
                    <RadioGroup
                        spacing={2}
                        value={selectedOptions5.toString()}
                        onChange={(value) => setSelectedOptions5(parseInt(value, 10))}
                    >
                        {options5.map((option, i) => (
                            <Radio key={i} borderColor="blue.500" colorScheme='green' width={"100%"} value={option.id.toString()}>
                                {option.text}
                            </Radio>
                        ))}
                    </RadioGroup>
                </Box>
            </Box>
        </Box>
    );
};

export default GoalsAndObjectivesForm;
