import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
	Box,
	Text,
	Image,
	Divider,
	useToast,
	Input,
	Checkbox,
} from "@chakra-ui/react";

import { EditIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";

import "../../App.css";

import UnitComponent from "./UnitComponent";

import { useStateContext } from "../../ContextProvider/Context";
import {
	deleteContent,
	addUnit,
	deleteUnit,
	updateCardIndex,
	updateUnitIdWithCardIndex,
	updateUnitIndex,
} from "../../Store/Actions/createAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

import InviteModal from "./InviteModal";
import SponsorAddModal from "./SponsorAddModal";
import ContentEditModal from "./ContentEditModal";
import UnitEditModal from "./UnitEditModal";

import G2Button19 from "../Common/G2Button19";
import G2Button20 from "../Common/G2Button20";
import G2Button22 from "../Common/G2Button22";

import content1 from "../../assets/create/content1.png";
import content2 from "../../assets/create/content2.png";
import group1 from "../../assets/create/group1.png";
import group2 from "../../assets/create/group2.png";
import edit1 from "../../assets/create/edit1.png";
import edit2 from "../../assets/create/edit2.png";

const MainContentForm = (props) => {
	const { screenSize, boxW1, boxW2, leftBarShow, leftBarClick } = props;
	const isLargeScreen = screenSize > 1024;

	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
		useStateContext();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const toast = useToast();

	const content = useSelector((state) => state.Create.content);
	const unitList = useSelector((state) => state.Create.unitList);

	const [unitName, setUnitName] = useState("");

	const [isContentDopen, setIsContentDopen] = useState(false);
	const [isUnitDopen, setIsUnitDopen] = useState(false);

	const [isIAddopen, setIsIAddOpen] = useState(false);
	const [isSAddopen, setIsSAddOpen] = useState(false);
	const [isCEditOpen, setIsCEditOpen] = useState(false);
	const [isUEditOpen, setIsUEditOpen] = useState(false);

	const [selectedAll, setSelectedAll] = useState(false);
	const [selectedUnits, setSelectedUnits] = useState([]);

	useEffect(() => {
		if (isEmptyValue(content._id)) {
			navigate("/create");
		}
	}, []);

	useEffect(() => {
		setSelectedUnits([]);
	}, [content]);

	const handleSelectAll = () => {
		setSelectedAll(!selectedAll);

		if (selectedAll == false) {
			const temp = [];
			unitList?.map((val) => {
				temp.push(val._id);
			});
			setSelectedUnits(temp);
		} else {
			setSelectedUnits([]);
		}
	};

	const handleUnitSelect = useCallback((id) => {
		if (selectedUnits?.includes(id)) {
			setSelectedAll(false);

			setSelectedUnits([...selectedUnits?.filter((val) => val != id)]);
		} else {
			if (unitList?.length == selectedUnits?.length + 1) {
				setSelectedAll(true);
			}

			setSelectedUnits([...selectedUnits, id]);
		}
	}, [selectedUnits, setSelectedUnits]);

	const openIAddModal = () => {
		setIsIAddOpen(true);
	};

	const closeIAddModal = () => {
		setIsIAddOpen(false);
	};

	const openSAddModal = () => {
		setIsSAddOpen(true);
	};

	const closeSAddModal = () => {
		setIsSAddOpen(false);
	};

	const openCEditModal = () => {
		setIsCEditOpen(true);
	};

	const closeCEditModal = () => {
		setIsCEditOpen(false);
	};

	const openUEditModal = () => {
		setIsUEditOpen(true);
	};

	const closeUEditModal = () => {
		setIsUEditOpen(false);
	};

	const openContentDModal = () => {
		setIsContentDopen(true);
	};

	const closeContentDModal = () => {
		setIsContentDopen(false);
	};

	const handleDeleteContent = () => {
		dispatch(deleteContent(toast, navigate, content._id));

		setIsContentDopen(false);
	};

	const handleAddUnit = () => {
		if (!isEmptyValue(unitName)) {
			dispatch(addUnit(toast, content._id, unitName));
			setUnitName([]);
		} else {
			g2Toast({ toast, status: "error", title: "Input unit name!" });
		}
	};

	const openUnitDModal = () => {
		setIsUnitDopen(true);
	};

	const closeUnitDModal = () => {
		setIsUnitDopen(false);
	};

	const handleDeleteUnit = () => {
		if (!isEmptyValue(selectedUnits[0])) {
			dispatch(deleteUnit(toast, content._id, selectedUnits));

			setSelectedUnits([]);
			setIsUnitDopen(false);
		} else {
			g2Toast({ toast, status: "error", title: "Select unit!" });
		}
	};

	const onDragUnitEnd = ({ destination, source, draggableId }) => {
		if (!destination) {
			return;
		}

		if (destination.droppableId == source.droppableId && destination.index == source.index) {
			return;
		}

		dispatch(updateUnitIndex(toast, content._id, source.index, destination.index));
	}

	const onDragEnd = (result) => {
		const { destination, source, draggableId } = result;

		if (!destination) {
			return;
		}

		if (
			destination.droppableId == source.droppableId &&
			destination.index == source.index
		) {
			return;
		}

		if (
			destination.droppableId == source.droppableId &&
			destination.index != source.index
		) {
			dispatch(
				updateCardIndex(
					toast,
					draggableId,
					source.droppableId,
					source.index,
					destination.index,
					content._id
				)
			);
		}

		if (destination.droppableId != source.droppableId) {
			dispatch(
				updateUnitIdWithCardIndex(
					toast,
					draggableId,
					destination.droppableId,
					destination.index,
					content._id
				)
			);
		}
	};

	return (
		<Box w="100%" className="pb-[50px]" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
			<Box w="100%" bg={G2_color_2} className="create-main-header flex-box centering-h shadow">
				<Box w="100%" className="flex-box justify-between centering-h">
					<Box className="flex-box text-left centering-h">
						<Image h="32px" hueRotate="25px" src={G2_theme_mode == "light" ? content1 : content2} alt="icon" />
						<Text fontSize="16px" className="font-semibold mx-[10px]">
							{content.title}
						</Text>
					</Box>
					<Box className="flex-box text-right centering-h">
						<Box className="mr-5 normal-hover flex" onClick={openSAddModal}>
							<AddIcon h="15px" className="mt-[5px] mr-[5px]" />
							<Text>Add sponsor</Text>
						</Box>
						<Box className="mr-5 normal-hover flex" onClick={openCEditModal}>
							<EditIcon h="15px" className="mt-[5px] mr-[5px]" />
							<Text>Edit content</Text>
						</Box>
						<Box className="mr-5 normal-hover flex" onClick={openContentDModal}>
							<DeleteIcon h="15px" className="mt-[5px] mr-[5px]" />
							<Text>Delete content</Text>
						</Box>
						<G2ConfirmAlert isOpen={isContentDopen} onClose={closeContentDModal} handleOkClick={handleDeleteContent} />
						{screenSize > 1023 ? (
							<G2Button20 widthVal="90px" heightVal="35px" text="Invite" onClick={openIAddModal} />
						) : (
							<></>
						)}
						<InviteModal isIAddopen={isIAddopen} closeIAddModal={closeIAddModal} />
					</Box>
				</Box>
				<SponsorAddModal isSAddopen={isSAddopen} closeSAddModal={closeSAddModal} />
				<ContentEditModal isCEditOpen={isCEditOpen} closeCEditModal={closeCEditModal} />
			</Box>
			<Box w="100%" className="create-main-padding flex-box justify-between centering-h">
				<Box className="flex justify-start centering-h mt-5">
					<Input height="35px" placeholder="Input a unit name" borderRadius="2.5px" className="mr-[10px]" bg={G2_color_2} color={G2_color_3} value={unitName} onChange={(e) => setUnitName(e.target.value)} />
					<G2Button20 widthVal="140px" heightVal="35px" text="Add a unit" onClick={handleAddUnit} />
				</Box>
				<Box h="35px" className="flex justify-end centering-h mt-5">
					<Checkbox size="md" borderColor="blue.500" colorScheme="green" isChecked={selectedAll} onChange={handleSelectAll}>
						Select All
					</Checkbox>
					{!isEmptyValue(selectedUnits[0]) ? (
						<Box className="flex ml-[20px]">
							<UnitEditModal isUEditOpen={isUEditOpen} closeUEditModal={closeUEditModal} selectedUnits={selectedUnits} setSelectedUnits={setSelectedUnits} />
							{selectedUnits.length == 1 ? (
								<G2Button22 widthVal="90px" heightVal="35px" text="Edit" onClick={openUEditModal} />
							) : (
								<></>
							)}
							<G2Button19 widthVal="90px" heightVal="35px" text="Delete" onClick={openUnitDModal} />
							<G2ConfirmAlert isOpen={isUnitDopen} onClose={closeUnitDModal} handleOkClick={handleDeleteUnit} />
						</Box>
					) : (
						<></>
					)}
				</Box>
			</Box>
			<Box className="create-main-padding mt-[10px]">
				<Divider borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation="horizontal" />
			</Box>

			<DragDropContext onDragEnd={onDragUnitEnd}>
				<Box w="100%">
					<Droppable droppableId="units" className="w-100">
						{(provided) => <Box ref={provided.innerRef}  {...provided.droppableProps} w="100%" className="create-content-main-padding flex-box mt-[10px] py-[10px]">
							{unitList?.map((element, index) => <Draggable key={element._id} draggableId={element._id + ""} index={index}>
								{(provided) => <DragDropContext onDragEnd={onDragEnd}>
									<Box w={"100%"} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
										<UnitComponent key={index} boxW2={boxW2} element={element} selectedUnits={selectedUnits} setSelectedUnits={setSelectedUnits} handleUnitSelect={handleUnitSelect} />
									</Box>
								</DragDropContext>}
							</Draggable>)}
						</Box>}
					</Droppable>
				</Box>
			</DragDropContext>
		</Box >
	);
};

export default MainContentForm;
