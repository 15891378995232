import { Box, Button, HStack, Input, Stack, Text, VStack, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import UniversalLeftSideBar from "../Components/Common/UniversalLeftSideBar";
import { useStateContext } from "../ContextProvider/Context";
import { getChatChannels, getChatMessages, sendChatMessage } from "../Store/Actions/chatAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { formatDateTime } from "../helpers/formatters";

function ChatPage({ }) {
    const dispatch = useDispatch();
    const toast = useToast();
    const messageRef = useRef([]);
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const user = useSelector((state) => state.Auth.user);
    const [channel, setChannel] = useState(() => ({ name: "All", targetType: "all", targetId: null }));
    const [message, setMessage] = useState("");
    const channels = useSelector((state) => state.Chat.channels);
    const chatLogs = useSelector((state) => state.Chat.chatLogs);

    const doUpdate = useCallback(() => {
        dispatch(getChatChannels(toast));
        if (channel != null) {
            dispatch(getChatMessages(toast, channel.targetType, channel.targetId));
        }
    }, [channel]);

    useEffect(() => {
        let timer = setInterval(doUpdate, 30 * 1000);
        return () => { clearInterval(timer); };
    }, [channel]);

    useEffect(() => {
        dispatch(getChatChannels(toast));
        if (channel != null) {
            dispatch(getChatMessages(toast, channel.targetType, channel.targetId));
        }
    }, [channel]);

    useEffect(() => {
        if (messageRef.current.length == 0) {
            return;
        }

        messageRef.current[messageRef.current.length - 1]?.scrollIntoView();
    }, [messageRef, chatLogs]);

    const sendMessage = useCallback(() => {
        dispatch(sendChatMessage(toast, channel.targetType, channel.targetId, message));
        setMessage("");
    }, [message]);

    const onEnter = useCallback((e, callback) => {
        if (e.key != "Enter") {
            return;
        }

        callback();
    }, []);

    const key = useMemo(() => {
        return user.id + '|' + (channel?.targetType ?? "") + '|' + (channel?.targetId ?? "")
    }, [user, channel]);

    return (
        <Box w="100%" minH="100vh" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
            <Box w="100%">
                <Navbar title="Chat" />
            </Box>
            <Box w="100%" className="flex-box">
                <Box className="universal-leftBar-w">
                    <Box w="100%" h="100vh" className="sticky top-0">
                        <UniversalLeftSideBar />
                    </Box>
                </Box>
                <Box className="universal-body-w flex">
                    <Box className="goal-left-box-w1">
                        <Box w="100%" h="100%" style={{ background: "#49688f", color: "#ffffff" }}>
                            <Text fontSize="24px" p="15px">Channels</Text>
                            <VStack w="100%" p="15px">
                                {channels?.map(r => <Button key={r.name} w="100%" onClick={() => setChannel(r)}>{r.name}</Button>)}
                            </VStack>
                        </Box>
                    </Box>
                    <Box className="universal-body-w flex">
                        < VStack w="100%">
                            <Box w="100%" h="80px" bg={G2_color_2} className="flex-box centering-h px-[30px] shadow">
                                <Text fontSize="20px" className="font-semibold mr-[10px] text-[var(--orange)]">
                                    {channel?.name ?? "Please select Channel"}
                                </Text>
                            </Box>
                            {channel != null && <Box h="100%" w="100%">
                                <Box className="chat-messages">
                                    {(chatLogs[key] ?? []).map((r, i) => {
                                        return <Box key={i} p="15px" ref={el => messageRef.current[i] = el}>
                                            <HStack justifyContent="space-between">
                                                {!r.isSent && <Box>{r.name}</Box>}
                                                <Box marginLeft="auto">{formatDateTime(r.created_at)}</Box>
                                            </HStack>
                                            <Box key={r.id} p="15px" className={"chat-message " + (r.isSent ? 'chat-sent' : 'chat-received')} ref={el => messageRef.current[i] = el}>
                                                <Box>{r.text}</Box>
                                            </Box>
                                        </Box>
                                    })}
                                </Box>
                                <Box>
                                    <Stack spacing={4} direction='row' align='center'>
                                        <Input placeholder="Message..." value={message} onChange={e => setMessage(e.target.value)} onKeyDown={(e) => onEnter(e, sendMessage)} />
                                        <Button className="chat-button" onClick={sendMessage}>Send</Button>
                                    </Stack>
                                </Box>
                            </Box>}
                        </VStack>
                    </Box>
                </Box>
            </Box>
        </Box >);
}

export default ChatPage;