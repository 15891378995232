import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { Box, Image, Text, List, ListItem, ListIcon, Divider, IconButton, useToast, VStack, HStack, Button, } from "@chakra-ui/react";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, deleteToken, verifyToken } from "../../utils";
import g2Toast from "../../Custom/g2Toast";
import isEmptyValue from "../../Custom/isEmptyValue";
import G2Button24 from "../Common/G2Button24";
import G2Button25 from "../Common/G2Button25";

import { getFileUrl, socialMediaTypes } from "../../helpers/general";
import { followUser } from "../../Store/Actions/academyAction";
import { FaPassport, FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import Chat from "../Chat/Chat";

const LeftSideBar = ({ leftBarShow, leftBarClick, passport, setPassport, saveCallback }) => {

	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
	const user = useSelector((state) => state.Auth.user);
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const toast = useToast();

	const token = getToken();

	const isProfile = useMemo(() => {
		return (passport?.id == user?.id) ?? false;
	}, [user, passport]);

	const calcAge = () => {
		const currentDate = new Date();
		const year1 = parseInt(currentDate.getFullYear());

		const year2 = parseInt(passport?.data?.birthday?.slice(0, 4));

		return year1 - year2;
	};

	const requestText = useMemo(() => {

		if (passport?.isRejected) {
			return "Rejected";
		}

		if (passport?.isAccepted) {
			return "Accepted";
		}

		if (passport?.isRequesting) {
			return "Waiting on Request";
		}
	}, [passport]);

	const canRequestMore = useMemo(() => {

		if (!passport?.isFollowing) {
			return false;
		}

		if (passport?.isRejectedCanReapply) {
			return true;
		}

		if (passport?.isAccepted || passport?.isRejected || passport?.isRequesting) {
			return false;
		}

		return true;
	}, [passport, isProfile]);

	const showRequestState = useMemo(() => {
		if (!passport?.isFollowing || !passport?.isRequesting || passport?.isRejected) {
			return false;
		}

		if (passport?.isAccepted && passport?.isRejected) {
			return true;
		}

		return false;
	}, [passport, isProfile]);

	return (
		<Box w="100%" h="100%" style={{ background: "#49688f", color: "#ffffff" }}>
			<Box w="100%" h="100%">
				<Box w="100%" h="80px" className="centering">
					{!passport?.isMyProfile && <NavLink to="/academy/digital"><IconButton isRound={true} className="normal-hover" style={{ backgroundColor: "var(--orange)" }} color="white" aria-label="Done" fontSize="20px" icon={<ArrowBackIcon />} /></NavLink>}
				</Box>
				<Box w="100%" className="px-5 pb-5 flex-box text-white centering-w">
					<Image borderRadius="full" bg="white" boxSize="100px" src={getFileUrl(passport?.avatar)} className="mt-[20px]" alt="avatar" />
					<Text w="100%" fontSize="20px" className="mt-[5px] font-bold text-center">
						{passport?.name}
					</Text>
					<Text w="100%" fontSize="14px" className="mt-[0px] mb-[5px] font-semibold text-center">
						{passport?.followers} followers | {passport?.following} following
					</Text>
					{passport?.data?.companyName !== undefined && passport?.data?.companyName != "" && <Text w="100%" fontSize="16px" className="text-center font-semibold">
						<Text className="inline" color="var(--orange)">Works For:{" "}</Text>{passport?.data?.companyName}
					</Text>}
					{passport?.data?.industry !== undefined && passport?.data?.industry != "" && <Text w="100%" fontSize="16px" className="text-center font-semibold">
						<Text className="inline" color="var(--orange)">Industry:{" "}</Text>{passport?.data?.industry}
					</Text>}
					{passport?.data?.birthday !== undefined && <Text w="100%" fontSize="16px" className=" text-center font-semibold">
						<Text className="inline" color="var(--orange)">Age:{" "}</Text>{calcAge()}
					</Text>}
					{passport?.data?.address !== undefined && <Text w="100%" fontSize="16px" className=" text-center mb-[10px] font-semibold">
						<Text className="inline" color="var(--orange)">Location:{" "}</Text>{passport?.data?.address?.split(",").pop()}
					</Text>}
					{
						//JSON.stringify(canRequestMore)
					}
					{isProfile == true && <G2Button24 text={(passport?.profile?.isPrivate ?? true) == true ? "Private" : "Public"} onClick={() => setPassport(['profile', 'isPrivate'], !(passport?.profile?.isPrivate ?? true))} />}
					{isProfile == false && <>
						<G2Button24 text={passport?.isFollowing ? "- Follow" : "+ Follow"} onClick={() => dispatch(followUser(toast, passport.hashId, !(passport?.isFollowing ?? false), false))} />
						<Chat targetType="user" targetId={passport?.id} >
							<G2Button25 text="+ Message" />
						</Chat>
					</>}
					{canRequestMore && <G2Button24 text={"Request More"} onClick={() => dispatch(followUser(toast, passport.hashId, true, true))} />}
					{showRequestState && <G2Button24 text={requestText} />}

					{isProfile == true && <G2Button24 text="Save" onClick={saveCallback} />}

					<Box className="flex mt-[50px]">
						{Object.keys(socialMediaTypes).map((name, i) => {
							if (passport?.data?.socialMedia === undefined || passport?.data?.socialMedia[name] === undefined) {
								return ""
							}

							return <Link key={i} to={passport?.data?.socialMedia[name]}>
								<Image src={socialMediaTypes[name].img} width="30px" height="30px" className="ml-[5px] mr-[5px] normal-hover" />
							</Link>
						})}
					</Box>
				</Box>
			</Box>
		</Box >
	);
};

export default LeftSideBar;
