import React from "react";

import { useStateContext } from "../../ContextProvider/Context";

const G2Label5 = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  return (
    <label
      htmlFor="first-name"
      style={{ color: "red" }}
      className="block text-sm leading-6"
    >
      This field is required!
    </label>
  );
};

export default G2Label5;
