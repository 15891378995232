const initialState = {
  mainData: {},
  creatorData: {},
  mentorData: {},
  sponsorData: [],
  scheduleData: [],
  goalData: [],
  activityData: {},
  paymentId: "",
  paymentType: "",
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DASHBOARD_MAIN_DATA":
      return {
        ...state,
        mainData: action.payload,
      };
    case "DASHBOARD_CREATOR_DATA":
      return {
        ...state,
        creatorData: action.payload,
      };
    case "DASHBOARD_MENTOR_DATA":
      return {
        ...state,
        mentorData: action.payload,
      };
    case "DASHBOARD_SPONSOR_DATA":
      return {
        ...state,
        sponsorData: action.payload,
      };
    case "DASHBOARD_SCHEDULE_DATA":
      return {
        ...state,
        scheduleData: action.payload,
      };
    case "DASHBOARD_GOAL_DATA":
      return {
        ...state,
        goalData: action.payload,
      };
    case "DASHBOARD_ACTIVITY_DATA":
      return {
        ...state,
        activityData: action.payload,
      };
    case "DASHBOARD_MENTORID":
      return {
        ...state,
        paymentId: action.payload,
      };
    case "DASHBOARD_PAYMENTTYPE":
      return {
        ...state,
        paymentType: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
