import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Text, useToast } from "@chakra-ui/react";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";

import Navbar from "../../Components/Navbar/Navbar";
import LeftSideBar from "../../Components/Academy/LeftSideBar";
import DgitalPassportForm from "../../Components/Academy/DgitalPassportForm";

import UniversalLeftSideBar from "../../Components/Common/UniversalLeftSideBar";
import { useDispatch } from "react-redux";
import { getPassport, savePassport } from "../../Store/Actions/academyAction";
import _ from "lodash";
import { isFile, profileUploadTypes } from "../../helpers/general";

const DigitalPassportPage = ({ }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();

  const user = useSelector((state) => state.Auth.user);
  const passport = useSelector((state => state.Academy.passport));
  const userData = useSelector((state) => state.Academy.userData);
  const today = useSelector((state) => state.Auth.today);

  const [screenSize, setScreenSize] = useState(1366);
  const [leftBarShow, setLeftBarShow] = useState(true);
  const [boxW1, setBoxW1] = useState("50%");
  const [boxW2, setBoxW2] = useState("33.3%");

  const [data, setData] = useState(passport);

  useEffect(() => {
    setData(passport);
  }, [passport]);


  const handleResize = () => {
    setScreenSize(window.innerWidth);

    if (window.innerWidth >= 1366) {
      setLeftBarShow(true);
      setBoxW1("50%");
      setBoxW2("33.3%");
    }
    if (window.innerWidth < 1366 && window.innerWidth >= 1024) {
      setLeftBarShow(true);
      setBoxW1("50%");
      setBoxW2("50%");
    }
    if (window.innerWidth < 1024 && window.innerWidth >= 768) {
      setLeftBarShow(true);
      setBoxW1("100%");
      setBoxW2("100%");
    }
    if (window.innerWidth < 768) {
      setLeftBarShow(false);
      setBoxW1("100%");
      setBoxW2("100%");
    }
  };

  useEffect(() => {

    dispatch(getPassport(toast, params.userHash ?? user.hashId))

    const startDate = new Date(today);
    const endDate = new Date(user.expire);

    if (startDate > endDate) {
      navigate("/billing");
    }

    handleResize();
    window.addEventListener("resize", handleResize);
  }, [user]);

  useEffect(() => {

    dispatch(getPassport(toast, params.userHash ?? user.hashId))

    const startDate = new Date(today);
    const endDate = new Date(user.expire);

    if (startDate > endDate) {
      navigate("/billing");
    }

    handleResize();
    window.addEventListener("resize", handleResize);
  }, [user, params?.userHash]);

  const leftBarClick = () => {
    setLeftBarShow(!leftBarShow);

    if (screenSize >= 1366) {
      setBoxW1("50%");
      setBoxW2("33.3%");
    }

    if (screenSize >= 1024 && screenSize < 1366 && leftBarShow == true) {
      setBoxW1("50%");
      setBoxW2("33.3%");
    }
    if (screenSize >= 1024 && screenSize < 1366 && leftBarShow == false) {
      setBoxW1("50%");
      setBoxW2("50%");
    }

    if (screenSize >= 768 && screenSize < 1024 && leftBarShow == true) {
      setBoxW1("100%");
      setBoxW2("50%");
    }
    if (screenSize >= 768 && screenSize < 1024 && leftBarShow == false) {
      setBoxW1("100%");
      setBoxW2("100%");
    }

    if (screenSize < 768) {
      setBoxW1("100%");
      setBoxW2("100%");
    }
  };

  const updatePassport = useCallback((path, value) => {
    let temp = _.cloneDeep(data);
    temp.isDirty = true;
    temp = _.set(temp, path, value);
    setData(temp);
  }, [data, setData]);

  const handleSavePassportClick = useCallback(() => {
    const formData = new FormData();
    let temp = _.cloneDeep(data);


    profileUploadTypes.map(type => {
      let rows = _.get(temp, type.path) ?? [];
      let cleanFiles = [];
      rows.map(r => {
        if (!isFile(r)) {
          cleanFiles.push(r);
          return;
        }
        formData.append(type.type, r);
      })

      temp = _.set(temp, type.path, cleanFiles);
    });

    formData.append("profile", JSON.stringify(temp.profile));

    dispatch(savePassport(toast, formData));
  }, [data]);

  if (passport?.profile?.isPrivate && !passport?.isMyProfile) {
    return <Box w="100%" minH="100vh" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box w="100%">
        <Navbar title="Academy" />
      </Box>
      <Box w="100%" className="flex-box" justifyContent="center">
        <Text fontSize="20px" color="var(--red)" pt="100px">Profile is not found or Private</Text>
      </Box>
    </Box>
  }

  return <Box w="100%" minH="100vh" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
    <Box w="100%">
      <Navbar title="Academy" />
    </Box>
    <Box w="100%" className="flex-box">
      <Box className="universal-leftBar-w">
        <Box w="100%" h="100vh" className="sticky top-0">
          <UniversalLeftSideBar />
        </Box>
      </Box>
      <Box className="universal-body-w flex">
        <Box className={leftBarShow ? screenSize < 768 ? "goal-left-box-w0" : "goal-left-box-w1" : "goal-left-box-w2"}>
          <LeftSideBar leftBarShow={leftBarShow} leftBarClick={leftBarClick} passport={data} setPassport={updatePassport} saveCallback={handleSavePassportClick} />
        </Box>
        <Box h="100%" className={leftBarShow ? screenSize < 768 ? "goal-main-box-w0" : "goal-main-box-w1" : "goal-main-box-w2"}>
          <DgitalPassportForm screenSize={screenSize} boxW1={boxW1} boxW2={boxW2} passport={data} setPassport={updatePassport} saveCallback={handleSavePassportClick} />
        </Box>
      </Box>
    </Box>
  </Box>

};

export default DigitalPassportPage;
