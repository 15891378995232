const initialState = {
  theme: {},
  content: {},
  mentorList: [],
  affiliateCodes: [],
  affiliateCodeUsers: [],
  userCredits: { totalcredits: 0 }
};

const otherReducer = (state = initialState, action) => {
  switch (action.type) {
    case "THEME_SET":
      return {
        ...state,
        theme: action.payload,
      };
    case "LOAD_CONTENT_DATA":
      return {
        ...state,
        content: {
          contentName: action.payload.contentName,
          unitList: action.payload.unitList,
        },
      };
    case "SAVE_UNIT_DATA":
      return {
        ...state,
        content: action.payload,
      };

    case "GET_MENTOR_LIST":
      return {
        ...state,
        mentorList: action.payload,
      };
    case "GET_AFFILIATE_CODES":
      return {
        ...state,
        affiliateCodes: action.payload,
      };
    case "GET_AFFILIATE_USERS":
      return {
        ...state,
        affiliateCodeUsers: action.payload,
      };
    case "GET_USER_CREDITS":
      return {
        ...state,
        userCredits: action.payload,
      };
    default:
      return state;
  }
};

export default otherReducer;
