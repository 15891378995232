import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  Card,
  CardBody,
  Select,
  Progress,
  Circle,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";

import { ArrowLeftIcon, ArrowRightIcon, LockIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import {
  getGoalList,
  addGoal,
  updateGoal,
  deleteGoal,
} from "../../Store/Actions/goalAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

// import AddModal from "./AddModal";

import G2Button10 from "../Common/G2Button10";
import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";
import G2Button23 from "../Common/G2Button23";

import icon from "../../assets/academy/icon.png";
import { getServerUrl } from "../../helpers/location";
import { getFileUrl } from "../../helpers/general";

const letters = [
  "Degree in Turf Management or horticulture preferred",
  "Pesticide Application License",
  "Valid driver's license",
];

const JobViewForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const job = useSelector((state) => state.Academy.job);
  const backPage = useSelector((state) => state.Academy.backPage);

  const token = getToken();

  useEffect(() => {
    if (isEmptyValue(job?._id)) {
      navigate("/academy/job");
    }
  }, [job]);

  const handleBackClick = () => {
    if (backPage == 1) {
      navigate("/academy/job");
    } else {
      navigate("/academy/job/edit");
    }
  };

  return (
    <Box
      w="100%"
      h="100%"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box
        w="100%"
        h="80px"
        bg={G2_color_2}
        className="flex centering-h px-[30px] shadow relative"
      >
        <Text
          fontSize="20px"
          className="font-semibold mr-[10px] text-[var(--orange)]"
        >
          Jobs Boards
        </Text>
        <Box className="absolute right-[30px]">
          <G2Button20
            widthVal="80px"
            heightVal="35px"
            text="Back"
            onClick={handleBackClick}
          />
        </Box>
      </Box>
      <Box w="100%" h="150px" className="flex-box">
        <Box w="150px" h="100%" className="centering-h pl-[30px] pt-[30px]">
          <Image
            w="120px"
            src={getFileUrl(job?.logo)}
          />
        </Box>
        <Box
          h="100%"
          className="p-[30px]"
          style={{ width: "calc(100% - 150px)" }}
        >
          <Box w="100%" className="pt-[15px]">
            <G2Button20 widthVal="150px" heightVal="35px" text="View Company" />
          </Box>
          <Box w="100%" className="pt-[10px] pb-[15px]">
            <G2Button21
              widthVal="150px"
              heightVal="35px"
              text="Send My Passport"
            />
          </Box>
        </Box>
      </Box>
      <Box w="100%" className="p-[30px]">
        <Text w="100%" fontSize="16px" className="font-semibold">
          Job Tilte:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.title}
        </Text>
        <Text w="100%" fontSize="16px" className="font-semibold mt-[10px]">
          Job Location:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.location}
        </Text>
        <Text w="100%" fontSize="16px" className="font-semibold mt-[10px]">
          Job Description:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.description}
        </Text>
        <Text w="100%" fontSize="16px" className="font-semibold mt-[10px]">
          Company Name:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.companyName}
        </Text>
        <Text w="100%" fontSize="16px" className="font-semibold mt-[10px]">
          Company Website:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.companyWebsite}
        </Text>
        <Text w="100%" fontSize="16px" className="font-semibold mt-[10px]">
          Job category:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.category}
        </Text>
        <Text w="100%" fontSize="16px" className="font-semibold mt-[10px]">
          Job Type:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.type}
        </Text>
        <Text w="100%" fontSize="16px" className="font-semibold mt-[10px]">
          Salary Range:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.salary}
        </Text>
        <Text w="100%" fontSize="16px" className="font-semibold mt-[10px]">
          Experience:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.experience}
        </Text>
        <Text w="100%" fontSize="16px" className="font-semibold mt-[10px]">
          Qualification:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.qualification}
        </Text>
        {/* {letters.map((val, i) => (
          <Text key={i} w="100%" fontSize="16px" className="flex centering-h">
            <Circle
              boxSize={2}
              bg={G2_color_3}
              className="mx-[10px] mt-[2px]"
            />
            {val}
          </Text>
        ))} */}
        <Text w="100%" fontSize="16px" className="font-semibold mt-[10px]">
          Application Close:
        </Text>
        <Text w="100%" fontSize="16px">
          {job?.expire}
        </Text>
      </Box>
    </Box>
  );
};

export default JobViewForm;
