import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  Card,
  CardBody,
  Select,
  Progress,
  Circle,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
} from "@chakra-ui/react";

import { CalendarIcon, SearchIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import {
  getGoalList,
  addGoal,
  updateGoal,
  deleteGoal,
} from "../../Store/Actions/goalAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

import AddModal from "./AddModal";
import UpdateModal from "./UpdateModal";
import HistoryModal from "./HistoryModal";

import G2Button2 from "../Common/G2Button2";
import G2Button12 from "../Common/G2Button12";
import G2Button17 from "../Common/G2Button17";
import G2Button18 from "../Common/G2Button18";
import G2Button19 from "../Common/G2Button19";

import completeImg from "../../assets/goal/complete.png";
import newImg from "../../assets/goal/new.png";

const sorts = ["All", "New", "Ongoing", "Completed"];

const MainGoalForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const schedules = useSelector((state) => state.Goal.goalList);

  const [isGoalDopen, setIsGoalDopen] = useState(false);

  const [isAOpen, setIsAOpen] = useState(false);
  const [isUOpen, setIsUOpen] = useState(false);
  const [isHOpen, setIsHOpen] = useState(false);

  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [selectedGoalId, setSelectedGoalId] = useState("");

  const [searchText, setSearchText] = useState("");
  const [sortType, setSortType] = useState("All");
  const [sortedGoal, setSortedGoal] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [title, setTitle] = useState("");
  const [percent, setPercent] = useState(0);
  const [comment, setComment] = useState("");

  const [upgrade, setUpgrade] = useState([]);

  const token = getToken();

  useEffect(() => {
    if (verifyToken()) {
      dispatch(getGoalList(toast));
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setStartDate(formattedDate);
    setEndDate(formattedDate);
  }, []);

  useEffect(() => {
    if (sortType == "New") {
      function checkPercent(element) {
        return element.percent == 0;
      }
      setSortedGoal(schedules.filter(checkPercent));
    } else if (sortType == "Ongoing") {
      function checkPercent(element) {
        return element.percent != 0 && element.percent != 100;
      }
      setSortedGoal(schedules.filter(checkPercent));
    } else if (sortType == "Completed") {
      function checkPercent(element) {
        return element.percent == 100;
      }
      setSortedGoal(schedules.filter(checkPercent));
    } else {
      setSortedGoal(schedules);
    }
  }, [schedules, sortType, searchText]);

  const handleSelectAll = () => {
    setSelectedAll(!selectedAll);

    if (selectedAll == false) {
      const temp = [];
      (isEmptyObject(sortedGoal[0]) ? schedules : sortedGoal).map((val) => {
        temp.push(val._id);
      });
      setSelectedGoals(temp);
    } else {
      setSelectedGoals([]);
    }
  };

  const handleGoalSelect = (id) => {
    if (selectedGoals.includes(id)) {
      setSelectedAll(false);

      setSelectedGoals(selectedGoals.filter((val) => val != id));
    } else {
      if (schedules.length == selectedGoals.length + 1) {
        setSelectedAll(true);
      }

      setSelectedGoals([...selectedGoals, id]);
    }
  };

  // Helper function to limit object keys
  const limitObjectKeys = (originalObject, allowedKeys) => {
    return allowedKeys.reduce((acc, key) => {
      if (originalObject.hasOwnProperty(key)) {
        acc[key] = originalObject[key];
      }
      return acc;
    }, {});
  };

  const handleSearchClick = () => {
    const keysToSearch = ["startDate", "endDate", "title", "percent"]; // Specify the keys you want to search in

    const result = (isEmptyObject(sortedGoal[0]) ? schedules : sortedGoal)
      .map((obj) => limitObjectKeys(obj, keysToSearch))
      .filter((obj) =>
        Object.values(obj).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase())
        )
      );

    setSortedGoal(result);
  };

  const handleChangeSort = (e) => {
    const val = e.target.options[e.target.selectedIndex].text;
    setSortType(val);
  };

  const openAddModal = () => {
    setTitle("");
    setPercent(0);

    setIsAOpen(true);
  };

  const closeAddModal = () => {
    setIsAOpen(false);
  };

  const openUpdateModal = (e, id, percent) => {
    setSelectedGoalId(id);
    setPercent(percent);
    setComment("");

    setIsUOpen(true);

    e.stopPropagation();
  };

  const closeUpdateModal = () => {
    setIsUOpen(false);
  };

  const openHistoryModal = (e, history) => {
    setUpgrade(history);

    setIsHOpen(true);

    e.stopPropagation();
  };

  const closeHistoryModal = () => {
    setIsHOpen(false);
  };

  const openGoalDModal = () => {
    setIsGoalDopen(true);
  };

  const closeGoalDModal = () => {
    setIsGoalDopen(false);
  };

  const handleAddGoal = () => {
    if (!isEmptyValue(title)) {
      dispatch(addGoal(toast, startDate, endDate, title));
      closeAddModal();
    } else {
      g2Toast({ toast, status: "error", title: "Input goal name!" });
    }
  };

  const handleUpdateGoal = () => {
    if (isEmptyValue(comment)) {
      g2Toast({ toast, status: "error", title: "Input comment name!" });
    } else if (percent > 100) {
      g2Toast({
        toast,
        status: "error",
        title: "Percentage must be less than 100!",
      });
    } else {
      dispatch(updateGoal(toast, selectedGoalId, percent, comment));
      closeUpdateModal();
    }
  };

  const handleDeleteGoal = () => {
    if (!isEmptyValue(selectedGoals[0])) {
      dispatch(deleteGoal(toast, selectedGoals));
      setSelectedGoals([]);
      setIsGoalDopen(false);
    } else {
      g2Toast({ toast, status: "error", title: "Select goal!" });
    }
  };

  return (
    <Box
      w="100%"
      h="100%"
      className="goal-main"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box w="100%" className="flex-box centering-h justify-between px-[10px]">
        <Box className="text-left">
          <Text fontSize="20px" className="font-semibold">
            Set Your Goals
          </Text>
        </Box>
        <Box className="text-right">
          <G2Button12
            widthVal="100px"
            heightVal="35px"
            text="Add Goal"
            onClick={openAddModal}
          />
        </Box>
      </Box>
      <Box className="px-[10px] mt-2">
        <Divider
          borderColor={G2_theme_mode == "light" ? "black" : "white"}
          orientation="horizontal"
        />
      </Box>
      <AddModal
        isAOpen={isAOpen}
        closeAddModal={closeAddModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        title={title}
        setTitle={setTitle}
        handleAddGoal={handleAddGoal}
      />
      <Box w="100%" className="flex-box justify-between px-[10px]">
        <Box className="flex justify-start centering-h mr-10 mt-2">
          <InputGroup width="300px" borderRadius="2.5px" bg={G2_color_2}>
            <Input
              height="40px"
              borderRadius="2.5px"
              placeholder="Search here..."
              color={G2_color_3}
              onBlur={(e) => setSearchText(e.target.value)}
            />
            <InputRightElement width="40px" height="40px" className="centering">
              <SearchIcon
                boxSize="15px"
                color={G2_color_3}
                className="goal-serch-icon"
                onClick={handleSearchClick}
              />
            </InputRightElement>
          </InputGroup>
        </Box>
        <Box className="flex justify-end centering-h mt-2">
          <Text fontSize="16px" className="font-semibold mr-2.5">
            Sort
          </Text>
          <Select
            width="130px"
            style={{
              background: `${G2_color_2}`,
              color: `${G2_color_3}`,
              borderRadius: "2.5px",
            }}
            onChange={handleChangeSort}
          >
            {sorts.map((val, i) => (
              <option
                key={i}
                value={i}
                style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
              >
                {val}
              </option>
            ))}
          </Select>
        </Box>
      </Box>
      <Box className="px-[10px] mt-2">
        <Divider
          borderColor={G2_theme_mode == "light" ? "black" : "white"}
          orientation="horizontal"
        />
      </Box>
      <Box
        height="35px"
        className="px-[10px] mt-2 flex-box justify-between centering-h"
      >
        <Box className="flex-box text-left">
          <Checkbox
            w="100%"
            size="lg"
            borderColor="blue.500"
            colorScheme="green"
            isChecked={selectedAll}
            onChange={handleSelectAll}
          >
            Select All
          </Checkbox>
        </Box>
        <Box className="flex-box text-right">
          {!isEmptyValue(selectedGoals[0]) ? (
            <G2Button19
              widthVal="100px"
              heightVal="35px"
              text="Delete"
              onClick={openGoalDModal}
            />
          ) : (
            <></>
          )}
          <G2ConfirmAlert
            isOpen={isGoalDopen}
            onClose={closeGoalDModal}
            handleOkClick={handleDeleteGoal}
          />
        </Box>
      </Box>
      <Box className="px-[10px] mt-2">
        <Divider
          borderColor={G2_theme_mode == "light" ? "black" : "white"}
          orientation="horizontal"
        />
      </Box>
      <Box w="100%" className="flex-box pt-2.5">
        {(isEmptyObject(sortedGoal[0]) ? schedules : sortedGoal).map(
          (val, i) => (
            <Box
              key={i}
              w={boxW2}
              p="10px"
              className="relative"
              onClick={() => handleGoalSelect(val._id)}
            >
              <Card
                w="100%"
                style={{
                  background: `${
                    selectedGoals.includes(val._id)
                      ? G2_theme_mode == "light"
                        ? "#f99d8f"
                        : "#a15245"
                      : G2_color_2
                  }`,
                  color: `${G2_color_3}`,
                }}
              >
                <CardBody w="100%" className="p-2.5">
                  <Box w="200px" className="flex-box justify-between">
                    <Box className="flex-box text-left">
                      <CalendarIcon className="mt-1 mr-2" />
                      <Text fontSize="16px" className="font-semibold">
                        Start Date
                      </Text>
                    </Box>
                    <Box className="centering-h text-right">
                      <Text fontSize="16px" className="">
                        {val.startDate}
                      </Text>
                    </Box>
                  </Box>
                  <Box w="200px" className="flex-box justify-between">
                    <Box className="flex-box text-left">
                      <CalendarIcon className="mt-1 mr-2" />
                      <Text fontSize="16px" className="font-semibold">
                        End Date
                      </Text>
                    </Box>
                    <Box className="centering-h text-right">
                      <Text fontSize="16px" className="">
                        {val.endDate}
                      </Text>
                    </Box>
                  </Box>
                  <Box w="100%" className="mt-2.5">
                    <Divider
                      borderColor={G2_theme_mode == "light" ? "black" : "white"}
                      orientation="horizontal"
                    />
                  </Box>
                  <Box
                    w="100%"
                    className="goal-sub-title-box mt-5"
                    bg={
                      val.percent == 0
                        ? "red.500"
                        : val.percent > 0 && val.percent < 25
                        ? "yellow.500"
                        : val.percent > 24 && val.percent < 50
                        ? "pink.500"
                        : val.percent > 49 && val.percent < 75
                        ? "teal.500"
                        : val.percent > 74 && val.percent < 100
                        ? "blue.500"
                        : "green.500"
                    }
                  >
                    <Text fontSize="16px" className="font-semibold text-white">
                      {val.title}
                    </Text>
                  </Box>
                  <Box w="100%" className="flex-box centering-h mt-5">
                    <Box className="goal-progress-bar">
                      <Progress
                        rounded="full"
                        hasStripe
                        colorScheme={
                          val.percent == 0
                            ? "red.500"
                            : val.percent > 0 && val.percent < 25
                            ? "yellow"
                            : val.percent > 24 && val.percent < 50
                            ? "pink"
                            : val.percent > 49 && val.percent < 75
                            ? "teal"
                            : val.percent > 74 && val.percent < 100
                            ? "blue"
                            : "green"
                        }
                        value={val.percent}
                      />
                    </Box>
                    <Box className="goal-progress-text text-end">
                      <Text fontSize="16px">{val.percent}%</Text>
                    </Box>
                  </Box>
                  <Box w="100%" className="mt-5 flex-box justify-between">
                    <Box className="flex-box text-left">
                      {val.percent != 0 ? (
                        <G2Button18
                          widthVal="80px"
                          heightVal="30px"
                          text="History"
                          onClick={(e) => openHistoryModal(e, val.upgrade)}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Box className="flex-box text-right">
                      {val.percent != 100 ? (
                        <G2Button17
                          widthVal="140px"
                          heightVal="30px"
                          text="Update Progress"
                          onClick={(e) =>
                            openUpdateModal(e, val._id, val.percent)
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                </CardBody>
              </Card>
              {val.percent == 100 ? (
                <Image
                  className="goal-complete-img"
                  width="40px"
                  height="40px"
                  src={completeImg}
                  alt="complete"
                />
              ) : val.percent == 0 ? (
                <Image
                  className="goal-complete-img"
                  width="40px"
                  height="40px"
                  src={newImg}
                  alt="new"
                />
              ) : (
                <></>
              )}
            </Box>
          )
        )}
        <UpdateModal
          isUOpen={isUOpen}
          closeUpdateModal={closeUpdateModal}
          percent={percent}
          setPercent={setPercent}
          comment={comment}
          setComment={setComment}
          handleUpdateGoal={handleUpdateGoal}
        />
        <HistoryModal
          isHOpen={isHOpen}
          upgrade={upgrade}
          closeHistoryModal={closeHistoryModal}
        />
      </Box>
      {/* <Box w="100%" className="flex justify-center flex-wrap mt-5">
                <G2Button2 widthVal="200px" text="Go to Dashboard" onClick={() => navigate("/dashboard")} />
            </Box> */}
    </Box>
  );
};

export default MainGoalForm;
