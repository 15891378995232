import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import {
  Box,
  Text,
  Image,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Card,
  CardBody,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Divider,
} from "@chakra-ui/react";

import {
  SearchIcon,
  ChevronDownIcon,
  EditIcon,
  SmallAddIcon,
  SmallCloseIcon,
} from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import {
  getContentList,
  searchContentList,
  getMyContentInfo,
  getMyContent,
  getSomeContent,
  makeContentList,
  makeContent,
  addToBusket,
  removeToBusket,
  getBusket,
  getCategory,
  addTreeItem,
  removeTreeItem,
  removeTreeNode,
} from "../../Store/Actions/lifeAction";

import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

import NodeAddModal from "./NodeAddModal";

import G2Button2 from "../Common/G2Button2";
import G2Button3 from "../Common/G2Button3";
import G2Button14 from "../Common/G2Button14";
import G2Button20 from "../Common/G2Button20";

import main1 from "../../assets/marketplace/main1.png";
import main2 from "../../assets/marketplace/main2.png";
import main3 from "../../assets/life/main3.png";

import content1 from "../../assets/life/content1.png";
import content2 from "../../assets/life/content2.png";
import content3 from "../../assets/life/content3.png";
import content4 from "../../assets/life/content4.png";
import content5 from "../../assets/life/content5.png";
import content6 from "../../assets/life/content6.png";

import newer from "../../assets/marketplace/new.png";
import enrolled from "../../assets/marketplace/enrolled.png";
import seen from "../../assets/marketplace/seen.png";
import buy1 from "../../assets/marketplace/buy1.png";
import buy2 from "../../assets/marketplace/buy2.png";

import edit from "../../assets/marketplace/edit.png";
import UTradieText from "../Common/UTradieText";
import { getServerUrl } from "../../helpers/location";
import { getFileUrl } from "../../helpers/general";

const btnGroup = [
  "Featured",
  "Price: Low to High",
  "Price: High to Low",
  "Newly Added",
  "Most Enrolled",
];

const MainLifeForm = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const user = useSelector((state) => state.Auth.user);
  const contentList = useSelector((state) => state.Life.contentList);
  const busket = useSelector((state) => state.Life.busket);
  const category = useSelector((state) => state.Life.category);
  const posted = useSelector((state) => state.Life.posted);
  const draft = useSelector((state) => state.Life.draft);

  const [screenSize, setScreenSize] = useState(1366);

  const [isNAddOpen, setIsNAddOpen] = useState(false);

  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const [sort, setSort] = useState("Featured");
  const [currentPage, setCurrentPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [treeItem, setTreeItem] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [isDopen, setIsDopen] = useState(null);

  useEffect(() => {
    setScreenSize(window.innerWidth);
    window.addEventListener("resize", setScreenSize);

    dispatch(getCategory(toast));
    dispatch(getContentList(toast, sort, currentPage));
    dispatch(getBusket(toast));
  }, [window.innerWidth]);

  useEffect(() => {
    setCategoryTitle(category[0]?.item);
    setCategoryId(category[0]?._id);
  }, [category]);

  const openNAddModal = () => {
    setIsNAddOpen(true);
  };

  const closeNAddModal = () => {
    setIsNAddOpen(false);
  };

  const openDeleteModal = (i) => {
    setIsDopen(i);
  };

  const closeDeleteModal = () => {
    setIsDopen(null);
  };

  const categoryChange = (e) => {
    const value = e.target.options[e.target.selectedIndex].text;
    setCategoryTitle(value);
    setCategoryId(e.target.value);
  };

  const handleNodeChange = (e) => {
    const nodeId = e.target.value;

    dispatch(getSomeContent(toast, sort, categoryId, nodeId));
  };

  const goToView = (val) => {
    dispatch(makeContent(navigate, 1, val));
  };

  const goToEdit = (val) => {
    dispatch(makeContent(navigate, 2, val));
  };

  const onClickNew = () => {
    navigate("/life/register");
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSortChange = (val) => {
    setSort(val);

    dispatch(getContentList(toast, val, currentPage));
  };

  const limitObjectKeys = (originalObject, allowedKeys) => {
    return allowedKeys.reduce((acc, key) => {
      if (originalObject.hasOwnProperty(key)) {
        acc[key] = originalObject[key];
      }
      return acc;
    }, {});
  };

  const handleSearchClick = () => {
    dispatch(searchContentList(toast, searchText));
  };

  const handleClickBusket = (id) => {
    if (busket?.includes(id)) {
      dispatch(removeToBusket(toast, id));
    } else {
      dispatch(addToBusket(toast, id));
    }
  };

  const handleItemAdd = () => {
    if (!isEmptyValue(treeItem)) {
      dispatch(addTreeItem(toast, treeItem));
      setTreeItem("");
    } else {
      g2Toast({ toast, status: "error", title: "Input item name!" });
    }
  };

  const handleNodeAdd = (id) => {
    openNAddModal();
    setSelectedItemId(id);
  };

  const handleItemRemove = (itemId) => {
    setIsDopen(null);

    dispatch(removeTreeItem(toast, itemId));
  };

  const handleNodeRemove = (itemId, nodeId) => {
    dispatch(removeTreeNode(toast, itemId, nodeId));
  };

  const handleAllClick = () => {
    dispatch(getContentList(toast, sort, currentPage));
  };

  const handleGetMyContentInfo = () => {
    dispatch(getMyContentInfo(toast));
  };

  const handleMyNodeClick = (cond) => {
    if (cond == 1) {
      dispatch(getMyContent(toast, "Posted"));
    }
    if (cond == 2) {
      dispatch(getMyContent(toast, "Draft"));
    }
  };

  const handleNodeClick = (itemId, nodeId) => {
    dispatch(getSomeContent(toast, sort, itemId, nodeId));
  };

  return (
    <Box
      minHeight="100vh"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box className="life-main-header flex-box centering-h relative">
        <Box className="market-main-header-box1 flex-box centering-h">
          <Box>
            <Text className="font-semibold text-white text-[30px]">
              Connect to an Expert
            </Text>
            <Box className="flex-box">
              <Text className="font-semibold text-white text-[30px]">
                in the Community
              </Text>
              <Image className="ml-2.5" boxSize="50px" src={main1} />
            </Box>
          </Box>
          <Box w="100%" className="mt-5 relative">
            {screenSize >= 768 ? (
              <Image className="market-main-pos2" boxSize="150px" src={main2} />
            ) : (
              <></>
            )}
            <Text className="text-white" fontSize="20px">
              Discover the power of the <UTradieText /> Connections community, connecting you to
              experts in your trade. Tap into their knowledge, experience and
              guidance to elevate your skills and achieve your goals.
            </Text>
          </Box>
        </Box>
        <Box className="market-main-header-box2 centering-h">
          <Image width="100%" height="auto" src={main3} />
        </Box>
        <InputGroup
          bg={G2_color_2}
          className={`market-main-search border ${G2_theme_mode == "light" ? "border-gray-600" : "border-gray-300"
            } border-1`}
        >
          <InputLeftElement h="50px" className="centering">
            <SearchIcon boxSize="20px" color={G2_color_3} />
          </InputLeftElement>
          <Input
            h="50px"
            placeholder="Search here..."
            color={G2_color_3}
            className="pl-[50px] pr-[100px]"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <InputRightElement w="100px" h="50px" className="centering">
            <G2Button14
              widthVal="80px"
              heightVal="30px"
              text="Search"
              onClick={handleSearchClick}
            />
          </InputRightElement>
        </InputGroup>
      </Box>
      <Box className="mt-5 market-main-body flex-box">
        {!isEditable ? (
          <Box className="market-main-category p-[10px]">
            <Box
              w="100%"
              h="30px"
              fontSize="20px"
              className="pl-[15px] flex-box"
            >
              Popular categories
              {user.isAdmin ? (
                <EditIcon
                  boxSize="20px"
                  className="mt-[5px] ml-[10px] normal-hover"
                  onClick={() => setIsEditable(true)}
                />
              ) : (
                <></>
              )}
            </Box>
            <Box className="px-[10px] mt-[20px]" allowMultiple>
              <G2Button14
                widthVal="100%"
                heightVal="35px"
                text="View All"
                onClick={handleAllClick}
              />
            </Box>
            <Accordion className="p-[10px] mt-2.5" allowMultiple>
              {verifyToken() ? (
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "#E0F1FF", color: "black" }}
                      onClick={handleGetMyContentInfo}
                    >
                      <Box
                        flex="1"
                        textAlign="left"
                        className="text-[var(--orange)] font-semibold"
                      >
                        My content
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg={G2_color_2} color={G2_color_3}>
                    <Box
                      className="flex-box cursor-pointer py-[5px] normal-hover"
                      onClick={() => handleMyNodeClick(1)}
                    >
                      <Box className="market-accordin-box1">
                        <Text fontSize="16px">Posted</Text>
                      </Box>
                      <Box className="market-accordin-box2 centering-w">
                        <Text fontSize="16px">{posted}</Text>
                      </Box>
                    </Box>
                    <Box
                      className="flex-box cursor-pointer py-[5px] normal-hover"
                      onClick={() => handleMyNodeClick(2)}
                    >
                      <Box className="market-accordin-box1">
                        <Text fontSize="16px">Draft</Text>
                      </Box>
                      <Box className="market-accordin-box2 centering-w">
                        <Text fontSize="16px">{draft}</Text>
                      </Box>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              ) : (
                <></>
              )}
              {category?.map((element, i) => (
                <AccordionItem key={i}>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "#E0F1FF", color: "black" }}
                    >
                      <Box flex="1" textAlign="left">
                        {element.item}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg={G2_color_2} color={G2_color_3}>
                    {element.nodes?.map((val, i) => (
                      <Box
                        key={i}
                        className="flex-box cursor-pointer py-[5px] normal-hover"
                        onClick={() => handleNodeClick(element._id, val.id)}
                      >
                        <Box className="market-accordin-box1">
                          <Text fontSize="16px">{val.text}</Text>
                        </Box>
                        <Box className="market-accordin-box2 centering-w">
                          <Text fontSize="16px">{val.size}</Text>
                        </Box>
                      </Box>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        ) : (
          <Box className="market-main-category p-[10px]">
            <Box
              w="100%"
              h="30px"
              fontSize="20px"
              className="flex-box px-[10px]"
            >
              <G2Button20
                widthVal="100%"
                heightVal="35px"
                text="Back"
                onClick={() => setIsEditable(false)}
              />
            </Box>
            <Box w="100%" className="px-[10px]">
              <Divider
                borderColor={G2_theme_mode == "light" ? "black" : "white"}
                orientation="horizontal"
                className="mt-[20px]"
              />
            </Box>
            <Box w="100%" className="flex-box px-[10px] mt-[20px]">
              <Box style={{ width: "calc(100% - 80px)" }}>
                <Input
                  w="100%"
                  h="35px"
                  bg={G2_color_2}
                  color={G2_color_3}
                  borderRadius="2.5px"
                  value={treeItem}
                  onChange={(e) => setTreeItem(e.target.value)}
                />
              </Box>
              <Box w="80px" className="pl-[10px]">
                <G2Button14
                  widthVal="100%"
                  heightVal="35px"
                  text="Add"
                  onClick={handleItemAdd}
                />
              </Box>
            </Box>
            <Accordion
              defaultIndex={[0]}
              allowMultiple
              className="p-[10px] mt-2.5"
            >
              {category?.map((element, i) => (
                <AccordionItem key={i}>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "#E0F1FF", color: "black" }}
                    >
                      <Box flex="1" textAlign="left">
                        {element.item}
                      </Box>
                      <SmallAddIcon
                        className="mr-[10px] normal-hover"
                        onClick={() => handleNodeAdd(element._id)}
                      />
                      <SmallCloseIcon
                        className="mr-[10px] normal-hover"
                        onClick={() => openDeleteModal(i)}
                      />
                      <G2ConfirmAlert
                        isOpen={i == isDopen}
                        onClose={closeDeleteModal}
                        handleOkClick={() => handleItemRemove(element._id)}
                      />
                      <AccordionIcon />
                      <NodeAddModal
                        isNAddOpen={isNAddOpen}
                        closeNAddModal={closeNAddModal}
                        selectedItemId={selectedItemId}
                      />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg={G2_color_2} color={G2_color_3}>
                    {element.nodes?.map((val, i) => (
                      <Box key={i} className="flex-box cursor-pointer py-[5px]">
                        <Box className="market-accordin-boxA">
                          <Text fontSize="16px">{val.text}</Text>
                        </Box>
                        <Box className="market-accordin-boxB centering-w">
                          <SmallCloseIcon
                            className="normal-hover"
                            onClick={() =>
                              handleNodeRemove(element._id, val.id)
                            }
                          />
                        </Box>
                      </Box>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        )}
        {screenSize < 1024 ? (
          <Box w="100%" className="px-[20px] py-[10px] flex-box">
            <Select
              width="100%"
              className="my-[10px] centering"
              onChange={categoryChange}
              style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
            >
              {category?.map((val, i) => (
                <option
                  key={i}
                  value={val._id}
                  style={{
                    background: `${G2_color_2}`,
                    color: `${G2_color_3}`,
                  }}
                >
                  {val.item}
                </option>
              ))}
            </Select>
            <Select
              width="100%"
              className="my-[10px] centering"
              style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
              onChange={handleNodeChange}
            >
              {category
                ?.find((element) => element.item == categoryTitle)
                ?.nodes?.map((val, i) => (
                  <option
                    key={i}
                    value={val.id}
                    style={{
                      background: `${G2_color_2}`,
                      color: `${G2_color_3}`,
                    }}
                  >
                    {val.text}
                  </option>
                ))}
            </Select>
          </Box>
        ) : (
          <></>
        )}
        <Box className="market-main-contents p-[10px]">
          <Box w="100%" h="30px" className="flex-box justify-between pr-3">
            <Box className="flex-box text-left pl-[10px]">
              <G2Button3 text="New" onClick={onClickNew} />
            </Box>
            <Box className="flex-box text-right">
              <Menu>
                <MenuButton
                  className="btn-small rounded-sm"
                  bg="var(--orange)"
                  color="white"
                  _hover={{ bg: "#2a956e" }}
                >
                  Sort <ChevronDownIcon />
                </MenuButton>
                <MenuList>
                  {btnGroup.map((val, index) => (
                    <MenuItem
                      key={index}
                      className="normal-hover"
                      onClick={() => handleSortChange(val)}
                    >
                      <Text className="text-black">{val}</Text>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
          </Box>
          <Box w="100%" className="flex-box mt-2.5">
            {contentList.map((val, i) => (
              <Box key={i} className="market-main-content p-[10px]">
                <Card
                  maxW="sm"
                  className="relative"
                  style={{
                    background: `${G2_color_2}`,
                    color: `${G2_color_3}`,
                  }}
                >
                  <CardBody w="100%">
                    <Image
                      className="market-main-image"
                      src={getFileUrl(val.cover)}
                      onClick={() => goToView(val)}
                    />
                    <Box w="100%" className="flex-box">
                      <Text w="100%" fontSize="18px" className="mt-5">
                        {val.name}
                      </Text>
                      <Text
                        w="100%"
                        fontSize="14px"
                        className={`mt-2.5 ${G2_theme_mode == "light"
                          ? "text-gray-600"
                          : "text-gray-300"
                          }`}
                      >
                        {val.businessName}
                      </Text>
                      <Text
                        w="100%"
                        fontSize="16px"
                        className="text-[#2AAF7F] mt-2.5"
                      >
                        $ {val.memberCost}
                      </Text>
                      {val.status != "" ? (
                        <Image
                          className="market-main-status"
                          width="auto"
                          height="30px"
                          src={
                            val.status == "new"
                              ? newer
                              : val.status == "enrolled"
                                ? enrolled
                                : seen
                          }
                        />
                      ) : (
                        <></>
                      )}
                      {val.userId == user._id ? (
                        <Image
                          className="absolute right-[20px] bottom-[25px] normal-hover"
                          width="35px"
                          src={edit}
                          onClick={() => goToEdit(val)}
                        />
                      ) : (
                        <Image
                          className="absolute right-[20px] bottom-[25px] normal-hover"
                          width="35px"
                          src={busket?.includes(val._id) ? buy2 : buy1}
                          onClick={() => handleClickBusket(val._id)}
                        />
                      )}
                    </Box>
                  </CardBody>
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLifeForm;
