import g2Axios from "../../Custom/g2Axios";
import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";

const getMainData = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/dashboard/main")
      .then((res) => {
        dispatch({ type: "DASHBOARD_MAIN_DATA", payload: res.data.mainData });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting main data:", error);
      });
  };
};

const getCreatorData = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/dashboard/creator")
      .then((res) => {
        dispatch({
          type: "DASHBOARD_CREATOR_DATA",
          payload: res.data.creatorData,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting creator data:", error);
      });
  };
};

const getMentorData = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/dashboard/mentor")
      .then((res) => {
        dispatch({
          type: "DASHBOARD_MENTOR_DATA",
          payload: res.data.mentorData,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting mentor data:", error);
      });
  };
};

const getSponsorData = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/dashboard/sponsor")
      .then((res) => {
        dispatch({
          type: "DASHBOARD_SPONSOR_DATA",
          payload: res.data.sponsorData,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting sponsor data:", error);
      });
  };
};

const getSchedule = (toast) => {
  return (dispatch) => {
    g2AxiosT
      .get("/dashboard/schedule")
      .then((res) => {
        dispatch({
          type: "DASHBOARD_SCHEDULE_DATA",
          payload: res.data.scheduleData,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting schedule data:", error);
      });
  };
};

const acceptMentorInvitation = (toast, id) => {
  return (dispatch) => {
    g2AxiosT
      .put("/dashboard/invitation/accept/" + id)
      .then((res) => {
        dispatch({
          type: "DASHBOARD_MENTOR_DATA",
          payload: res.data.mentorData,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error accepting mentor invitation:", error);
      });
  };
};

const rejectMentorInvitation = (toast, id) => {
  return (dispatch) => {
    g2AxiosT
      .put("/dashboard/invitation/reject/" + id)
      .then((res) => {
        dispatch({
          type: "DASHBOARD_MENTOR_DATA",
          payload: res.data.mentorData,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error rejecting mentor invitation:", error);
      });
  };
};

const getGoalData = (toast, memberId) => {
  return (dispatch) => {
    g2AxiosT
      .get("/dashboard/mentor/goal/" + memberId)
      .then((res) => {
        dispatch({ type: "DASHBOARD_GOAL_DATA", payload: res.data.goalData });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting goal data:", error);
      });
  };
};

const getActivityData = (toast, memberId) => {
  return (dispatch) => {
    g2AxiosT
      .get("/dashboard/mentor/activity/" + memberId)
      .then((res) => {
        dispatch({
          type: "DASHBOARD_ACTIVITY_DATA",
          payload: res.data.activityData,
        });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error getting activity data:", error);
      });
  };
};

const makePaymentData = (id, type) => {
  return (dispatch) => {
    dispatch({ type: "DASHBOARD_MENTORID", payload: id });
    dispatch({ type: "DASHBOARD_PAYMENTTYPE", payload: type });
  };
};

const rejectPayment = (toast, id, navigate) => {
  return (dispatch) => {
    g2AxiosT
      .delete("/dashboard/payments/reject/" + id)
      .then((res) => {
        // dispatch({
        //   type: "DASHBOARD_MENTOR_DATA",
        //   payload: res.data.mentorData,
        // });
        navigate("/dashboard");
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error rejecting payment:", error);
      });
  };
};

export {
  getMainData,
  getCreatorData,
  getMentorData,
  getSponsorData,
  getSchedule,
  acceptMentorInvitation,
  rejectMentorInvitation,
  getGoalData,
  getActivityData,
  makePaymentData,
  rejectPayment,
};
