import React from "react";
import CommonExternalPage from "./CommonExternalPage";

const content = [
  {
    title: "Privacy Policy for uTRADIE",
    body: "Effective Date: 29/05/2024"
  },
  {
    title: "Introduction",
    body: "Welcome to uTRADIE. We are committed to protecting your privacy and ensuring that your personal information is collected and used properly, transparently, and in compliance with applicable laws."
  },
  {
    title: "Collecting Personal Information",
    body: "When you use uTRADIE, we may collect a variety of information about you, including:",
    points: [
      "Your name, email address, and contact details.",
      "Demographic information such as postcode, preferences, and interests.",
      "Other information relevant to customer surveys and/or offers.",
      "Information related to your educational and professional background if you choose to provide it."
    ]
  },
  {
    title: "Using Your Personal Information",
    body: "The information we collect from you may be used in the following ways:",
    points: [
      "To improve our products and services.",
      "To send periodic promotional emails about new products, special offers, or other information we think you may find interesting.",
      "To customise the website according to your interests.",
      "To manage your account and provide you with customer support.",
    ]
  },
  {
    title: "Sharing Your Personal Information",
    body: "We do not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen."
  },
  {
    title: "Securing Your Personal Information",
    body: "Grow2 Pty Ltd takes precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information."
  },
  {
    title: "International Data Transfers",
    body: "Information that we collect may be stored and processed in and transferred between any of the countries in which we operate to enable us to use the information in accordance with this policy. Information which you provide may be transferred to countries which do not have data protection laws equivalent to those in force in our home jurisdiction."
  },
  {
    title: "Your Rights",
    body: "You may choose to restrict the collection or use of your personal information in the following ways:",
    points: [
      "Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes.",
      "If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us.",
      "You may request details of personal information which we hold about you under the data protection law in the applicable jurisdiction. A small fee will be payable. If you would like a copy of the information held on you please write to us.",
    ]
  },
  {
    title: "Cookies",
    body: "Our website uses \"cookies\" to help you personalise your online experience. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website."
  },
  {
    title: "Third-Party Websites",
    body: "Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement."
  },


  {
    title: "Updates to This Privacy Policy",
    body: "We may update this privacy policy by posting a new version on our website. You should check this page occasionally to ensure you are familiar with any changes."
  },


  {
    title: "Contact Us",
    body: "If you have any questions about this privacy policy or our treatment of your personal information, please write to us by email at contact@grow2.com.au"
  },
];

const PrivacyPage = () => {
  return <CommonExternalPage title="Privacy" content={content} />
};

export default PrivacyPage;
