const initialState = {
  user: {},
  type: "",
  des: "",
  userData: {},
  today: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "AUTH_GET_USER_DATA":
      return {
        ...state,
        user: action.payload,
      };
    case "AUTH_GET_USERDATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "AUTH_GET_USER_TYPE":
      return {
        ...state,
        type: action.payload,
      };
    case "AUTH_SET_DES":
      return {
        ...state,
        des: action.payload,
      };
    case "AUTH_GET_TODAY":
      return {
        ...state,
        today: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
