import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Box,
    Image,
    Text,
    List,
    ListItem,
    ListIcon,
    Divider,
    IconButton,
} from "@chakra-ui/react";

import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';

import "../../App.css";

import { useStateContext } from '../../ContextProvider/Context';

import show from "../../assets/create/show.png";
import hidden from "../../assets/create/hidden.png";

import pic1 from "../../assets/goal/pic1.png";
import pic2 from "../../assets/goal/pic2.png";

const recommend = [
    {
        img: pic1,
        title: "Basketball Tutorial",
        subtitle: "By XYZ Company"
    },
    {
        img: pic2,
        title: "Football Tutorial",
        subtitle: "By Brazilian Foundation"
    },
];

const LeftSideBar = (props) => {
    const { leftBarShow, leftBarClick } = props;

    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    const navigate = useNavigate();

    return (
        <Box w="100%" h="100%" style={{ background: "#49688f", color: "#ffffff" }}>
            {leftBarShow ? (
                <Box w="100%" h="100%">
                    {/* <Box w="100%" h="80px" className="centering">
                        <Image
                            className="create-left-zoom"
                            boxSize="55px"
                            src={hidden}
                            alt='icon'
                            onClick={leftBarClick}
                        />
                    </Box> */}
                    <Box w="100%" h="80px" className="centering">
                        <IconButton
                            isRound={true}
                            className="normal-hover"
                            style={{ backgroundColor: "var(--orange)" }}
                            color="white"
                            aria-label='Done'
                            fontSize='20px'
                            icon={<ArrowBackIcon />}
                            onClick={leftBarClick}
                        />
                    </Box>
                    <Box w="100%" className="px-5 pb-5">
                        <Text w="100%" fontSize="18px" className="text-center mt-5">
                            You have searched for <Text className="font-semibold">"Sporting Tutorial"</Text>
                        </Text>
                        <Text w="100%" fontSize="16px" className="font-semibold mt-5 mb-[10px]">Recommendation</Text>
                        {recommend.map((val, i) => (
                            <Box key={i} className="py-5">
                                <Image
                                    width="100%"
                                    height="auto"
                                    className="goal-left-image"
                                    src={val.img}
                                />
                                <Text w="100%" fontSize="16px" className="mt-1">{val.title}</Text>
                                <Text w="100%" fontSize="14px" className="text-gray-300">{val.subtitle}</Text>
                            </Box>
                        ))}
                        <Text w="100%" fontSize="16px" className="goal-left-view text-right font-semibold pb-5">View all</Text>
                    </Box>
                </Box>
            ) : (
                <Box>
                    {/* <Box w="100%" h="80px" className="centering">
                        <Image
                            className="create-left-zoom"
                            boxSize="55px"
                            src={show}
                            alt='icon'
                            onClick={leftBarClick}
                        />
                    </Box> */}
                    <Box w="100%" h="80px" className="centering">
                        <IconButton
                            isRound={true}
                            className="normal-hover"
                            style={{ backgroundColor: "var(--orange)" }}
                            color="white"
                            aria-label='Done'
                            fontSize='20px'
                            icon={<ArrowForwardIcon />}
                            onClick={leftBarClick}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default LeftSideBar;