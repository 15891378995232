import _ from "lodash";
import { getServerUrl } from "./location";
import twitter from "../assets/social_icon/twitter.png";
import facebook from "../assets/social_icon/facebook.png";
import instagram from "../assets/social_icon/instagram.png";
import tiktok from "../assets/social_icon/tiktok.png";
import linkedin from "../assets/social_icon/linkedin.png";
import youtube from "../assets/social_icon/youtube.png";

export function removeAt(obj, path) {
    if (!Array.isArray(path)) {
        path = _.toPath(path);
    }
    let oneDownPath = path.slice(0, -1);
    var temp = _.cloneDeep(obj);
    _.unset(temp, path);
    temp = _.set(temp, oneDownPath, _.compact(_.get(temp, oneDownPath)));
    return temp;
}

export function calculatePasswordStrength(password) {

    let score = 0;

    // TODO GO BACK AND FIX THESE
    if (password.length >= 8) {
        score += 1;
    }
    if (/\d/.test(password) && password.length >= 5) {
        score += 1;
    }
    if (/[A-Z]/.test(password) && password.length >= 3) {
        score += 1;
    }
    if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
        score += 1;
    }

    const lookup = [
        { status: "warning", title: "Very Weak" },
        { status: "warning", title: "Weak" },
        { status: "info", title: "Medium" },
        { status: "success", title: "Strong" },
        { status: "success", title: "Very Strong" },
    ]

    return lookup[score];
};

export function getFileUrl(filePath) {
    filePath = encodeURIComponent(filePath);
    return `${getServerUrl()}learn/getfile?file=${filePath}`
}

const commonPattern = /\/([^\/?]+)\/*$/;
export const socialMediaTypes = {
    twitter: {
        img: twitter,
        name: "Twitter (X)",
        default: "https://www.twitter.com",
        parser: {
            pattern: commonPattern,
            replace: "https://www.twitter.com/$1"
        },
        isSelected: false,

    },
    facebook: {
        img: facebook,
        name: "Facebook",
        default: "https://www.facebook.com/",
        parser: {
            pattern: commonPattern,
            replace: "https://www.facebook.com/$1/"
        },
        isSelected: false,


    },
    instagram: {
        img: instagram,
        name: "Instagram",
        default: "https://www.instagram.com/",
        parser: {
            pattern: commonPattern,
            replace: "https://www.instagram.com/$1"
        },
        isSelected: false,

    },
    tiktok: {
        img: tiktok,
        name: "Tiktok",
        default: "https://www.tiktok.com/",
        parser: {
            pattern: commonPattern,
            replace: "https://www.tiktok.com/$1"
        },
        isSelected: false,

    },
    linkedin: {
        img: linkedin,
        name: "Linkedin",
        default: "https://www.linkedin.com/",
        parser: {
            pattern: commonPattern,
            replace: "https://www.linkedin.com/in/$1"
        },
        isSelected: false,

    },
    youtube: {
        img: youtube,
        name: "Youtube",
        default: "https://www.youtube.com",
        parser: {
            pattern: commonPattern,
            replace: "https://www.youtube.com/$1"
        },
        isSelected: false,

    }
}

export const isFile = input => 'File' in window && input instanceof File;

export const profileUploadTypes = [
    {
        title: "Portfolio",
        subtitle: "MaxSize: 50Mbyte",
        buttonName: "+ Photo / Video",
        type: "portfolio",
        accept: ".png, .jpg, .jpeg, .mp4, .mpg, .avi, .3gp",
        multiple: true,
        path: ['profile', 'portfolio']
    },
    {
        title: "Cover letter",
        subtitle: "",
        buttonName: "Upload cover letter",
        type: "coverLetter",
        accept: ".pdf, .doc",
        multiple: false,
        path: ['profile', 'coverLetter']
    },
    {
        title: "Resume",
        subtitle: "",
        buttonName: "Upload resume",
        type: "resume",
        accept: ".pdf, .doc",
        multiple: false,
        path: ['profile', 'resume']
    },
    {
        title: "Licenses",
        subtitle: "",
        buttonName: "Upload licenses",
        type: "licenses",
        accept: ".pdf, .doc, .jpg, .png",
        multiple: true,
        path: ['profile', 'licenses']
    },
    {
        title: "Certificates",
        subtitle: "",
        buttonName: "Upload certificates",
        type: "certificates",
        accept: ".pdf, .doc, .jpg, .png, jpeg",
        multiple: true,
        path: ['profile', 'certificates']
    },
];

export function parseCSVLine(text) {
    var patternValid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    if (!patternValid.test(text)) {
        return null;
    }

    var results = [];

    var patternValue = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    text.replace(patternValue, (m0, m1, m2, m3) => {
        if (m1 !== undefined) {
            results.push(m1.replace(/\\'/g, "'"));
        } else if (m2 !== undefined) {
            results.push(m2.replace(/\\"/g, '"'));
        } else if (m3 !== undefined) {
            results.push(m3);
        }

        return '';
    });

    if (/,\s*$/.test(text)) {
        results.push('');
    }

    return results;
}

export function parseCSV(text) {
    return text.trim().split(/[\r\n]+/g).map(r => parseCSVLine(r));
};