import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  Card,
  CardBody,
  Select,
  Progress,
  Circle,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
  Textarea,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from "@chakra-ui/react";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ViewIcon,
  EditIcon,
  DeleteIcon,
} from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import { getJob, makeJob, deleteJob } from "../../Store/Actions/academyAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

// import AddModal from "./AddModal";

import G2Button10 from "../Common/G2Button10";
import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";
import G2Button23 from "../Common/G2Button23";
import G2Button24 from "../Common/G2Button24";
import G2Button26 from "../Common/G2Button26";

import logo from "../../assets/dashboard/logo.png";

const myLists = [
  {
    id: 1,
    title: "Greenkeeper",
    role: "Tradesman",
    jobType: "Full-time",
    expire: "18/04/2024",
  },
  {
    id: 2,
    title: "Horticulturist",
    role: "Apprentice",
    jobType: "Full-time",
    expire: "24/04/2024",
  },
  {
    id: 1,
    title: "Landscaper",
    role: "Labourer",
    jobType: "Part-time",
    expire: "13/05/2024",
  },
];

const JobEditForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const user = useSelector((state) => state.Auth.user);
  const jobList = useSelector((state) => state.Academy.jobList);

  const [isJobDopen, setIsJobDopen] = useState(false);

  const [delId, setDelId] = useState("");

  const token = getToken();

  const openJobDModal = (id) => {
    setIsJobDopen(true);
    setDelId(id);
  };

  const closeJObDModal = () => {
    setIsJobDopen(false);
  };

  const handleJobCreateClick = () => {
    navigate("/academy/job/create");
  };

  const handleBackClick = () => {
    navigate("/academy/job");
  };

  const handleJobViewClick = (val) => {
    dispatch(getJob(navigate, 2, val));
  };

  const handleUpdateClick = (val) => {
    dispatch(makeJob(navigate, val));
  };

  const handleDeleteClick = () => {
    dispatch(deleteJob(toast, delId));

    setIsJobDopen(false);
  };

  return (
    <Box
      w="100%"
      h="100%"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box
        w="100%"
        h="80px"
        bg={G2_color_2}
        className="flex-box centering-h px-[30px] shadow"
      >
        <Text
          fontSize="20px"
          className="font-semibold mr-[10px] text-[var(--orange)]"
        >
          Jobs Boards
        </Text>
        <Box className="absolute right-[30px]">
          <G2Button20
            widthVal="80px"
            heightVal="35px"
            text="Back"
            onClick={handleBackClick}
          />
        </Box>
      </Box>
      <Box w="100%" px="30px" py="20px" className="flex-box">
        <Text
          w="100%"
          fontSize="20px"
          className="mt-[10px] font-semibold text-center"
        >
          My Jobs List
        </Text>
        <Box w="100%">
          <G2Button23
            widthVal="120px"
            heightVal="35px"
            text="Post a Job"
            onClick={handleJobCreateClick}
          />
        </Box>
        <TableContainer
          width="100%"
          className="mt-5"
          css={{
            bg: `${G2_color_2}`,
            color: `${G2_color_3}`,
            borderRadius: "5px",
          }}
        >
          <Table>
            <Thead>
              <Tr>
                <Th w="50px" css={{ color: `${G2_color_3}` }}>
                  No
                </Th>
                <Th textAlign="center" css={{ color: `${G2_color_3}` }}>
                  Title
                </Th>
                <Th textAlign="center" css={{ color: `${G2_color_3}` }}>
                  Role
                </Th>
                <Th textAlign="center" css={{ color: `${G2_color_3}` }}>
                  Job Type
                </Th>
                <Th
                  textAlign="center"
                  className="flex-box"
                  css={{ color: `${G2_color_3}` }}
                >
                  <Text w="100%">Applications</Text>
                  <Text w="100%">close</Text>
                </Th>
                <Th textAlign="center" css={{ color: `${G2_color_3}` }}>
                  Actions
                </Th>
              </Tr>
            </Thead>
            {jobList && (
              <Tbody>
                {jobList
                  ?.filter((item) => item.userId == user._id)
                  ?.map((val, i) => (
                    <Tr key={i}>
                      <Td textAlign="center">{i + 1}</Td>
                      <Td textAlign="center">{val.title}</Td>
                      <Td textAlign="center">{val.category}</Td>
                      <Td textAlign="center">{val.type}</Td>
                      <Td textAlign="center">{val.expire}</Td>
                      <Td className="flex centering-w">
                        <Box>
                          <ViewIcon
                            className="mr-5 mb-[4px] normal-hover"
                            onClick={() => handleJobViewClick(val)}
                          />
                          <EditIcon
                            className="mr-5 mb-[5px] normal-hover"
                            onClick={() => handleUpdateClick(val)}
                          />
                          <DeleteIcon
                            className="mb-[5px] normal-hover"
                            onClick={() => openJobDModal(val._id)}
                          />
                        </Box>
                        <G2ConfirmAlert
                          isOpen={isJobDopen}
                          onClose={closeJObDModal}
                          handleOkClick={handleDeleteClick}
                        />
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>
        <Box w="100%" className="mt-[20px]"></Box>
      </Box>
    </Box>
  );
};

export default JobEditForm;
