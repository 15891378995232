import { calcLength } from "framer-motion";
import g2Axios from "../../Custom/g2Axios";
import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";

const getTotalList = (toast) => {
	return (dispatch) => {
		g2AxiosT
			.get("/create")
			.then((res) => {
				dispatch({ type: "CREATE_GET_CONTENTLIST", payload: res.data.contentList, });
				dispatch({ type: "CREATE_GET_GROUPLIST", payload: res.data.groupList });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error getting content and group data:", error);
			});
	};
};

const createContent = (toast, navigate, contentName) => {
	return (dispatch) => {
		g2AxiosT
			.post("/create/content/reg", { contentName })
			.then((res) => {
				dispatch({
					type: "CREATE_GET_CONTENTLIST",
					payload: res.data.contentList,
				});
				dispatch({ type: "CREATE_GET_CONTENT", payload: res.data.content });
				dispatch({ type: "CREATE_GET_UNITLIST", payload: [] });
				g2Toast({ toast, status: "success", title: res.data.msg });
				navigate("/create/content");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error creating content data:", error);
			});
	};
};

const getContent = (toast, navigate, val, id) => {
	return (dispatch) => {
		g2AxiosT
			.get("/create/content/" + id)
			.then((res) => {
				dispatch({ type: "CREATE_SET_CONTENT", payload: val });
				dispatch({ type: "CREATE_GET_UNITLIST", payload: res.data.unitList });
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				navigate("/create/content");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error getting content data:", error);
			});
	};
};

const updateContent = (toast, id, contentName) => {
	return (dispatch) => {
		g2AxiosT
			.put("/create/content/" + id, { contentName })
			.then((res) => {
				dispatch({
					type: "CREATE_GET_CONTENTLIST",
					payload: res.data.contentList,
				});
				dispatch({ type: "CREATE_GET_CONTENT", payload: res.data.content });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error updaing content data:", error);
			});
	};
};

const deleteContent = (toast, navigate, id) => {
	return (dispatch) => {
		g2AxiosT
			.delete("/create/content/" + id)
			.then((res) => {
				dispatch({
					type: "CREATE_GET_CONTENTLIST",
					payload: res.data.contentList,
				});
				g2Toast({ toast, status: "success", title: res.data.msg });
				navigate("/create");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error deleting content data:", error);
			});
	};
};

const addSponsor = (toast, formData) => {
	return (dispatch) => {
		g2AxiosT
			.put("/create/content/sponsor/reg", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				g2Toast({ toast, status: "success", title: res.data });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error adding unit data:", error);
			});
	};
};

const addUnit = (toast, contentId, unitName) => {
	return (dispatch) => {
		g2AxiosT
			.post("/create/content/regunit", { contentId, unitName })
			.then((res) => {
				dispatch({
					type: "CREATE_GET_CONTENTLIST",
					payload: res.data.contentList,
				});
				dispatch({ type: "CREATE_GET_UNITLIST", payload: res.data.unitList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error adding unit data:", error);
			});
	};
};

const updateUnit = (toast, unitId, unitName) => {
	return (dispatch) => {
		g2AxiosT
			.put("/create/content/unit/" + unitId, { unitName })
			.then((res) => {
				dispatch({ type: "CREATE_GET_UNITLIST", payload: res.data.unitList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error deleting unit data:", error);
			});
	};
};

const deleteUnit = (toast, contentId, ids) => {
	return (dispatch) => {
		g2AxiosT
			.delete("/create/content/unit/" + contentId, { data: { ids } })
			.then((res) => {
				dispatch({
					type: "CREATE_GET_CONTENTLIST",
					payload: res.data.contentList,
				});
				dispatch({ type: "CREATE_GET_UNITLIST", payload: res.data.unitList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error deleting unit data:", error);
			});
	};
};

const setSelectedUnit = (toast, navigate, one) => {
	return (dispatch) => {
		dispatch({ type: "CREATE_SET_UNIT", payload: one });
		navigate("/create/content/card/add");
	};
};

const addCard1 = (toast, navigate, cond, formData, setWait) => {
	return (dispatch) => {
		g2AxiosT
			.put(`/create/content/unit/card/reg/${cond}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				setWait(false);
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				g2Toast({ toast, status: "success", title: res.data.msg });
				navigate("/create/content");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error adding unit data:", error);
			});
	};
};

const addCard2 = (toast, navigate, data, setWait) => {
	return (dispatch) => {
		g2AxiosT
			.post("/create/content/unit/card/reg", { data })
			.then((res) => {
				setWait(false);
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				g2Toast({ toast, status: "success", title: res.data.msg });
				navigate("/create/content");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error adding unit data:", error);
			});
	};
};

const setSelectedCard = (navigate, currentCard) => {
	return (dispatch) => {
		g2AxiosT
			.post("/", { "class": "ControllerContent", "method": "getCard", cardId: currentCard.id })
			.then((res) => {
				dispatch({ type: "CREATE_SET_CURRENTCARD", payload: res.data });
				navigate("/create/content/card");
			})
			.catch((error) => {
				console.error("Error getting card data", error);
			});
	};
};

const updateCard1 = (toast, navigate, cond, formData, setWait) => {
	return (dispatch) => {
		g2AxiosT
			.put(`/create/content/unit/card/update/${cond}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				setWait(false);
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				g2Toast({ toast, status: "success", title: res.data.msg });
				navigate("/create/content");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error adding unit data:", error);
			});
	};
};

const updateCard2 = (toast, navigate, data, setWait) => {
	return (dispatch) => {
		g2AxiosT
			.put("/create/content/unit/card/update", { data })
			.then((res) => {
				setWait(false);
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				g2Toast({ toast, status: "success", title: res.data.msg });
				navigate("/create/content");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error deleting unit data:", error);
			});
	};
};

const deleteCard = (toast, unitId, ids) => {
	return (dispatch) => {
		g2AxiosT
			.delete("/create/content/unit/card/" + unitId, { data: { ids } })
			.then((res) => {
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error deleting unit data:", error);
			});
	};
};

const updateCardIndex = (
	toast,
	cardId,
	unitId,
	sourceIndex,
	destinationIndex,
	contentId
) => {
	return (dispatch) => {
		g2AxiosT
			.put("/create/content/unit/card/drop1/" + cardId, {
				unitId,
				sourceIndex,
				destinationIndex,
				contentId,
			})
			.then((res) => {
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error updating card data:", error);
			});
	};
};

const updateUnitIdWithCardIndex = (toast, cardId, unitId, destinationIndex, contentId) => {
	return (dispatch) => {
		g2AxiosT
			.put("/create/content/unit/card/drop2/" + cardId, {
				unitId,
				destinationIndex,
				contentId,
			})
			.then((res) => {
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error updating card data:", error);
			});
	};
};

const inviteMember = (toast, userIds, groupIds, setUsers, setGroups, closeIAddModal, targetGroupId) => {
	return (dispatch) => {
		g2AxiosT
			.post("/create/group/invite", { userIds, groupIds })
			.then((res) => {
				if (res.data.one) {
					dispatch({ type: "CREATE_GET_GROUPLIST", payload: res.data.groupList });

					if (res.data.groupData[targetGroupId] !== undefined) {
						const groupData = res.data.groupData[targetGroupId];
						dispatch({ type: "CREATE_GET_MEMBERLIST", payload: groupData.memberList, });
						dispatch({ type: "CREATE_GET_UNITLIST", payload: groupData.unitList });
						dispatch({ type: "CREATE_GET_CARDLIST", payload: groupData.cardList });
						dispatch({ type: "CREATE_GET_ACTIVITYLIST", payload: groupData.activityList, });
					}

					g2Toast({ toast, status: "success", title: res.data.msg });
					setUsers([]);
					setGroups([]);
					closeIAddModal();



					//dispatch({ type: "CREATE_GET_MEMBERLIST", payload: res.data.memberList });
					//dispatch({ type: "CREATE_GET_GROUP", payload: res.data.group });
				} else {
					g2Toast({ toast, status: "info", title: res.data });
				}
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error adding unit data:", error);
			});
	};
};

const inviteMentor = (toast, memberId, email, setEmail, closeIAddModal) => {
	return (dispatch) => {
		g2AxiosT
			.post("/create/invite/mentor/" + email, { memberId })
			.then((res) => {
				if (res.data.one) {
					g2Toast({ toast, status: "success", title: res.data.msg });

					setEmail("");
					closeIAddModal();
				} else {
					g2Toast({ toast, status: "info", title: res.data });
				}
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error adding unit data:", error);
			});
	};
};

const createGroup = (toast, navigate, groupName) => {
	return (dispatch) => {
		g2AxiosT
			.post("/create/group/reg", { groupName })
			.then((res) => {
				dispatch({ type: "CREATE_GET_GROUPLIST", payload: res.data.groupList });
				dispatch({ type: "CREATE_GET_GROUP", payload: res.data.group });
				dispatch({ type: "CREATE_GET_MEMBERLIST", payload: [] });
				g2Toast({ toast, status: "success", title: res.data.msg });
				navigate("/create/group");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error creating group data:", error);
			});
	};
};

const getGroup = (toast, navigate, val, id) => {
	return (dispatch) => {
		g2AxiosT
			.get("/create/group/" + id)
			.then((res) => {
				dispatch({ type: "CREATE_SET_GROUP", payload: val });
				dispatch({ type: "CREATE_GET_MEMBERLIST", payload: res.data.memberList, });
				dispatch({ type: "CREATE_GET_UNITLIST", payload: res.data.unitList });
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				dispatch({ type: "CREATE_GET_ACTIVITYLIST", payload: res.data.activityList, });
				navigate("/create/group");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error registering user data:", error);
			});
	};
};

// const getMemberList = (toast, navigate, id) => {
//     return (dispatch) => {
//         g2AxiosT.get("/create/group/member/" + id)
//             .then((res) => {
//                 dispatch({ type: "CREATE_GET_MEMBERLIST", payload: res.data.member });
//             })
//             .catch((error) => {
//                 g2Toast({ toast, status: "error", title: "Authentication failed!" });
//                 console.error("Error registering user data:", error);
//             });
//     };
// };

const updateGroup = (toast, id, groupName) => {
	return (dispatch) => {
		g2AxiosT
			.put("/create/group/" + id, { groupName })
			.then((res) => {
				dispatch({ type: "CREATE_GET_GROUPLIST", payload: res.data.groupList });
				dispatch({ type: "CREATE_GET_GROUP", payload: res.data.group });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error updaing content data:", error);
			});
	};
};

const deleteGroup = (toast, navigate, id) => {
	return (dispatch) => {
		g2AxiosT
			.delete("/create/group/" + id)
			.then((res) => {
				dispatch({ type: "CREATE_GET_GROUPLIST", payload: res.data.groupList });
				g2Toast({ toast, status: "success", title: res.data.msg });
				navigate("/create");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error deleting content data:", error);
			});
	};
};

const deleteMemberList = (toast, groupId, ids) => {
	return (dispatch) => {
		g2AxiosT
			.delete("/create/group/member/" + groupId, { data: { ids } })
			.then((res) => {
				dispatch({ type: "CREATE_GET_GROUPLIST", payload: res.data.groupList });
				dispatch({
					type: "CREATE_GET_MEMBERLIST",
					payload: res.data.memberList,
				});
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error deleting unit data:", error);
			});
	};
};

const addDefaultContent = (toast, id, contentIds) => {
	return (dispatch) => {
		g2AxiosT
			.put("/create/group/defaultcontent/" + id, { contentIds })
			.then((res) => {
				dispatch({ type: "CREATE_GET_GROUP", payload: res.data.group });
				dispatch({ type: "CREATE_GET_UNITLIST", payload: res.data.unitList });
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error updaing content data:", error);
			});
	};
};

const removeDefaultContent = (toast, id, defaultContentId) => {
	return (dispatch) => {
		g2AxiosT
			.delete("/create/group/defaultcontent/" + id, {
				data: { defaultContentId },
			})
			.then((res) => {
				dispatch({ type: "CREATE_GET_GROUP", payload: res.data.group });
				dispatch({ type: "CREATE_GET_UNITLIST", payload: res.data.unitList });
				dispatch({ type: "CREATE_GET_CARDLIST", payload: res.data.cardList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error adding unit data:", error);
			});
	};
};

const addAttachment = (toast, id, formData, setWait) => {
	return (dispatch) => {
		g2AxiosT
			.put("/create/group/attachment/" + id, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				setWait(false);
				dispatch({ type: "CREATE_GET_GROUP", payload: res.data.group });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error adding unit data:", error);
			});
	};
};

const removeAttachment = (toast, groupId, id) => {
	return (dispatch) => {
		g2AxiosT
			.delete("/create/group/attachment/" + groupId, { data: { id } })
			.then((res) => {
				dispatch({ type: "CREATE_GET_GROUP", payload: res.data.group });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error adding unit data:", error);
			});
	};
};

const getMember = (toast, navigate, id) => {
	return (dispatch) => {
		g2AxiosT
			.get("/create/group/member/" + id)
			.then((res) => {
				const { member, assignedGroupIds, activity } = res.data;
				dispatch({
					type: "CREATE_GET_MEMBER",
					payload: { ...member, assignedGroupIds },
				});
				dispatch({ type: "CREATE_GET_ACTIVITY", payload: activity });
				navigate("/create/group/member");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error registering user data:", error);
			});
	};
};

const addMember = (toast, groupMemberId, groupIds, targetUserId) => {
	return (dispatch) => {
		g2AxiosT
			.put("/create/group/member/add", { groupMemberId, groupIds, targetUserId })
			.then((res) => {
				const { groupList, member, assignedGroupIds } = res.data;
				dispatch({ type: "CREATE_GET_GROUPLIST", payload: groupList });
				dispatch({ type: "CREATE_GET_MEMBER", payload: { ...member, assignedGroupIds }, });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error updaing content data:", error);
			});
	};
};

const deleteMember = (toast, navigate, id) => {
	return (dispatch) => {
		g2AxiosT
			.delete("/create/group/member/current/" + id)
			.then((res) => {
				dispatch({ type: "CREATE_GET_GROUPLIST", payload: res.data.groupList });
				dispatch({ type: "CREATE_GET_MEMBERLIST", payload: res.data.memberList, });
				g2Toast({ toast, status: "success", title: res.data.msg });
				navigate("/create/group");
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error deleting content data:", error);
			});
	};
};

const removeCardAttachment = (toast, cardAttachmentId) => {
	return (dispatch) => {
		g2AxiosT
			.post("/?class=ControllerContent&method=removeCardAttachment", { cardAttachmentId })
			.then((res) => {
				g2Toast({ toast, status: "success", title: "Attachment Removed" });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error deleting card attachment data:", error);
			});
	};
}

const updateContentIndex = (toast, sourceIndex, destinationIndex) => {
	return (dispatch) => {
		g2AxiosT.post("?class=ControllerContent&method=updateContentIndex", { sourceIndex, destinationIndex })
			.then((res) => {
				dispatch({ type: "CREATE_GET_CONTENTLIST", payload: res.data.contentList, });
				dispatch({ type: "CREATE_GET_GROUPLIST", payload: res.data.groupList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error updating content index:", error);
			});
	}
};

const updateGroupIndex = (toast, sourceIndex, destinationIndex) => {
	return (dispatch) => {
		g2AxiosT.post("?class=ControllerContent&method=updateGroupIndex", { sourceIndex, destinationIndex })
			.then((res) => {
				dispatch({ type: "CREATE_GET_CONTENTLIST", payload: res.data.contentList, });
				dispatch({ type: "CREATE_GET_GROUPLIST", payload: res.data.groupList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error updating content index:", error);
			});
	}
};

const updateUnitIndex = (toast, contentId, sourceIndex, destinationIndex) => {
	return (dispatch) => {
		g2AxiosT.post("?class=ControllerContent&method=updateUnitIndex", { contentId, sourceIndex, destinationIndex })
			.then((res) => {
				dispatch({ type: "CREATE_GET_CONTENTLIST", payload: res.data.contentList, });
				dispatch({ type: "CREATE_GET_UNITLIST", payload: res.data.unitList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error updating content index:", error);
			});
	}
};

const moveUnitToContent = (toast, contentId, unitId) => {
	return (dispatch) => {
		g2AxiosT.post("?class=ControllerContent&method=moveUnittoOtherContent", { contentId, unitId })
			.then((res) => {
				dispatch({ type: "CREATE_GET_UNITLIST", payload: res.data.unitList });
				g2Toast({ toast, status: "success", title: res.data.msg });
			})
			.catch((error) => {
				g2Toast({ toast, status: "error", title: "Authentication failed!" });
				console.error("Error updating content index:", error);
			});
	}
}

export {
	getTotalList,
	createContent,
	getContent,
	updateContent,
	deleteContent,
	addSponsor,
	addUnit,
	updateUnit,
	deleteUnit,
	setSelectedUnit,
	addCard1,
	addCard2,
	setSelectedCard,
	updateCard1,
	updateCard2,
	deleteCard,
	updateCardIndex,
	updateUnitIdWithCardIndex,
	inviteMember,
	inviteMentor,
	createGroup,
	getGroup,
	updateGroup,
	deleteGroup,
	deleteMemberList,
	addDefaultContent,
	addAttachment,
	removeAttachment,
	removeDefaultContent,
	getMember,
	addMember,
	deleteMember,
	removeCardAttachment,
	updateContentIndex,
	updateGroupIndex,
	updateUnitIndex,
	moveUnitToContent
};
