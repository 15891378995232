import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { useStateContext } from '../ContextProvider/Context';
import { getToken, verifyToken } from "../utils";

import Container from "../Components/Container/Container";
import Navbar from "../Components/Navbar/Navbar";
import OnBoardingForm from "../Components/OnBoardingForm/OnBoardingForm";

const OnboardingPage = () => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    const navigate = useNavigate();

    const token = getToken();

    useEffect(() => {
        if (!verifyToken()) {
            navigate("/login");
        }
    }, []);

    return (
        <Box minH="100vh" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
            <Navbar title="Onboard" />
            <Box p="5" style={{ background: `${G2_color_1}` }}>
                <Container>
                    <Box className="md:mx-12 lg:mx-28 ">
                        <OnBoardingForm />
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default OnboardingPage;