import React, { useEffect, useMemo, useState } from "react";
import { Box, Text, Link, Image, Input } from "@chakra-ui/react";
import { CheckCircleIcon, LinkIcon } from '@chakra-ui/icons'

import "../../App.css";

import { useStateContext } from '../../ContextProvider/Context';

import { useSelector } from "react-redux";
import _ from "lodash";
import { FaEdit } from "react-icons/fa";
import { socialMediaTypes } from "../../helpers/general";

const SocialConnectForm = ({ userData, setUserData }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    const [socialMedia, setSocialMedia] = useState(socialMediaTypes);

    useEffect(() => {
        if (userData?.socialMedia === undefined) {
            return;
        }

        let temp = _.cloneDeep(socialMedia);
        for (var name in userData.socialMedia) {
            temp[name].isSelected = true;
        }
    }, [userData]);

    const updateSocialMedia = (path, value) => {
        let temp = _.cloneDeep(socialMedia);
        temp = _.set(temp, path, value);
        setSocialMedia(temp)
    };

    const updateUserData = (path, value) => {
        let temp = _.cloneDeep(userData);
        temp = _.set(temp, path, value);
        setUserData(temp)
    }

    const updateUserDataSocialMedia = (name, value) => {
        let r = socialMedia[name];
        updateUserData(['socialMedia', name], value)
    }


    return <Box pt="0" className="social-box-group">
        {Object.keys(socialMedia).map(name => {
            const r = socialMedia[name]
            const url = userData?.socialMedia !== undefined && userData?.socialMedia[name] !== undefined ? userData?.socialMedia[name] : r.default;
            const value = userData?.socialMedia !== undefined && userData?.socialMedia[name] !== undefined ? userData?.socialMedia[name] : "";
            return <Box key={r.name} className="social-box">
                <Box className="social-img centering">
                    <Link to={url} className="btn">
                        <Image src={r.img} w="100px" h="100px" className="responsive-img" />
                    </Link>
                </Box>
                <FaEdit className="social-checkbox cursor-pointer" w="25px" h="25px" color={`${r.isSelected ? "green.500" : "gray.300"}`} onClick={() => updateSocialMedia([name, 'isSelected'], !r.isSelected)} />
                <Box className="social-text-box">
                    <Text className="social-text">{r.name}</Text>
                    {!r.isSelected && <Link to={url} style={{ width: "100%" }}>
                        {url}
                    </Link>}
                    {r.isSelected && <Input type="url" bg={!value.match(r.parser.pattern) && value != '' ? "var(--red)" : ""} placeholder={r.default} value={value} onChange={(e) => updateUserDataSocialMedia(name, e.target.value)} />}
                </Box>
            </Box>
        })}
    </Box>
};

export default SocialConnectForm;