import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Switch,
  Text,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";

import { useStateContext } from "../../ContextProvider/Context";
import {
  logInUser,
  logInDashboard,
  logInCreate,
  logInLearn,
  logInAcademy,
  forgotPassword,
} from "../../Store/Actions/authAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2Button6 from "../Common/G2Button6";
import UTradieText from "../Common/UTradieText";

const Login = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const passwordRef = useRef(null);

  const toast = useToast();

  const des = useSelector((state) => state.Auth.des);

  const [isBusiness, setIsBusiness] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setValidEmail] = useState(true);

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidEmail(emailRegex.test(inputEmail));
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    // if (isEmptyValue(inputValue)) {
    //   setValidEmail(true);
    // } else {
    //   validateEmail(inputValue);
    // }
  };

  const handleForgotClick = () => {
    if (isValidEmail && email != "") {
      dispatch(forgotPassword(toast, email, isBusiness));
    } else {
      g2Toast({ toast, status: "error", title: "Invalid email address!" });
    }
  };

  const handleLogin = () => {
    if (email != "" && password != "") {
      // if (isValidEmail) {
      if (isEmptyValue(des)) {
        dispatch(logInUser(toast, navigate, email, password, isBusiness));
      } else {
        if (des == "dashboard") {
          dispatch(
            logInDashboard(toast, navigate, email, password, isBusiness)
          );
        }
        if (des == "create") {
          dispatch(logInCreate(toast, navigate, email, password, isBusiness));
        }
        if (des == "learn") {
          dispatch(logInLearn(toast, navigate, email, password, isBusiness));
        }
        if (des == "academy") {
          dispatch(logInAcademy(toast, navigate, email, password, isBusiness));
        }
      }
      // }
    } else {
      g2Toast({ toast, status: "error", title: "Invalid input!" });
    }
  };

  const handleKeyPress = (e, num) => {
    if (e.key === "Enter") {
      if (num == 1) {
        passwordRef.current && passwordRef.current.focus();
      } else {
        handleLogin();
      }
    }
  };

  return (
    <Box
      className="flex min-h-full flex-col justify-center px-6 lg:px-8"
      style={{ background: `${G2_color_1}` }}
    >
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm flex flex-col items-center justify-center">
        <div className="flex">
          <p className="font-bold  text-[48px] ">
            <UTradieText />
          </p>
        </div>
        <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-[var(--orange)]">
          Login to your {!isBusiness ? "Personal" : "Bussiness"} account
        </h2>
      </div>

      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="mt-5 flex centering-h">
          <Switch
            colorScheme="green"
            size="md"
            className="mr-5"
            onChange={() => setIsBusiness(!isBusiness)}
          />
          <Text fontSize="18px" color="var(--orange)" className="font-semibold">
            For business
          </Text>
        </div>
        <div className="mt-5">
          <div>
            <label
              style={{ color: `${G2_color_3}` }}
              htmlFor="email"
              className="block text-sm font-medium text-gray-900"
            >
              Email address
            </label>
            {!isValidEmail ? (
              <Alert
                status="error"
                variant="solid"
                height="40px"
                borderRadius="md"
                className="mt-2 text-black"
              >
                <AlertIcon />
                Invalid email address!
              </Alert>
            ) : (
              <></>
            )}
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onKeyDown={(e) => handleKeyPress(e, 1)}
                onChange={handleEmailChange}
                autoComplete="email"
                required
                style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
                className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="mt-5">
            <div className="flex items-center justify-between">
              <label
                style={{ color: `${G2_color_3}` }}
                htmlFor="password"
                className="block text-sm font-medium text-gray-900"
              >
                Password
              </label>
              <div className="text-sm">
                <p
                  className="font-semibold text-indigo-600 normal-hover"
                  onClick={handleForgotClick}
                >
                  Forgot password?
                </p>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                onKeyDown={(e) => handleKeyPress(e, 2)}
                onChange={(e) => setPassword(e.target?.value)}
                ref={passwordRef}
                autoComplete="current-password"
                required
                style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}
                className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="mt-5">
            <G2Button6 widthVal="100%" text="Sign In" onClick={handleLogin} />
          </div>
        </div>

        <p
          className="mt-5 mb-10 text-center text-sm"
          style={{ color: `${G2_color_3}` }}
        >
          Not a member?
          <Link
            to={"/signUp"}
            className="ml-5 font-semibold text-indigo-600 hover:text-indigo-300"
          >
            Register now
          </Link>
        </p>
      </div>
    </Box>
  );
};

export default Login;
