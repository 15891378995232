import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";

import "../../App.css";

import { useStateContext } from '../../ContextProvider/Context';

import G2Button2 from "../Common/G2Button2";
import UTradieText from "../Common/UTradieText";
import Navbar from "../Navbar/Navbar";
import UniversalLeftSideBar from "../Common/UniversalLeftSideBar";

// import congratulation from "../../assets/onboarding_page/congratulation.png";

const CongratulationForm = () => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const navigate = useNavigate();
    const [screenSize, setScreenSize] = useState(1366);
    const [menuShow, setMenuShow] = useState(true);
    const [menuHide, setMenuHide] = useState(false);
    const [mainHide, setMainHide] = useState(false);
    const [leftBarShow, setLeftBarShow] = useState(true);
    const [rightBarShow, setRightBarShow] = useState(true);

    const onClickGoal = () => {
        navigate("/goal");
    }

    return <Box>
        <Navbar title="Dashboard" />
        <Box className="flex-box relative" style={{ background: `${G2_color_1}` }}>
            <Box className={`flex-box ${menuHide ? "dash-leftBar-w0" : leftBarShow ? menuShow ? "dash-leftBar-w1" : "dash-leftBar-w2" : "dash-leftBar-w0"}`} style={{ background: `${G2_color_1}` }}>
                <UniversalLeftSideBar showText={true} menuShow={menuShow} screenSize={screenSize} />
            </Box>
            <Box w="calc(100% - 250px)" minH="100vh">
                <Box w="100%">
                    <Box style={{ background: `${G2_color_1}` }}>
                        <Box className="congBac" style={{ backgroundImage: "url(/congratulationBac.png)" }}></Box>
                    </Box>
                    <Box style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }} className="congText">
                        <Text className="text-4xl">Congratulations!</Text>
                        <Text className="text-2xl mt-[10px]">Now, You can explore the benefits of <UTradieText />.</Text>
                    </Box>
                    <Box style={{ background: `${G2_color_1}` }} className="congBtn centering-w">
                        <G2Button2 widthVal="180px" text="Set a Goal" onClick={onClickGoal} />
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
};

export default CongratulationForm;