import React from 'react';

function UTradieText({ isHeading = false }) {


    if (isHeading === 2) {
        return <>
            <span className="brand-text" style={{ "color": "#032c47" }}>U</span>
            <span className="brand-text" style={{ "color": "#ffffff" }}>TRADIE</span>
        </>
    }

    if (isHeading) {
        return <>
            <span className="brand-text" style={{ "color": "#f86709" }}>U</span>
            <span className="brand-text" style={{ "color": "#ffffff" }}>TRADIE</span>
        </>
    }

    return <>
        <span className="brand-text" style={{ "color": "#f86709" }}>U</span>
        <span className="brand-text" style={{ "color": "#032c47" }}>Tradie</span>
    </>
}

export default UTradieText;