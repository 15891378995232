import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Text,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  DatePicker,
  Input,
  Image,
  IconButton,
  useToast,
} from "@chakra-ui/react";

import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";

import { useStateContext } from "../../ContextProvider/Context";
import G2InputC from "../Common/G2InputC";
import G2Input from "../Common/G2Input";
import G2EmailInput from "../Common/G2EmailInput";
import StreetAddressInput from "../Common/StreetAddressInput";
import G2Label2 from "../Common/G2Label2";
import G2Label4 from "../Common/G2Label4";
import g2Toast from "../../Custom/g2Toast";

import { idLabelData } from "../../constant";
import { workStatusData } from "../../constant";
import camera from "../../assets/camera.png";
import { getServerUrl } from "../../helpers/location";
import { getFileUrl } from "../../helpers/general";

const PersonalDetailsForm = (props) => {
  const {
    user,
    avatarUrl,
    setAvatarFile,
    setAvatarUrl,
    data,
    handleFieldChange1,
    handleFieldChange2,
    handleFieldChange4,
    setAddress,
    setComAddress,
  } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const toast = useToast();

  const userInfo = useSelector((state) => state.Auth.user);

  const [delAvatar, setDelAvatar] = useState(false);

  const fileInputRef = useRef(null);

  const onClickFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (event.target.files.length == 0) {
      return;
    }

    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = "." + selectedFile.name.split(".").pop();

    if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
      g2Toast({ toast, status: "error", title: "Invalid file type!" });
      return;
    }

    setAvatarFile(selectedFile);
    setAvatarUrl(URL.createObjectURL(selectedFile));
  };

  const handleAvatarDelete = () => {
    setDelAvatar(true);

    setAvatarFile(null);
    setAvatarUrl(null);
  };

  return (
    <Box style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box width="100%" h="50px"></Box>
      {userInfo?.type == "Personal" && (
        <Box w="100%">
          <Box className={`font-bold text-[18px] text-start`}>
            Personal details
          </Box>
          <Box width="100%" h="20px"></Box>
          <Box width="100%" className="centering py-[20px]">
            <Box className="onboard-avatar-box centering">
              {avatarUrl == null && delAvatar ? (
                <Box w="100%" className="centering">
                  <Box w="25px">
                    <Image boxSize="100%" src={camera} alt="upload" onClick={onClickFileInput} className="normal-hover" />
                    <Input type="file" ref={fileInputRef} accept=".png, .jpg, .jpeg" onChange={handleFileChange} style={{ display: "none" }} />
                  </Box>
                </Box>
              ) : (
                <Box w="100%" h="100%" className="relative">
                  {!delAvatar ? (
                    <Box w="100%">
                      <Image boxSize="100%" src={getFileUrl(user?.avatar)} alt="uploadedImage" />
                      <IconButton position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" variant="outline" isRound={true} color="black" fontSize="15px" icon={<DeleteIcon />} onClick={handleAvatarDelete} />
                    </Box>
                  ) : (
                    <Box w="100%">
                      <Image boxSize="100%" src={avatarUrl} alt="uploadedImage" />
                      <IconButton position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" variant="outline" isRound={true} color="black" fontSize="15px" icon={<DeleteIcon />} onClick={handleAvatarDelete} />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box className="grid grid-cols-1 gap-x-10 sm:grid-cols-2">
            <G2InputC type="text" label="First Name" name="Name" value={user.name} />
            <G2InputC type="email" label="Email" name="email" value={user.email} />
            <Box width="100%" h="80px" display={{ base: "none", lg: "flex" }}></Box>
            <Box className="mt-3">
              <G2Label4 className="block text-sm font-semibold leading-6" label="Gender" />
              <Select className="mt-1" name="gender" value={data.gender} onChange={handleFieldChange2} style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
                <option value="Male" style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
                  Male
                </option>
                <option value="Female" style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
                  Female
                </option>
              </Select>
            </Box>
            <Box className="mt-3">
              <Text className="block text-sm font-semibold leading-6" style={{ color: `${G2_color_3}` }}>
                Date of birth
              </Text>
              <Box className="mt-1">
                <Input className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6" style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }} type="date" name="birthday" value={data.birthday} onChange={handleFieldChange1} />
              </Box>
            </Box>
            <G2Input type="text" label="Phone" name="phone" value={data.phone} onChange={handleFieldChange1} />
            <StreetAddressInput address={data.address} setAddress={setAddress} label="Address" />
          </Box>
        </Box>
      )}
      <Box width="100%" h="50px"></Box>
      <Box className={`font-bold text-[18px] text-start`}>
        Workplace details
      </Box>
      <Box width="100%" h="20px"></Box>
      <Box className="grid grid-cols-1 gap-x-10 sm:grid-cols-2">
        <G2Input type="text" label="Occupation / Job title" name="occupation" value={data.occupation} onChange={handleFieldChange1} />
        <Box className="mt-3">
          <G2Label4 className="block text-sm font-semibold leading-6" label="ID Label" />
          <Select className="mt-1" name="idLabel" value={data.idLabel} onChange={handleFieldChange2} style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
            {idLabelData.map((val, index) => (
              <option key={index} value={val} className="bg-gray-500" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
                {val}
              </option>
            ))}
          </Select>
        </Box>
        <G2Input type="text" label="Company / Business name" name="companyName" value={data.companyName} onChange={handleFieldChange1} />
        <G2Input type="text" label="Website URL" name="siteURL" value={data.siteURL} onChange={handleFieldChange1} />
        <G2EmailInput type="email" label="Company / Business Email" name="companyEmail" value={data.companyEmail} onChange={handleFieldChange1} />
        <G2Input type="text" label="Company / Business Phone" name="companyPhone" value={data.companyPhone} onChange={handleFieldChange1} />
        <StreetAddressInput address={data.companyAddress} setAddress={setComAddress} label="Company / Business Address" />
        <Box width="100%" h="80px" display={{ base: "none", lg: "flex" }}></Box>
        <Box className="mt-3">
          <G2Label4 className="block text-sm font-semibold leading-6" label="Years of experience in the industry" />
          <NumberInput className="mt-1" value={data.experience} onChange={(_, value) => handleFieldChange4(value)}>
            <NumberInputField style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Box className="mt-3">
          <G2Label4 className="block text-sm font-semibold leading-6" label="Work Status" />
          <Select className="mt-1" name="workStatus" value={data.workStatus} onChange={handleFieldChange2} style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
            {workStatusData.map((val, index) => (
              <option key={index} value={val} style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>{val}</option>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalDetailsForm;
