const initialState = {
  contentList: [],
  posted: 0,
  draft: 0,
  content: {},
  preview: {},
  busket: [],
  category: [],
};

const marketplaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MARKET_GET_CONTENTLIST":
      return {
        ...state,
        contentList: action.payload,
      };
    case "MARKET_GET_POSTEDCOUNT":
      return {
        ...state,
        posted: action.payload,
      };
    case "MARKET_GET_DRAFTCOUNT":
      return {
        ...state,
        draft: action.payload,
      };
    case "MARKET_GET_CONTENT":
      return {
        ...state,
        content: action.payload,
      };
    case "MARKET_MAKE_CONTENT":
      return {
        ...state,
        content: action.payload,
      };
    case "MARKET_GET_PREVIEW":
      return {
        ...state,
        preview: action.payload,
      };
    case "MARKET_GET_BUSKET":
      return {
        ...state,
        busket: action.payload,
      };
    case "MARKET_GET_CATEGORY":
      return {
        ...state,
        category: action.payload,
      };
    default:
      return state;
  }
};

export default marketplaceReducer;
