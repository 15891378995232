import React from "react";

import { useStateContext } from "../../ContextProvider/Context";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";

import G2Button1 from "../Common/G2Button1";
import G2Button2 from "../Common/G2Button2";

const G2ConfirmAlert = (props) => {
  const { isOpen, onClose, handleOkClick } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        bg={G2_color_2}
        color={G2_color_3}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <G2Button1
                widthVal="80px"
                heightVal="40px"
                text="Cancel"
                onClick={onClose}
              />
              <G2Button2
                widthVal="80px"
                heightVal="40px"
                text="Ok"
                onClick={handleOkClick}
              />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default G2ConfirmAlert;
