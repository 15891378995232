import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Text,
  Image,
  Divider,
  Card,
  CardBody,
  Select,
  Progress,
  Circle,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  HStack,
  Avatar,
} from "@chakra-ui/react";

import { CalendarIcon, SearchIcon, CheckCircleIcon, ArrowBackIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getInvitation, getUnit } from "../../Store/Actions/learnAction";
import { getToken, verifyToken } from "../../utils";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import InvitationModal from "./InvitationModal";

import G2Button2 from "../Common/G2Button2";
import G2Button20 from "../Common/G2Button20";

import notification1 from "../../assets/learn/notification1.png";
import notification2 from "../../assets/learn/notification2.png";
import done from "../../assets/learn/done.png";
import yet from "../../assets/learn/yet.png";
import yet1 from "../../assets/learn/yet1.png";
import com from "../../assets/learn/com.png";
import content1 from "../../assets/create/content1.png";
import content2 from "../../assets/create/content2.png";
import group1 from "../../assets/create/group1.png";
import group2 from "../../assets/create/group2.png";
import { formatRelativeTime } from "../../helpers/formatters";
import { getFileUrl } from "../../helpers/general";

const MainUnitForm = (props) => {
  const { screenSize, boxW1, boxW2 } = props;

  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const invitation = useSelector((state) => state.Learn.invitation);
  const group = useSelector((state) => state.Learn.group);
  const content = useSelector((state) => state.Learn.content);
  const unitList = useSelector((state) => state.Learn.unitList);
  const cardList = useSelector((state) => state.Learn.cardList);
  const activity = useSelector((state) => state.Learn.activity);
  const card = useSelector((state) => state.Learn.card);

  const [isAIopen, setIsAIOpen] = useState(false);
  const [openUnit, setOpenUnit] = useState([0]);

  const token = getToken();

  useEffect(() => {
    if (verifyToken()) {
      dispatch(getInvitation(toast));
    }

    if (isEmptyValue(group._id) || isEmptyValue(content._id)) {
      navigate("/learn");
    }

    if (card?.unitId !== undefined) {
      for (let i in unitList) {
        if (unitList[i]._id == card?.unitId) {
          setOpenUnit([parseInt(i)]);
          break;
        }
      }
    }

  }, []);

  const openInvitationModal = () => {
    setIsAIOpen(true);
  };

  const closeInvitationModal = () => {
    setIsAIOpen(false);
  };

  const handleUnitClick = (val) => {
    dispatch(getUnit(toast, navigate, val));
  };

  return (
    <Box w="100%" h="100%" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
      <Box w="100%" h="80px" bg={G2_color_2} className="flex-box centering-h shadow">
        <Box w="100%" className="flex-box">
          <Box p="3" className="text-left centering-h" style={{ width: "calc(100% - 70px)" }}>
            <IconButton isRound={true} className="normal-hover mx-[10px]" style={{ backgroundColor: "var(--orange)" }} color="white" aria-label="Done" fontSize="20px" icon={<ArrowBackIcon />} onClick={() => navigate("/learn/content")} />
            <Avatar size="md" src={getFileUrl(group?.userId?.avatar)} alt="icon" />
            {!isEmptyValue(group._id) && <Box>
              <Text fontSize="16px" className="font-semibold mx-[10px]">
                {content.title}
              </Text>
              <Text fontSize="14px" className="mx-[10px]">
                {group.groupId.title}
              </Text>
            </Box>}
          </Box>
          <Box w="70px" className="centering relative">
            <Box className="normal-hover invite-notification" onClick={openInvitationModal}>
              {isEmptyValue(invitation[0]?._id) ? (
                <Image width="30px" height="35px" src={notification1} alt="notification" />
              ) : (
                <Image width="30px" height="35px" src={notification2} alt="notification" />
              )}
              {invitation?.length != 0 ? (
                <Box w="15px" h="15px" className="absolute top-[2px] right-[22px] centering">
                  <Text fontSize="12px" className="text-white">
                    {invitation?.length}
                  </Text>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <InvitationModal isAIopen={isAIopen} closeInvitationModal={closeInvitationModal} />
          </Box>
        </Box>
      </Box>
      <Box w="100%" className="learn-accordion-box">
        <Accordion defaultIndex={[9]} index={openUnit} onChange={(e) => setOpenUnit(e)} allowMultiple className="p-[10px] mt-2.5">
          {unitList?.map((element, i) => (
            <AccordionItem key={i}>
              <h2>
                <AccordionButton className="learn-content-unit" bg={G2_color_2} color={G2_color_3} _expanded={{ bg: "#E0F1FF", color: "black" }} py="15px">
                  <Image className="mt-[5px] mr-[15px]" boxSize="25px" src={com} alt="icon" />
                  <Box flex="1" textAlign="left" className="font-semibold">
                    {element.title} ({element.size})
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb="10px" bg={G2_color_2} color={G2_color_3}>
                {cardList?.filter((val) => val.unitId == element._id)?.map((val, i) => (
                  <Box key={i} className="learn-content-card flex-box cursor-pointer py-[10px] normal-hover" onClick={() => handleUnitClick(val)}>
                    {
                      //JSON.stringify(activity?.activeCards)
                    }
                    {
                      //JSON.stringify(val)
                      //JSON.stringify(activity?.activeCards[val._id])
                    }
                    <HStack w="100%" justifyContent={"space-between"}>
                      <Text fontSize="16px">{val.cardName}</Text>
                      <Box>
                        <Text display="inline" pr="15px">{formatRelativeTime(activity?.activeCards[val._id]?.lastActive)}</Text>
                        <Image display="inline" boxSize="20px" src={activity?.activeCards[val._id]?.completed ? done : G2_theme_mode == "light" ? yet : yet1} alt={"notification_" + (activity?.activeCards[val._id]?.completed ? "completed" : "notyet")} />
                      </Box>
                    </HStack>
                  </Box>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Box>
  );
};

export default MainUnitForm;
