import React, { useEffect, useMemo, useState } from 'react';
import { formatCurrency } from '../../helpers/formatters';
import { Box, HStack, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Radio, RadioGroup, Text } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm4 from '../Common/PaymentForm4';
import { useStateContext } from '../../ContextProvider/Context';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAvailableSubscriptions } from '../../Store/Actions/onboardAction';
import { loadStripe } from "@stripe/stripe-js";

const options = {
    mode: "payment",
    amount: 100,
    currency: "aud",
    appearance: {
        /*...*/
    },
};

const SubscriptionForm = ({ title = "Subscription" }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const availableSubscriptions = useSelector((state) => state.Onboard.availableSubscriptions);
    const [type, setType] = useState(null);
    const [users, setUsers] = useState(0);
    const dispatch = useDispatch();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    useEffect(() => {
        dispatch(getAvailableSubscriptions());
    }, []);

    const selectedSubscription = useMemo(() => {
        const s = availableSubscriptions.filter(r => r.id + "" == type);
        if (s.length == 1) {
            return s[0];
        }

        return null;
    }, [type]);

    const calcPrice = useMemo(() => {
        if (type == null) {
            return 0;
        }

        return selectedSubscription?.periodCost + (users * (selectedSubscription?.additionalUsers ?? 0));
    }, [type, users]);

    return <Box w="100%">
        <Box w="100%" className="market-bucket-pay" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
            <Box w="100%">
                <Text fontSize="20px" className="font-semibold mb-[10px]">{title}</Text>
            </Box>
            <Box w="100%" className="market-bucket-pay-sub flex-box">
                <RadioGroup className="wide-radio" w="100%" value={type} onChange={setType}>

                    {availableSubscriptions.map(r => <Radio key={r.id} borderColor="blue.500" colorScheme="green" w="100%" value={r.id + ""}>
                        <HStack w="100%" justifyContent="space-between">
                            <Box className='subscription-name'>{r.name}, {r.description}</Box>
                            <Box fontWeight="bold">{formatCurrency(r.periodCost)}</Box>
                        </HStack>
                    </Radio>)}
                </RadioGroup>
            </Box>
            {
                //JSON.stringify(selectedSubscription)
            }
            {selectedSubscription?.additionalUsers != null && <>
                <Box w="100%" className="flex-box justify-between market-bucket-pay-sub">
                    <Box className="flex-box text-left">
                        <Text fontSize="18px" className="font-semibold">Additional Users</Text>
                    </Box>
                    <Box className="flex-box text-right">
                        <NumberInput w="60px" value={users} onChange={(value) => setUsers(value)} min={0} max={100}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Box>
                </Box>
            </>
            }
            <Box w="100%" className="flex-box justify-between market-bucket-pay-sub">
                <Box className="flex-box text-left">
                    <Text fontSize="18px" className="font-semibold">Price</Text>
                </Box>
                <Box className="flex-box text-right">
                    <Text fontSize="18px" className="font-semibold">{formatCurrency(calcPrice)}</Text>
                </Box>
            </Box>
            {type != null && <Box w="100%" className="mt-[20px]">
                <Elements stripe={stripePromise} options={options}>
                    <PaymentForm4 subscriptionId={type} users={users} />
                </Elements>
            </Box>}
        </Box>
    </Box>
};

export default SubscriptionForm;