import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, AlertIcon, Box, Circle, HStack, Image, Text, VStack, useToast } from "@chakra-ui/react";

import { useStateContext } from "../../ContextProvider/Context";

import business from "../../assets/business.png";
import person from "../../assets/person.png";
import UTradieText from "../Common/UTradieText";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUserTypes } from "../../Store/Actions/onboardAction";
import _ from "lodash";
import { calculatePasswordStrength } from "../../helpers/general";
import g2Toast from "../../Custom/g2Toast";
import { signUpUser } from "../../Store/Actions/authAction";
import G2Button6 from "../Common/G2Button6";

const SignUpForm = () => {
	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const toast = useToast();

	useEffect(() => {
		dispatch(getUserTypes());
	}, []);

	const userTypes = useSelector((state) => state.Onboard.userTypes);

	const [data, setData] = useState({
		type: null,
		name: "",
		email: null,
		password: null,
		passwordConfirm: null,
		affiliateCode: params.affiliateCode ?? ""
	});

	const update = useCallback((path, value) => {
		let temp = _.cloneDeep(data);
		temp = _.set(temp, path, value);
		setData(temp);
	}, [data, setData]);

	const passwordPrompt = useMemo(() => {

		if (data.password == null) {
			return <></>;
		}

		const strength = calculatePasswordStrength(data.password)

		return <Alert status={strength.status} variant="solid" height="40px" borderRadius="md" className="mt-2 text-black">
			<AlertIcon />
			Password is {strength.title}!
		</Alert>;
	}, [data.password]);

	const passwordMatchPrompt = useMemo(() => {

		if (data.password == null && data.passwordConfirm == null) {
			return <></>;
		}

		if (data.password != data.passwordConfirm || data.password == null || data.passwordConfirm == null || data.password == "" && data.passwordConfirm == "") {
			return <Circle boxSize="10px" bg="var(--red)" className="absolute top-[12.5px] right-[15px]" />
		}

		return <Circle boxSize="10px" bg="var(--green)" className="absolute top-[12.5px] right-[15px]" />
	}, [data.password, data.passwordConfirm]);

	const isValidEmail = useMemo(() => {
		if (data.email == null) {
			return true;
		}

		return data.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
	}, [data.email]);

	const handleRegister = useCallback(() => {
		if (data.name == "" || data.email == "" || data.password == "" || data.passwordConfirm == "") {
			g2Toast({ toast, status: "error", title: "Missing required input!" });
			return;
		}

		if (!isValidEmail && data.email !== null) {
			g2Toast({ toast, status: "error", title: "Invalid Email address!" });
			return;
		}


		if (data.password != data.passwordConfirm) {
			g2Toast({ toast, status: "error", title: "Passwords dont match!" });
			return;
		}

		dispatch(signUpUser(toast, navigate, data));
	}, [data]);

	const staticContent = useMemo(() => {
		return {
			none: {
				heading: "Select the account you want to create"
			},
			Personal: {
				image: person,
				heading: "Create Free Personal Account"
			},
			Business: {
				image: business,
				heading: "Create Free Account For Business"
			},
			Parent: {
				image: person,
				heading: "Create Free Parental Account"
			},
		}
	}, []);

	return (
		<Box className="flex min-h-full flex-col justify-center pb-5 px-6 lg:px-8" style={{ background: `${G2_color_1}` }}>
			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center justify-center">
				<div className="flex">
					<p className="font-bold  text-[48px] ">
						<UTradieText />
					</p>
				</div>
				<h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-[var(--orange)]">
					{staticContent[data.type ?? "none"].heading}
				</h2>
			</div>

			{data.type == null && <HStack justifyContent="center" alignContent="center" mt="25px">
				{userTypes.map((r, i) => <div key={r.id} className="w-auto pl-[20px] cursor-pointer" onClick={() => update('type', r.name)}>
					<VStack justifyContent="center" alignContent="center">
						<Image w="auto" h="50px" src={staticContent[r.name].image} />
						<Text className="text-[18px] font-semibold">{r.name}</Text>
						<Text className="text-[12px] font-semibold">{r.description}</Text>
					</VStack>
				</div>)}
			</HStack>}

			{data.type != null && <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
				<div className="mt-5">
					<div>
						<label style={{ color: `${G2_color_3}` }} htmlFor="name" className="text-sm flex font-medium leading-6 text-gray-900">Name</label>
						<div className="mt-2">
							<input value={data.name} onChange={(e) => update('name', e.target.value)} id="name" name="Name" type="text" autoComplete="Name" required style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }} className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
						</div>
					</div>

					<div className="mt-5">
						<label style={{ color: `${G2_color_3}` }} htmlFor="email" className="text-sm flex font-medium leading-6 text-gray-900">Email address</label>
						{!isValidEmail && <Alert status="error" variant="solid" height="40px" borderRadius="md" className="mt-2 text-black"><AlertIcon />Invalid email address!</Alert>}
						<div className="mt-2">
							<input id="email" value={data.email ?? ""} onChange={(e) => update('email', e.target.value)} name="email" type="email" autoComplete="email" required style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }} className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
						</div>
					</div>

					<div className="mt-5">
						<label style={{ color: `${G2_color_3}` }} htmlFor="password" className="text-sm flex font-medium leading-6 text-gray-900">Password</label>
						{passwordPrompt}
						<div className="mt-2 relative">
							<input id="password" value={data.password ?? ""} onChange={(e) => update('password', e.target.value)} name="password" type="password" autoComplete="current-password" required style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }} className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
						</div>
					</div>


					<div className="mt-5">
						<label style={{ color: `${G2_color_3}` }} htmlFor="passwordConfirm" className="text-sm flex font-medium leading-6 text-gray-900">Password</label>
						<div className="mt-2 relative">
							<input id="passwordConfirm" value={data.passwordConfirm ?? ""} onChange={(e) => update('passwordConfirm', e.target.value)} name="passwordConfirm" type="password" autoComplete="current-password" required style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }} className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
							{passwordMatchPrompt}
						</div>
					</div>

					<div className="mt-5">
						<label style={{ color: `${G2_color_3}` }} htmlFor="affiliateCode" className="text-sm flex font-medium leading-6 text-gray-900">Affiliate Code</label>
						<div className="mt-2">
							<input id="affiliateCode" value={data.affiliateCode} onChange={(e) => update('affiliateCode', e.target?.value)} name="affiliateCode" type="text" autoComplete="Affiliate Code" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }} className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
						</div>
					</div>

					<div className="mt-5">
						<G2Button6 widthVal="100%" text="Sign Up" onClick={handleRegister} />
					</div>
				</div>
			</div>}

			<p className="mt-5 mb-10 text-center text-sm" style={{ color: `${G2_color_3}` }}>
				Already a member?{" "}
				<Link to={"/login"} className="ml-5 font-semibold text-indigo-600 hover:text-indigo-500">Login now</Link>
			</p>
		</Box >
	);
};

export default SignUpForm;
