import React from "react";

import { useStateContext } from '../../ContextProvider/Context';

const G2Label4 = ({ label }) => {
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    return (
        <label
            htmlFor="first-name"
            style={{ color: `${G2_color_3}` }}
            className="block text-sm font-semibold leading-6"
        >
            {label}
        </label>
    );
}

export default G2Label4;