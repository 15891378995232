import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    useBreakpointValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Button,
    Text,
    Divider,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Center,
    useToast
} from '@chakra-ui/react';

import { SmallCloseIcon } from '@chakra-ui/icons';

import { useStateContext } from '../../ContextProvider/Context';
import { acceptInvitation, rejectInvitation } from "../../Store/Actions/learnAction";
import { getToken, verifyToken } from "../../utils";

import G2Button19 from "../Common/G2Button19";
import G2Button20 from "../Common/G2Button20";
import G2Button21 from "../Common/G2Button21";
import { formatDate } from "../../helpers/formatters";

const InvitationModal = (props) => {
    const { isAIopen, closeInvitationModal } = props;

    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

    const toast = useToast();

    const dispatch = useDispatch();

    const groupList = useSelector((state) => state.Create.groupList);
    const invitation = useSelector((state) => state.Learn.invitation);

    const handleAcceptInvitation = (id) => {
        dispatch(acceptInvitation(toast, id));
    }

    const handleRejectInvitation = (id) => {
        dispatch(rejectInvitation(toast, id));
    }

    const isSmallScreen = useBreakpointValue({ base: true, lg: false });

    return (
        <>
            <Modal size={isSmallScreen ? "lg" : "xl"} isOpen={isAIopen}>
                <ModalOverlay />
                <ModalContent style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
                    <ModalBody>
                        <Box w="100%" className="flex-box py-5">
                            <Box w="100%" className="flex-box justify-between centering-h mb-[10px]">
                                <Box className="flex-box text-left">
                                    <Text className="block text-lg font-semibold" style={{ color: `${G2_color_3}` }}>
                                        View invitation
                                    </Text>
                                </Box>
                                <Box className="flex-box text-right">
                                    <SmallCloseIcon className="exit-icon" style={{ color: `${G2_color_3}` }} onClick={closeInvitationModal} />
                                </Box>
                            </Box>
                            <Divider borderColor={G2_theme_mode == "light" ? "black" : "white"} orientation='horizontal' className="mb-[10px]" />
                            <TableContainer className="mt-5" css={{ color: `${G2_color_3}`, borderRadius: "10px" }}>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th w="50px" css={{ color: `${G2_color_3}` }}>Date</Th>
                                            <Th textAlign="center" css={{ color: `${G2_color_3}` }}>Group</Th>
                                            <Th textAlign="center" css={{ color: `${G2_color_3}` }}>From</Th>
                                            <Th textAlign="center" css={{ color: `${G2_color_3}` }}>Accept</Th>
                                            <Th textAlign="center" css={{ color: `${G2_color_3}` }}>Reject</Th>
                                            {/* <Th textAlign="center" css={{ color: `${G2_color_3}` }}>Delete</Th> */}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {invitation?.map((val, i) => (
                                            <Tr key={i}>
                                                <Td>{formatDate(val.created_at)}</Td>
                                                <Td>{val.groupId.title}</Td>
                                                <Td>{val.senderId.email}</Td>
                                                <Td>
                                                    <G2Button20 widthVal="70px" heightVal="25px" text="Accept" onClick={() => handleAcceptInvitation(val._id)} />
                                                </Td>
                                                <Td>
                                                    <G2Button21 widthVal="70px" heightVal="25px" text="Reject" onClick={() => handleRejectInvitation(val._id)} />
                                                </Td>
                                                {/* <Td>
                                                    <G2Button19 widthVal="90px" heightVal="25px" text="Delete" />
                                                </Td> */}
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal >
        </>
    );
}

export default InvitationModal; 