import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	Box,
	Text,
	Image,
	Divider,
	Card,
	CardBody,
	Select,
	Progress,
	Circle,
	useToast,
	InputGroup,
	Input,
	InputRightElement,
	IconButton,
	Checkbox,
	List,
	ListItem,
	HStack,
	Button
} from "@chakra-ui/react";

import { CalendarIcon, SearchIcon } from '@chakra-ui/icons';

import "../../App.css";

import { useStateContext } from '../../ContextProvider/Context';
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import InviteModal from "./InviteModal";

import G2Button2 from "../Common/G2Button2";
import G2Button20 from "../Common/G2Button20";
import content2 from "../../assets/create/content2.png";
import group2 from "../../assets/create/group2.png";
import plan from "../../assets/create/plan.png";
import ContentAddModal from "./ContentAddModal";
import GroupAddModal from "./GroupAddModal";
import { getContent, getGroup, updateContentIndex, updateGroupIndex } from "../../Store/Actions/createAction";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FaClipboard, FaGripLinesVertical } from "react-icons/fa";

const MainFirstForm = ({ mode, screenSize, boxW1, boxW2 }) => {

	const contentList = useSelector((state) => state.Create.contentList);
	const groupList = useSelector((state) => state.Create.groupList);

	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const toast = useToast();

	const [memberCnt, setMemberCnt] = useState(0);
	const [isIAddopen, setIsIAddOpen] = useState(false);
	const [isCAddOpen, setIsCAddOpen] = useState(false);
	const [isGAddOpen, setIsGAddOpen] = useState(false);

	const openCAddModal = () => {
		setIsCAddOpen(true);
	};

	const closeCAddModal = () => {
		setIsCAddOpen(false);
	};

	const openGAddModal = () => {
		setIsGAddOpen(true);
	};

	const closeGAddModal = () => {
		setIsGAddOpen(false);
	};

	const contentOpen = useMemo(() => {
		return mode == "content";
	}, [mode]);

	const groupOpen = useMemo(() => {
		return mode == "group";
	}, [mode]);

	useEffect(() => {
		let cnt = 0;
		groupList?.map((val) => {
			cnt += val?.size;
		});
		setMemberCnt(cnt);
	}, [groupList]);

	const openIAddModal = () => {
		setIsIAddOpen(true);
	};

	const closeIAddModal = () => {
		setIsIAddOpen(false);
	};

	const onClickContent = (val) => {
		dispatch(getContent(toast, navigate, val, val._id));

		if (screenSize < 768) {
			//setLeftBarShow(false);
		}
	}

	const onClickGroup = (val) => {
		dispatch(getGroup(toast, navigate, val, val._id));

		if (screenSize < 768) {
			//setLeftBarShow(false);
		}
	}

	const onContentDragEnd = ({ destination, source, draggableId }) => {

		if (!destination) {
			return;
		}

		if (destination.droppableId == source.droppableId && destination.index == source.index) {
			return;
		}

		dispatch(updateContentIndex(toast, source.index, destination.index));
	};

	const onGroupDragEnd = ({ destination, source, draggableId }) => {

		if (!destination) {
			return;
		}

		if (destination.droppableId == source.droppableId && destination.index == source.index) {
			return;
		}

		dispatch(updateGroupIndex(toast, source.index, destination.index));
	};

	return (
		<Box w="100%" h="100%" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
			<Box w="100%" h="80px" bg={G2_color_2} className="flex-box centering-h px-[30px] shadow">
				<Box w="100%" className="flex-box justify-between">
					<Box className="flex-box text-left centering-h">
						<Image h="30px" src={plan} alt='icon' />
						{mode == "content" && <Text fontSize="16px" className="font-semibold mx-[10px]">Content</Text>}
						{mode == "group" && <Text fontSize="16px" className="font-semibold mx-[10px]">Group</Text>}
					</Box>
					<Box className="flex-box text-right">

						<Box w="100%" className="centering">
							{contentOpen && <G2Button20 mr="10px" py="10px" widthVal="150px" heightVal="40px" text="Add a content" onClick={openCAddModal} />}
							{groupOpen && <G2Button20 mr="10px" py="10px" widthVal="150px" heightVal="40px" text="Add a group" onClick={openGAddModal} />}
							<G2Button20 widthVal="150px" heightVal="40px" text="Invite members" onClick={openIAddModal} />
						</Box>
						<InviteModal isIAddopen={isIAddopen} closeIAddModal={closeIAddModal} />
					</Box>
				</Box>
			</Box>
			<Box w="100%" className="flex-box px-[20px] pb-[50px]">


				{contentOpen && <Box w="100%">
					<ContentAddModal isCAddOpen={isCAddOpen} closeCAddModal={closeCAddModal} />
					<DragDropContext onDragEnd={onContentDragEnd}>
						{contentList?.length > 0 && <Droppable droppableId="content" className="w-100">

							{(provided) => <List w="100%" ref={provided.innerRef}  {...provided.droppableProps}>
								{contentList?.map((val, i) => <Draggable key={val._id} draggableId={val._id + ""} index={i}>
									{(provided) => <ListItem key={i} w="100%" ref={provided.innerRef} {...provided.draggableProps} bg="white" my="10px" p="10px">
										<HStack w="100%" justifyContent="space-between">
											<HStack>
												<div h="100%" {...provided.dragHandleProps}><FaGripLinesVertical /></div>
												<Box w="40px">
													<FaClipboard h="25px" />
												</Box>
												<Text fontSize="16px">{val.title}</Text>
												<Text fontSize="14px" className="text-gray-500">{val.size?.toString() + " Units"}</Text>
											</HStack>
											<Box>
												<Button colorScheme='orange' variant="solid" onClick={() => onClickContent(val)}>Open</Button>
											</Box>
										</HStack>

									</ListItem>}

								</Draggable>)}
								{provided.placeholder}
							</List>}
						</Droppable>}
					</DragDropContext>
				</Box>}

				{groupOpen && <Box w="100%">
					<GroupAddModal isGAddOpen={isGAddOpen} closeGAddModal={closeGAddModal} />
					<DragDropContext onDragEnd={onGroupDragEnd}>

						{groupList?.length > 0 && <Droppable droppableId="group" className="w-100">
							{(provided) => <List w="100%" ref={provided.innerRef}  {...provided.droppableProps}>
								{groupList?.map((val, i) => <Draggable key={val._id} draggableId={val._id + ""} index={i}>
									{(provided) => <ListItem key={i} w="100%" ref={provided.innerRef} {...provided.draggableProps} bg="white" my="10px" p="10px">
										<HStack w="100%" justifyContent="space-between">
											<HStack>
												<div h="100%" {...provided.dragHandleProps}><FaGripLinesVertical /></div>
												<Box w="40px">
													<FaClipboard h="25px" />
												</Box>
												<Text fontSize="16px">{val.title}</Text>
												<Text fontSize="14px" className="text-gray-500">{val.size?.toString() + " Members"}</Text>
											</HStack>
											<Box>
												<Button colorScheme='orange' variant="solid" onClick={() => onClickGroup(val)}>Open</Button>
											</Box>
										</HStack>
									</ListItem>}
								</Draggable>)}
								{provided.placeholder}
							</List>}
						</Droppable>}
					</DragDropContext>
				</Box>}

			</Box>
		</Box >
	);
};

export default MainFirstForm;