import g2Axios from "../../Custom/g2Axios";
import g2AxiosT from "../../Custom/g2AxiosT";
import g2Toast from "../../Custom/g2Toast";

const updateSetting1 = (toast, formData, setDelAvatar) => {
  return (dispatch) => {
    g2AxiosT
      .put(`/setting/update/a`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
        dispatch({
          type: "AUTH_GET_USERDATA",
          payload: res.data.userData,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
        setDelAvatar(false);
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error updating setting data:", error);
      });
  };
};

const updateSetting2 = (toast, data) => {
  return (dispatch) => {
    g2AxiosT
      .put("/setting/update/b", { data })
      .then((res) => {
        dispatch({ type: "AUTH_GET_USER_DATA", payload: res.data.user });
        dispatch({
          type: "AUTH_GET_USERDATA",
          payload: res.data.userData,
        });
        g2Toast({ toast, status: "success", title: res.data.msg });
      })
      .catch((error) => {
        g2Toast({ toast, status: "error", title: "Authentication failed!" });
        console.error("Error updating setting data:", error);
      });
  };
};

export { updateSetting1, updateSetting2 };
